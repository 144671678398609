import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../assets/styles/Production.css";
import Button from "../../../utils/Button";
import {
    dispatchLoadingList,
    dispatchLoadingListSF,
    fetchLoadingList,
    fetchLoadingListSF,
} from "../../../redux-tookit/slices/loadingListSlice";
import AGTable from "../../../components/AGTable";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import refresh from "../../../assets/images/button-icons/Refresh.svg";
import { fetchUserAccess } from "../../../redux-tookit/slices/userAccessSlice";
import TopButtonContainer from "../../../components/UserAccess/TopButtonContainer";
import { UserAccessContext } from "../../../App";
import CreatePass from "./Print/CreatePass";
import { Document, pdf } from "@react-pdf/renderer";
import {
    fetchOrder,
    fetchOrderSF,
} from "../../../redux-tookit/slices/orderSlice";
import {
    downloadGenPdf,
    uploadGenPdf,
} from "../../../redux-tookit/slices/bbsPdfGenerationSlice";

import CreateLoadingListPDF from "./Print/LoadingList/CreateLoadingListPDF";
import { PDFViewer } from "@react-pdf/renderer";
import { fetchPartItems } from "../../../redux-tookit/slices/partItemsSlice";
import { fetchBundles } from "../../../redux-tookit/slices/loadingListSlice";
import { fetchServiceCenter } from "../../../redux-tookit/slices/serviceCenterSlice";
import { fetchProductionParameters } from "../../../redux-tookit/slices/smartFab/productionParameterSlice";
import SmFabLoadingListPdf from "./Print/LoadingList/SmFabLoadingListPdf";

const LoadingDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const productName = useContext(UserAccessContext).productName;

    const columns = useSelector((state) => state.loadingList.columns);
    const originalData = useSelector((state) => state.loadingList.labeledData);
    const [data, setData] = useState(originalData);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const [fromDateFilter, setFromDateFilter] = useState("");
    const [toDateFilter, setToDateFilter] = useState("");
    const [dispatchStatusFilter, setDispatchStatusFilter] =
        useState("Not dispatched");
    const dispatchStatusValues = [
        ...new Set(originalData.map((item) => item.gatePassPrintStatus)),
    ];

    const orderData = useSelector((state) => state.order.labeledData);

    const [generatePdfOpen, setGeneratePdfOpen] = useState(false);

    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

    const [permission, setPermission] = useState({});
    useEffect(() => {
        dispatch(fetchUserAccess()).then((res) => {
            if (res.payload) {
                if (res.payload.navbarNames.Transaction["Loading Details"][0]) {
                    setPermission(
                        res.payload.navbarNames.Transaction[
                            "Loading Details"
                        ][0]
                    );
                }
            }
        });
        dispatch(fetchServiceCenter());
    }, []);

    const applyFilters = () => {
        let filteredData = originalData;
        if (fromDateFilter && toDateFilter) {
            const fromDate = new Date(fromDateFilter);
            const toDate = new Date(toDateFilter);

            filteredData = filteredData.filter((item) => {
                const orderDate = new Date(item.gatePassPrintDate);
                return orderDate >= fromDate && orderDate <= toDate;
            });
        }

        if (dispatchStatusFilter) {
            filteredData = filteredData.filter(
                (item) => item.gatePassPrintStatus === dispatchStatusFilter
            );
        }

        setData(filteredData);
    };

    const handleRowSelect = (rowIndex) => {
        try {
            if (rowIndex !== undefined) {
                setSelectedRowIndex(rowIndex);
                if (productName === "smartFab") {
                    dispatch(fetchProductionParameters());
                } else {
                    dispatch(fetchBundles(`?bundleIds=${data[rowIndex].bundleId}`));
                }
            } else setSelectedRowIndex(null);
        } catch (error) {
            console.log(error);
        }
        
    };

    const handleEdit = () => {
        if (selectedRowIndex !== null) {
            console.log(data[selectedRowIndex]);
            if (data[selectedRowIndex].gatePassPrintStatus !== "dispatched") {
                console.log(data[selectedRowIndex].gatePassPrintStatus);
                const loadingListId = data[selectedRowIndex].loadingListId;
                navigate("/update loading details", {
                    state: {
                        loadingListId: loadingListId,
                        updateData: data[selectedRowIndex],
                        orderIds: data[selectedRowIndex].orderId,
                    },
                });
            } else {
                toast.error("Dispatched loading list cannot be edited");
            }
        } else
            toast.error("No rows selected. Please select a row and try again");
    };

    const handleGatePassPrint = async () => {
        const status = data[selectedRowIndex].gatePassPrintStatus;
        if (status !== "dispatched") {
            const result = window.confirm(
                "Loading List will be marked as Dispatched. Do you want to Continue?"
            );
            if (result) {
                const id = data[selectedRowIndex].loadingListId; // PUT ID HERE
                console.log(data[selectedRowIndex]);

                if (productName === "smartFab") {
                    dispatch(dispatchLoadingListSF(id));
                } else {
                    dispatch(dispatchLoadingList(id));
                }

                navigate("/gate pass print", {
                    state: { data: data[selectedRowIndex] },
                });
            }
        } else {
            navigate("/gate pass print", {
                state: { data: data[selectedRowIndex] },
            });
        }
    };

    const handlePrintLoadingList = () => {
        if (productName === "smartFab") {
            if (!isGeneratingPdf) setGeneratePdfOpen(true);
        } else {
            if (!isGeneratingPdf) setGeneratePdfOpen(true);
        }
    };

    const handleRefresh = () => {
        setFromDateFilter("");
        setToDateFilter("");
    };

    useEffect(() => {
        if (productName === "smartFab") {
            dispatch(fetchLoadingListSF());
            dispatch(fetchOrderSF());
        } else {
            dispatch(fetchLoadingList());
            dispatch(fetchOrder());
        }
    }, [dispatch, productName]);

    useEffect(() => {
        applyFilters();
        handleRefresh();
    }, [originalData]);

    useEffect(() => {
        applyFilters();
    }, [fromDateFilter, toDateFilter, dispatchStatusFilter]);

    const downloadBase64File = (base64Data, filename) => {
        const link = document.createElement("a");

        link.href = base64Data;

        link.download = filename;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    const handleDownload = () => {
        const orderId = data[selectedRowIndex].orderId;

        dispatch(
            downloadGenPdf(
                `?orderId=${orderId}&fileType=${
                    productName === "smartFab"
                        ? "smartFabDeliveryNote"
                        : "DeliveryNote"
                }`
            )
        ).then((res) => {
            if (res.payload) {
                // console.log(res.payload.json);
                if (res.payload.json[0]) {
                    downloadBase64File(
                        res.payload.json[0].filePath,
                        res.payload.json[0].fileName
                    );
                }
            }
        });
    };

    return (
        <div className="sdbr">
            <div className="filter-card">
                <div
                    className="filter"
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <label htmlFor="planStatus">Dispatch Status</label>
                    <select
                        name="planStatus"
                        id="planStatus"
                        value={dispatchStatusFilter}
                        style={{ width: "fit-content" }}
                        onChange={(e) =>
                            setDispatchStatusFilter(e.target.value)
                        }
                    >
                        <option value="">All</option>
                        {/* {dispatchStatusValues.map(data => (

              <option value={data}>{data}</option>

            ))} */}
                        <option value={"Not dispatched"}>Not dispatched</option>
                        <option value={"dispatched"}>dispatched</option>
                    </select>
                </div>
                <div
                    className="filter-bottom"
                    style={{ justifyContent: "space-between" }}
                >
                    <div className="DateCont">
                        <label className="DateHead">Gate pass print date</label>

                        <div className="DateFilt">
                            <div className="filterD">
                                <label htmlFor="fromDateFilter">From </label>
                                <input
                                    id="fromDateFilter"
                                    type="date"
                                    value={fromDateFilter}
                                    onChange={(e) =>
                                        setFromDateFilter(e.target.value)
                                    }
                                />
                            </div>
                            <div className="filterD">
                                <label htmlFor="toDateFilter">To</label>
                                <input
                                    id="toDateFilter"
                                    type="date"
                                    value={toDateFilter}
                                    onChange={(e) =>
                                        setToDateFilter(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "end",
                        }}
                    >
                        <img
                            src={refresh}
                            alt="refresh"
                            onClick={handleRefresh}
                            title="Refresh filters"
                        />
                    </div>
                </div>
            </div>

            <div className="head">
                Loading Details
                <div className="top-button-container">
                    <TopButtonContainer
                        add={() => {
                            navigate(`/create loading details`);
                        }}
                        edit={() => {
                            handleEdit();
                        }}
                        access={permission}
                        accessOverride={[1, 1, 0]}
                    />
                </div>
            </div>

            <AGTable
                columns={columns}
                data={data}
                onSelectRow={handleRowSelect}
                selectedRow={selectedRowIndex}
            />
            <div className="bottom-button-container">
                {/* <Button label="Create" className="blue" link='create loading details' /> */}
                {/* <Button label="Edit" className="blue" onClick={handleEdit} disabled={selectedRowIndex}/> */}
                {/* <Button label="Show" className="blue" /> */}
                <Button
                    label="Gate Pass Print"
                    className="blue"
                    onClick={handleGatePassPrint}
                    disabled={selectedRowIndex}
                />
                {/* <Button label="Summary" className="blue" /> */}
                <Button
                    label={"Generate Delivery Note"}
                    className="blue"
                    onClick={handlePrintLoadingList}
                    disabled={selectedRowIndex}
                />
                <Button
                    label={`Download Delivery Note`}
                    className="blue"
                    onClick={handleDownload}
                    disabled={selectedRowIndex}
                />
            </div>

            {generatePdfOpen && (
                <GenerateLoadingList
                    isGeneratingPdf={isGeneratingPdf}
                    setIsGeneratingPdf={setIsGeneratingPdf}
                    setGeneratePdfOpen={setGeneratePdfOpen}
                    data={data[selectedRowIndex]}
                    productName={productName}

                />
            )}

        </div>
    );
};

export default LoadingDetails;
//End of File

const GenerateLoadingList = (props) => {
    
    const { isGeneratingPdf, setIsGeneratingPdf, setGeneratePdfOpen } = props;

    const dispatch = useDispatch();
    const listDetails = props?.data;
    const bundleData = useSelector((state) => state.loadingList.labeledData4);

    const productName = useContext(UserAccessContext).productName;

    const serviceCenter1 =
        useSelector((state) => state.serviceCenter.labeledData).filter(
            (sc) => sc.imageUrl
        )[0] || "";

    
    const parameters = useSelector(
        (state) => state.productionParameter.labeledData)
        // .filter(item => listDetails.smartfabProductionRecordingId.includes(item.smartfabProductionRecordingId));

    const bufferToBase64 = (buffer) => {
        try {
            // console.log(buffer);

            const byteArray = new Uint8Array(buffer);
            const decoder = new TextDecoder("utf-8");

            let result = "";
            for (let i = 0; i < byteArray.length; i += 1024) {
                const chunk = byteArray.subarray(i, i + 1024);
                result += decoder.decode(chunk);
            }
            console.log("this ran");
            // console.log(result);
            return result;
        } catch (error) {
            console.log(error);
        }
    };

    const serviceCenterLogo = bufferToBase64(serviceCenter1?.imageUrl?.data);

    const [loading, setLoading] = useState(false);

    const [partItems, setPartItems] = useState([]);

    const [partItemIds, setPartItemIds] = useState([]);

    console.log({ bundleData, partItems });

    const getAllPartItems = (partIds) => {
        const orderIds = JSON.parse(`[${listDetails.orderId}]`).sort(
            (a, b) => a - b
        );
        let tempPartItems = [];

        if (bundleData.length === 0) {
            toast.error('No bundles found')
        }

        console.log(orderIds);

        orderIds.forEach((ele, index) => {
            dispatch(fetchPartItems(`?orderId=${ele}`)).then((res) => {
                if (res.payload) {
                    const tempData = res?.payload?.labeledData;
                    tempPartItems = [...tempPartItems, ...tempData];

                    if (orderIds.length === index + 1) {
                        setPartItems(() => {
                            let tempPartArray = tempPartItems.filter((item) =>
                                partIds.includes(item.partItemId)
                            );

                            tempPartArray
                                .sort((a, b) => a.orderItemId - b.orderItemId)
                                .forEach((rowData, index) => {
                                    let normalCount = 0,
                                        balanceQty = 0;
                                    bundleData
                                        .filter(
                                            (item) =>
                                                item.partItemId ===
                                                rowData.partItemId
                                        )
                                        .forEach((bundle) => {
                                            if (
                                                bundle.noOfPieces ===
                                                rowData.bundleQuantity
                                            ) {
                                                normalCount++;
                                            } else {
                                                balanceQty = bundle.noOfPieces;
                                            }
                                        });
                                    tempPartArray[index] = {
                                        ...rowData,
                                        bundles: `${normalCount}x(${
                                            rowData.bundleQuantity
                                        }) ${
                                            balanceQty !== 0
                                                ? `+ ${balanceQty}`
                                                : ""
                                        }`,
                                    };
                                });
                            return tempPartArray;
                        });
                        setLoading(false);
                    }
                }
            });
        });
    };

    useEffect(() => {
        if (productName === "smartFab") {
        } else {
            let partIds = [];
            bundleData.forEach((ele) => {
                partIds.push(ele.partItemId);
            });

            getAllPartItems(partIds);

            setPartItemIds(partIds);
        }

        // dispatch(fetchPartItems(`?partItemIds=${partItemIds}`))
    }, [bundleData]);

    const getCurrentDateTime = () => {
        const date = new Date();

        const formatData = (input) => (input < 10 ? `0${input}` : input);

        const day = formatData(date.getDate());
        const month = formatData(date.getMonth() + 1); // Months are zero-indexed
        const year = date.getFullYear();
        // const hours = formatData(date.getHours());
        // const minutes = formatData(date.getMinutes());

        return `${day}-${month}-${year}`;

        // T${hours}:${minutes}
    };

    const handleUpload = async () => {
        setIsGeneratingPdf(true);

        const toastId = toast.loading("Generating Pdf...");

        let blob;

        let idsJson
        let filteredParams = []
        if(productName==='smartFab'){
          // console.log(JSON.parse(`[${listDetails.smartfabProductionRecordingId}]`));
          
          idsJson = JSON.parse(`[${listDetails.smartfabProductionRecordingId}]`)
        }

        filteredParams = parameters.filter(item => idsJson.includes(item.smartfabProductionRecordingId*1))
        

        console.log(serviceCenterLogo, listDetails, parameters, filteredParams);
        

        try {
          if (productName === "smartFab")
            blob = await pdf(
                <SmFabLoadingListPdf
                    serviceCenterLogo={serviceCenterLogo}
                    listDetails={listDetails}
                    parameters={filteredParams}
                />
            ).toBlob();
        else
            blob = await pdf(
                <CreateLoadingListPDF
                    serviceCenterLogo={serviceCenterLogo}
                    listDetails={listDetails}
                    partItems={partItems}
                    bundleData={bundleData}
                />
            ).toBlob();

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
            const base64data = reader.result; // This is the Base64 string

            // Prepare data for upload
            const pdfData = {
                filePath: base64data,
                fileName: `${
                    listDetails.loadingListName
                }-DeliveryNote-${getCurrentDateTime()}`,
                fileType: `${
                    productName === "smartFab"
                        ? "smartFabDeliveryNote"
                        : "DeliveryNote"
                }`,
                orderId: listDetails.orderId,
                orderType: productName==="smartFab"?'smartFab':'readyBuild',
            };

            console.log(pdfData);

            try {
                dispatch(uploadGenPdf(pdfData)).then((res) => {
                    if (res.payload) {
                        toast.update(toastId, {
                            render: "Generated successfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 3000, // Close the toast after 3 seconds
                        });
                        setIsGeneratingPdf(false);
                    } else {
                        toast.update(toastId, {
                            render: "Failed to generate Pdf",
                            type: "error",
                            isLoading: false,
                            autoClose: 3000, // Close the toast after 3 seconds
                        });
                        setIsGeneratingPdf(false);
                    }
                });
            } catch (error) {
                console.error("Error uploading file:", error);
                toast.update(toastId, {
                  render: "Failed to generate Pdf",
                  type: "error",
                  isLoading: false,
                  autoClose: 3000, // Close the toast after 3 seconds
              });
                setIsGeneratingPdf(false);
            }
        };
        } catch (error) {
          toast.update(toastId, {
            render: "Failed to generate Pdf",
            type: "error",
            isLoading: false,
            autoClose: 3000, // Close the toast after 3 seconds
        });
        setIsGeneratingPdf(false);
        }
    };

    let functionTimerSett;

    useEffect(() => {
        if (
            (partItems.length !== 0 && !loading) ||
            (productName === "smartFab" && !loading && parameters.length!==0 && serviceCenterLogo)
        ) {
            clearTimeout(functionTimerSett);

            functionTimerSett = setTimeout(() => {
                handleUpload();
            }, 200);
        }

        return () => clearTimeout(functionTimerSett);
    }, [partItems, loading, parameters, serviceCenterLogo]);

    let fullTimedOut;

    useEffect(() => {
        fullTimedOut = setTimeout(() => {
            if (!isGeneratingPdf) setGeneratePdfOpen(false);
        }, 300);

        return () => clearTimeout(fullTimedOut);
    });

    return (
        <div
            style={{ height: "100%", flexDirection: "column", display: "none" }}
        ></div>
    );
};
