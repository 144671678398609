import React, { useState, useEffect, useRef } from "react";
import "../../../assets/styles/BbsShapeSetting.css";
import Button from "../../../utils/Button.jsx";
import { useDispatch, useSelector } from "react-redux";
// import CanvasDraw from "../../master/Shape/CanvasDraw.jsx";
import {
    editBmRow,
    fetchPartItems,
    updatePartItems,
} from "../../../redux-tookit/slices/partItemsSlice.jsx";
import { fetchGrades } from "../../../redux-tookit/slices/gradeMasterSlice.jsx";
import { toast } from "react-toastify";
import BBSDraw from "../../../components/Test/BBSDraw.jsx";
import { searchShapes } from "../../../redux-tookit/slices/shapeSlice.jsx";

const BbsShapeSetting = (props) => {
    const {
        BBSDetailsProps,
        tempDis,
        uTempDis,
        shapeD = [],
        orderId,
        materials = [],
        diameterValues,
        gradeValues,
        ordItems,
        bbsShapeId,
        editData = {},
        compAction = "add",
        id,
        mag = "100,147,1",
        lastData,
        actualShape,
        setActualShape,
        setBbsShapeIdEdit,
        setMag,
        setShDaBBSEdit,
        setQuickAddData,
        partItems,
        setElementValueAfterEdit,
        incrementLastChar,
        filteredData,
        changeElementValue,
        selectedPart
    } = props;

    //console.log({ shapeD, bbsShapeId, actualShape, mag });

    const dispatch = useDispatch();
    const [diaValues, uDiaValues] = useState([]);
    const [elementWiseData, setElementWiseData] = useState(null);
    const [totalNoOfPieces, setTotalNoOfPieces] = useState(0);
    const [grValues, uGrValues] = useState([]);
    const [orItValues, setOrItValues] = useState([]);
    const [changer, uChanger] = useState(true);
    const [localChanger, uLocalChanger] = useState(true);
    const saveInit = useRef(false);
    const [material, setMaterial] = useState([]);
    const [bbsCanvasRef, setBbsCanvasRef] = useState();
    const canvasRef = useRef(null);
    const [searchKey, setSearchKey] = useState("");
    const [saveTrigger, setSaveTrigger] = useState(false);

    const gradeMaster = useSelector((state) => state.gradeMaster.labeledData);

    let extraAdder = {};

    // console.log(BBSDetailsProps);

    const [focusedIndex, setFocusedIndex] = useState(0);
    const inputsRef = useRef([]);

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight') {
            setFocusedIndex((prevIndex) => Math.min(prevIndex + 1, inputsRef.current.length - 1));
            event.preventDefault();
        } else if (event.key === 'ArrowLeft') {
            setFocusedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            event.preventDefault();
        }
    };

    const defaultDiameter =
        BBSDetailsProps?.diameter ||
        [
            ...new Set(
                gradeMaster
                    .filter(
                        (item) => item.gradeName === BBSDetailsProps?.gradeName
                    )
                    .map((item) => item.diameter)
            ),
        ].sort((a, b) => a - b)[0];

    const [extraData, uExtraData] = useState({
        diameter: defaultDiameter,
        gradeName: BBSDetailsProps?.gradeName,
        pinDiameter: defaultDiameter * 4,
    });

    const [pinDiaValue, setPinDiaValue] = useState();

    useEffect(() => {
        if (compAction === 'add') {
            setPinDiaValue(extraData.diameter * 4);
        } else {
            setPinDiaValue(editData.pinDia);
        }
        
        //console.log(1);
    }, [extraData.diameter]);

    const [formData, setFormData] = useState({});

    // const [diameterValues, setDiameterValues] = useState([]);
    // const [gradeValues, setGradeValues] = useState([])
    // const [orderItemValues, setOrderItemValues] = useState([]);

    const [shapeData, uShapeData] = useState(shapeD);

    const [orX, orY, magValue] = mag.split(",");

    const [orginX, setOrginX] = useState(orX * 1);
    const [orginY, setOrginY] = useState(orY * 1);
    const [mF, setMF] = useState(magValue * 1);

    // console.log(mF, orginY, orginX);

    const [partItemDetails, uPartItemDetails] = useState({
        id: bbsShapeId, //
        serviceCenterId: 2,
        orderItemId:
            BBSDetailsProps.orderItemId ||
            (compAction === "add" && lastData?.orderItemId), //
        diffFactId: 2,
        routeId: 1,
        noOfPieces: BBSDetailsProps?.noOfPieces || lastData?.noOfPieces,
        totalNoOfPieces: totalNoOfPieces, //
        bundleId: 1,
        bmRow: "1", //posAfterBmrow
        lengthDeduction: 10,
        scale: 1,
        inventoryLength: "",
        productionLength: "",
        inventoryWeight: "",
        productionWeight: "",
        tagId: 1,
        tagDescription: "",
        bundleQuantity: totalNoOfPieces,
        // barMarkId: barMarkIdLength,
        plId: 1,
        image: "",
        pinDiameter: extraData.pinDiameter,
    });

    // console.log(partItemDetails, BBSDetailsProps, editData, extraData);

    const noOfSetsForDataShow =
        ordItems &&
        ordItems.length > 0 &&
        ordItems.find((item) => {
            if (item?.orderItemId === partItemDetails?.orderItemId) {
                return item;
            } else if (item?.orderItemName === partItemDetails?.orderItemId) {
                return item;
            }
        });

    useEffect(() => {
        // console.log(2);
        const data =
            BBSDetailsProps?.barMarkIdLength.filter(
                (item) => item.orderItemId === partItemDetails.orderItemId
            ).length + 1;

        setBarMarkId(editData.barMarkId ? editData.barMarkId : `BM_${data}`);
    }, [
        partItemDetails?.orderItemId,
        BBSDetailsProps?.barMarkIdLength,
        editData.barMarkId,
    ]);

    function getTotalNumberOfPieces() {
        if (noOfSetsForDataShow?.noOfSets && partItemDetails.noOfPieces) {
            setTotalNoOfPieces(
                noOfSetsForDataShow?.noOfSets === undefined
                    ? 0
                    : noOfSetsForDataShow?.noOfSets * partItemDetails.noOfPieces
            );
            uPartItemDetails({
                ...partItemDetails,
                totalNoOfPieces:
                    noOfSetsForDataShow?.noOfSets === undefined
                        ? 0
                        : noOfSetsForDataShow?.noOfSets *
                        partItemDetails.noOfPieces,
                bundleQuantity:
                    noOfSetsForDataShow?.noOfSets === undefined
                        ? 0
                        : noOfSetsForDataShow?.noOfSets *
                        partItemDetails.noOfPieces,
            });
            // console.log(
            //     "this ru",
            //     noOfSetsForDataShow?.noOfSets,
            //     partItemDetails.noOfPieces
            // );
        }
    }

    useEffect(() => {
        // console.log(9);
        getTotalNumberOfPieces();
    }, [noOfSetsForDataShow, BBSDetailsProps]);

    useEffect(() => {
        // console.log(3);

        let tempElementWiseData = {};

        BBSDetailsProps?.barMarkIdLength.map((item) => {
            if (item.orderItemId === partItemDetails.orderItemId) {
                tempElementWiseData = item;
                if (BBSDetailsProps?.diameter) {
                    tempElementWiseData = {
                        ...tempElementWiseData,
                        diameter: BBSDetailsProps?.diameter,
                    };
                }
            } else {
                tempElementWiseData = null;
            }
        });
        // console.log(tempElementWiseData);

        if (tempElementWiseData !== null) {
            uExtraData({
                ...extraData,
                diameter: tempElementWiseData?.diameter ||
                    BBSDetailsProps?.diameter,
                gradeName:
                    tempElementWiseData?.gradeName ||
                    BBSDetailsProps?.gradeName,
                // pinDiameter: editData.pinDia,
            });
        }

        setElementWiseData(tempElementWiseData);
    }, [BBSDetailsProps]);

    const [barMarkId, setBarMarkId] = useState();

    const editShapes = (index, header, value) => {
        uShapeData((prev) => {
            const newShapes = [...prev];
            newShapes[index][header] = value;
            return newShapes;
        });
        diaInputHandle();
        uChanger(!changer);
    };

    const addExtraData = () => {
        const noOfSet = orItValues.filter(
            (item) => item.orderItemId * 1 === partItemDetails.orderItemId * 1
        )[0].noOfSets;

        const totalPieces = partItemDetails.noOfPieces * noOfSet;
        let gradeId = "";
        let gradeName;

        gradeMaster.map((item) => {
            if (
                item.diameter * 1 === extraData.diameter * 1 &&
                `${item.gradeName}` === `${extraData.gradeName}`
            ) {
                gradeId = item.gradeId;
                gradeName = item.gradeName;

            }
        });

        // findGradeId(gradeMaster, extraData.gradeName, extraData.diameter)
        // gradeMaster.filter(item => (item.diameter * 1 === extraData.diameter * 1 && `${item.gradeName}` === `${extraData.gradeName}`))[0].gradeId

        const d = extraData.diameter;
        const xon = (totalPieces * Math.PI * d * d * 7850) / 4000000000;

        const invWeight = partItemDetails.inventoryLength * xon;
        const prodWeight = partItemDetails.productionLength * xon;

        extraAdder = {
            totalNoOfPieces: totalPieces,
            gradeId: gradeId,
            productionWeight: Math.round(prodWeight),
            inventoryWeight: Math.round(invWeight),
            gradeName,
            diameter: d * 1
        };
    };

    const handleSave = async (e) => {
        e.preventDefault();
        saveInit.current = true;
        setSaveTrigger(true)
        addExtraData();

        await new Promise((resolve) => {
            setTimeout(() => {
                resolve('resolved');
            }, 100);
        })

        const dataURL = canvasRef.current.toDataURL("image/png");

        const image = { image: dataURL };

        
        
        // const barMarkIdLength = extraData?.barMarkIdLength?.length || 0;
        // const barMarkId = `BM_${barMarkIdLength + 1}`;
        let type = {
            ...partItemDetails,
            ...extraAdder,
            ...image,
            barMarkId,

            imageProperties: JSON.stringify(shapeData),
            magnifications: `${Math.floor(orginX)},${Math.floor(orginY)},${Math.floor(mF * 1000) / 1000
                }`,
        };

        
        if (compAction==='add') {
            type = {...type, pinDia: selectedPart.pinDia};
        } else{
            type = {...type, pinDia: pinDiaValue};
        }

        if (type.productionLength > 12000) {
            // console.log('this run 00');
            //   toast.error("The Production length cannot be greater than 12000mm ");
            toast.warning('The production length is greater than 12000 mm.');
        }

        if (compAction === "update") {
            type = { ...type, id: bbsShapeId };
        }

        if (compAction === "update" && extraAdder.gradeId === "") {
            toast.error("No grade Id found");
            return;
        }

        // if (
        //     compAction === "update" &&
        //     partItemDetails.totalNoOfPieces < partItemDetails.bundleQuantity
        // ) {
        //     toast.error(
        //         "Pcs/bundle cannot be greater than total no. of pieces"
        //     );
        //     return;
        // }

        if (type.productionLength < extraData.diameter * 0) {
            toast.error(
                "The Production length cannot be less than 60 times of diameter "
            );
            return;
        }

       

        if (compAction === "add") {
            // console.log('this run 01');
            setQuickAddData(type);
            uTempDis();
            const selectElement = document.getElementById(
                "bbsEntryPcsPerBundle"
            );
            selectElement.focus();
            // dispatch(postPartItems(type)).then((res) => {
            //     if (res.payload !== undefined) {
            //         dispatch(fetchPartItems(`?orderId=${orderId}`));
            //         uTempDis();
            //     }
            // });
        } else
            dispatch(updatePartItems({ id, type })).then((res) => {
                if (res.payload !== undefined) {
                    if (editData.bmRow * 1 !== type.bmRow * 1) {
                        changeBmRow(type.bmRow);
                        // console.log(type.bmRow, 'hi');
            
                        setElementValueAfterEdit(partItemDetails.orderItemId);
                    }
                    dispatch(fetchPartItems(`?orderId=${orderId}`));
                    uTempDis();
                    incrementLastChar(filteredData);
                    const elementName = ordItems.find(
                        (item) => item.orderItemId * 1 === type.orderItemId
                    )?.orderItemName;
                    changeElementValue(elementName, type.gradeName);
                }
            });

        
        // console.log('this run 02');
    };

    const handleFormChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const ProdLengthCalculator = (
        dia = null,
        Dia = null,
        tempShapeData = null
    ) => {
        let cuLen = 0,
            direction = 0,
            invLen = 0,
            cu2 = 0;
        let D = extraData.diameter * 4,
            d = extraData.diameter * 1;
        if (dia !== null) {
            d = dia * 1;
            D = dia * 4;
        }

        if (Dia !== null) {
            D = Dia * 1;
        }

        let thisShapeData = shapeData;

        if (tempShapeData !== null) {
            thisShapeData = tempShapeData;
        }

        console.log(dia, Dia, d, D);


        thisShapeData.map((i, k) => {
            // direction = -(Math.atan(l2 / l1));
            // console.log('index:',k,'L:',i.Length1,'Ang:',i.Angle)

            i.Geometry === "Angle"
                ? (direction = direction - (i.Angle * Math.PI) / 180)
                : i.Geometry === "Arc"
                    ? i.Type === "Rad+Sw_Angle"
                        ? (direction = direction - (i.Angle * Math.PI) / 180)
                        : i.Type === "Chord+Normal"
                            ? (direction = direction - 2 * Math.PI)
                            : (direction =
                                direction - ((i.Length2 / i.Length1) * Math.PI) / 180)
                    : i.Geometry === "Length"
                        ? i.Type === "Offset + Normal"
                            ? (direction = -Math.atan(i.Length2 / i.Length1))
                            : console.log("#")
                        : console.log("#");

            if (i.Geometry === "Spring") {
                if (i.Match1 === "A") {
                    cuLen = 1 / i.Length1;
                } else if (i.Match1 === "B") {
                    cuLen = cuLen * i.Length1;
                } else if (i.Match1 === "C") {
                    cuLen = cuLen * i.Length1 * Math.PI;
                }
            }

            i.Geometry === "Length"
                ? i.Type === "Actual"
                    ? (cuLen = cuLen + 1 * i.Length1)
                    : i.Type === "Normal"
                        ? (cuLen = cuLen + i.Length1 / Math.sin(-direction))
                        : i.Type === "Offset"
                            ? (cuLen = cuLen + i.Length1 / Math.cos(-direction))
                            : (cuLen =
                                cuLen +
                                Math.sqrt(
                                    i.Length1 * i.Length1 + i.Length2 * i.Length2
                                ))
                : i.Geometry === "Arc"
                    ? i.Type === "Rad+Sw_Angle"
                        ? (cuLen =
                            cuLen +
                            (i.Length1 * Math.abs(i.Angle) * Math.PI) / 180)
                        : i.Type === "Dia+Sw_Angle"
                            ? (cuLen =
                                cuLen +
                                ((i.Length1 / 2) * Math.abs(i.Angle) * Math.PI) / 180)
                            : i.Type === "Chord+Normal"
                                ? (cuLen =
                                    cuLen +
                                    (((i.Length1 * i.Length1) / 4 +
                                        i.Length2 * i.Length2) /
                                        (2 * i.Length2)) *
                                    2 *
                                    Math.PI)
                                : (cuLen = cuLen + i.Length2 * 1)
                    : i.Geometry === "Angle" && k !== 0
                        ? i.Angle <= 90 && i.Angle >= -90
                            ? (cuLen =
                                cuLen -
                                (D * 1 + 2 * d) /
                                Math.tan(
                                    (90 - Math.abs(i.Angle) / 2) * (Math.PI / 180)
                                ) +
                                ((Math.PI * Math.abs(i.Angle)) / 360) *
                                (D * 1 + d * 1))
                            : (cuLen =
                                cuLen +
                                ((Math.PI * Math.abs(i.Angle)) / 360) * (D + d) -
                                (D * 1 + 2 * d))
                        : console.log("#");

            if (i.Geometry !== "Angle") {
                invLen = invLen + cuLen - cu2;
            }
            cu2 = cuLen;
            // console.log(cuLen);

        });

        // console.log(cuLen);

        uPartItemDetails({
            ...partItemDetails,
            productionLength: Math.round(cuLen),
            inventoryLength: Math.round(invLen),
        });

        uLocalChanger(!localChanger);
    };

    const diaInputHandle = (dia = null, Dia = null) => {
        if (partItemDetails.diameter !== "") {
            // console.log('1st');
            if (compAction==='add') {
                ProdLengthCalculator(dia, selectedPart.pinDia);
                console.log(dia, selectedPart.pinDia);
                
            } else
            ProdLengthCalculator(dia, Dia);
        }
    };

    useEffect(() => {
        // console.log(5);

        uShapeData(() => {
            const tempArray = JSON.parse(JSON.stringify(shapeD));
            return tempArray;
        });

        // console.log('2nd');
        if (compAction==='add') {
            ProdLengthCalculator(null, selectedPart.pinDia*1 || null, JSON.parse(JSON.stringify(shapeD)));
        } else 
        ProdLengthCalculator(null, null, JSON.parse(JSON.stringify(shapeD)));

    }, [shapeD]);

    useEffect(() => {
        // console.log(6);

        dispatch(fetchGrades());
    }, [dispatch]);

    useEffect(() => {
        uPartItemDetails({
            ...partItemDetails,
            bundleQuantity: totalNoOfPieces,
        });
    }, [totalNoOfPieces]);

    //   noOfSetsForDataShow?.noOfSets);

    useEffect(() => {
        // console.log(7);

        // setDiameterValues([...new Set(materials.map(item => item.diameter))].sort((a, b) => a - b))
        // setGradeValues([...new Set(materials.map(item => item.gradeName))])
        setOrItValues(ordItems);

        uDiaValues(diameterValues);
        uGrValues(gradeValues);
        // uPartItemDetails({ ...partItemDetails });
        setMaterial(materials);
        if (compAction === "update") {
            uPartItemDetails({ ...partItemDetails, ...editData });
            // console.log(editData);

            if (editData.diameter && editData.gradeName && editData.pinDia) {
                // console.log(editData.diameter);

                uExtraData({
                    ...extraData,
                    diameter: editData.diameter,
                    gradeName: editData.gradeName,
                    pinDiameter: editData.pinDia,
                });
            } else if (editData.gradeName) {
                uExtraData({ ...extraData, gradeName: editData.gradeName });
            }
        }
    }, [diameterValues, editData]);

    const changeBmRow = (value) => {
        const type = {
            orderItemId: partItemDetails.orderItemId,
            partItemId: id,
            bmRow: value * 1,
        };
        dispatch(editBmRow(type));
    };

    const HandleElementChange = () => {
        //renders grades based on element
        // console.log(grValues, ordItems);

        return compAction === "add" &&
            BBSDetailsProps?.barMarkIdLength?.filter(
                (item) => item.orderItemId === partItemDetails.orderItemId
            )?.length > 0 ? (
            <option
                value={
                    ordItems?.find(
                        (item) =>
                            item.orderItemId === partItemDetails.orderItemId
                    )?.gradeName
                }
                hidden
            >
                {
                    ordItems?.find(
                        (item) =>
                            item.orderItemId === partItemDetails.orderItemId
                    )?.gradeName
                }
            </option>
        ) : compAction === "update" &&
            (BBSDetailsProps?.barMarkIdLength?.filter(
                (item) => item.orderItemId === partItemDetails.orderItemId
            )?.length > 1 ||
                editData.orderItemId !== partItemDetails.orderItemId) ? (
            <option value={extraData.gradeName} hidden>
                {extraData.gradeName}
            </option>
        ) : (
            <>
                {grValues.map((value) => (
                    <option value={value}>{value}</option>
                ))}
            </>
        );
    };

    const handleEditShapeChange = (shapeId) => {
        dispatch(searchShapes(`?shapeId=${shapeId}`)).then((res) => {
            if (res.payload) {
                if (res.payload.shapes.length === 0) {
                    toast.error(`No shape found, ${shapeId}`);
                    return;
                }
                setActualShape(res.payload.shapes[0].imageProperties);
                setBbsShapeIdEdit(res.payload.shapes[0].id);
                setMag(res.payload.shapes[0].magnifications);
                setShDaBBSEdit(res.payload.shapes[0].imageProperties);
                const [orX, orY, magValue] =
                    res.payload.shapes[0].magnifications.split(",");

                setOrginX(orX * 1);
                setOrginY(orY * 1);
                setMF(magValue * 1);
                // console.log('3rd');

                ProdLengthCalculator(
                    null,
                    null,
                    JSON.parse(res.payload.shapes[0].imageProperties)
                );
            }
        });
    };


    const updownArrowDisabler = (e, index) => {
        try {
            if ([38, 40].includes(e.keyCode)) {
                // Prevent the default behavior of the arrow key
                e.preventDefault();

                const visibleInputs = inputsRef.current.filter(ref => ref !== null); // Filter out null refs

                // console.log(visibleInputs);

                if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    // Focus on the next visible input
                    const nextIndex = visibleInputs.indexOf(inputsRef.current[index]) + 1;
                    if (nextIndex < visibleInputs.length) {
                        visibleInputs[nextIndex]?.focus();
                    }
                } else if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    // Focus on the previous visible input
                    const prevIndex = visibleInputs.indexOf(inputsRef.current[index]) - 1;
                    if (prevIndex >= 0) {
                        visibleInputs[prevIndex]?.focus();
                    }
                }

            }
        } catch (error) {
            // console.log(error);
        }

    }




    return (
        <div
            className="bbsSet"
            style={{ display: `${tempDis === true ? `block` : `none`}` }}
        >
            <div className="overlay-hide-bg"></div>
            <form onSubmit={handleSave}>
                <div
                    className="bbsPopAlignContainer"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100vw",
                    }}
                >
                    <div className="dialog-box-1">
                        {compAction === "add" ? (
                            ""
                        ) : (
                            <div className="row-1">
                                <div className="box-1">
                                    <div className="box-row-1">
                                        <div className="row-item-1">
                                            <label htmlFor="diameter">
                                                Bar Mark Id
                                            </label>
                                            <input
                                                type="text"
                                                id="diameter"
                                                defaultValue={
                                                    partItemDetails.barMarkId
                                                }
                                                // defaultValue={barMarkId}
                                                required
                                                style={{
                                                    width: "5vw",
                                                    height: "25px",
                                                }}
                                                onChange={(e) => {
                                                    setBarMarkId(
                                                        e.target.value
                                                    );
                                                    uExtraData({
                                                        ...extraData,
                                                        barMarkId:
                                                            e.target.value,
                                                    });

                                                    handleFormChange(e);
                                                }}
                                            ></input>
                                        </div>
                                        <div className="row-item-1">
                                            <label htmlFor="Grade">
                                                Grade Name
                                            </label>
                                            <select
                                                style={{ height: "37px" }}
                                                value={extraData.gradeName}
                                                required
                                                onChange={(e) => {
                                                    uExtraData({
                                                        ...extraData,
                                                        gradeName:
                                                            e.target.value,
                                                        diameter: [
                                                            ...new Set(
                                                                gradeMaster
                                                                    .filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.gradeName ===
                                                                            e
                                                                                .target
                                                                                .value
                                                                    )
                                                                    .map(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.diameter
                                                                    )
                                                            ),
                                                        ].sort(
                                                            (a, b) => a - b
                                                        )[0],
                                                    });
                                                    handleFormChange(e);
                                                }}
                                            >
                                                <HandleElementChange />
                                            </select>
                                        </div>
                                        <div className="row-item-1">
                                            <label htmlFor="diameter">
                                                Diameter
                                            </label>
                                            <select
                                                style={{ height: "37px" }}
                                                id="diameter"
                                                value={extraData.diameter}
                                                required
                                                onChange={(e) => {
                                                    uExtraData({
                                                        ...extraData,
                                                        diameter:
                                                            e.target.value,
                                                    });
                                                    diaInputHandle(
                                                        e.target.value
                                                    );
                                                    handleFormChange(e);
                                                    setPinDiaValue(
                                                        e.target.value * 4
                                                    );
                                                }}
                                            >
                                                {BBSDetailsProps?.diameter ===
                                                    undefined ? (
                                                    <></>
                                                ) : (
                                                    <option
                                                        value={
                                                            BBSDetailsProps?.diameter
                                                        }
                                                        hidden
                                                    >
                                                        {
                                                            BBSDetailsProps?.diameter
                                                        }
                                                    </option>
                                                )}
                                                {/* </option> */}
                                                {[
                                                    ...new Set(
                                                        gradeMaster
                                                            .filter(
                                                                (item) =>
                                                                    item.gradeName ===
                                                                    extraData.gradeName
                                                            )
                                                            .map(
                                                                (item) =>
                                                                    item.diameter
                                                            )
                                                    ),
                                                ]
                                                    .sort((a, b) => a - b)
                                                    .map((value) => (
                                                        <option value={value}>
                                                            {" "}
                                                            {value}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        <div className="row-item-1">
                                            <label htmlFor="diameter">
                                                Pin Dia
                                            </label>
                                            <input
                                                type="text"
                                                id="diameter"
                                                defaultValue={pinDiaValue}
                                                required
                                                value={pinDiaValue}
                                                style={{
                                                    width: "5vw",
                                                    height: "27px",
                                                }}
                                                onChange={(e) => {
                                                    setPinDiaValue(
                                                        e.target.value
                                                    );
                                                    uExtraData({
                                                        ...extraData,
                                                        pinDiameter:
                                                            e.target.value,
                                                    });
                                                    diaInputHandle(
                                                        null,
                                                        e.target.value
                                                    );
                                                    handleFormChange(e);
                                                }}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="box-row-1">
                                        {Object.keys(partItemDetails).map(
                                            (header) =>
                                                header === "noOfPieces" ? (
                                                    <>
                                                        <div className="row-item-1">
                                                            <label htmlFor="customerName">
                                                                No Of Pieces
                                                                <span
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                style={{
                                                                    height: "26px",
                                                                }}
                                                                type="number"
                                                                value={
                                                                    partItemDetails[
                                                                    header
                                                                    ]
                                                                }
                                                                required
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    uPartItemDetails(
                                                                        {
                                                                            ...partItemDetails,
                                                                            noOfPieces:
                                                                                e
                                                                                    .target
                                                                                    .value *
                                                                                1,
                                                                            totalNoOfPieces:
                                                                                noOfSetsForDataShow?.noOfSets *
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            bundleQuantity:
                                                                                noOfSetsForDataShow?.noOfSets *
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                    setTotalNoOfPieces(
                                                                        noOfSetsForDataShow?.noOfSets *
                                                                        e
                                                                            .target
                                                                            .value
                                                                    );
                                                                    handleFormChange(
                                                                        e
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="row-item-1">
                                                            <label htmlFor="customerName">
                                                                Total No Of
                                                                Pieces
                                                                <span
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                style={{
                                                                    height: "26px",
                                                                }}
                                                                type="number"
                                                                value={
                                                                    partItemDetails.totalNoOfPieces
                                                                }
                                                                required
                                                            />
                                                        </div>
                                                    </>
                                                ) : header ===
                                                    "bundleQuantity" ? (
                                                    <div className="row-item-1">
                                                        <label htmlFor="customerName">
                                                            Pcs/Bundles
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            style={{
                                                                height: "26px",
                                                            }}
                                                            type="number"
                                                            defaultValue={
                                                                totalNoOfPieces
                                                            }
                                                            value={
                                                                partItemDetails[
                                                                header
                                                                ]
                                                            }
                                                            required
                                                            min={1}
                                                            onKeyDown={(e) =>
                                                                e.key === "e"
                                                                    ? e.preventDefault()
                                                                    : ""
                                                            }
                                                            onChange={(e) => {
                                                                uPartItemDetails(
                                                                    {
                                                                        ...partItemDetails,
                                                                        bundleQuantity:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    }
                                                                );
                                                                handleFormChange(
                                                                    e
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    // header === 'pinDia' ? (<div className="row-item-1">
                                                    //     <label htmlFor="customerName">Pin Dia.<span style={{ color: 'red' }}>*</span></label>
                                                    //     <input
                                                    //         type='number'
                                                    //         value={partItemDetails[header]}
                                                    //         required
                                                    //         onChange={(e) => { uPartItemDetails({ ...partItemDetails, pinDia: e.target.value }); handleFormChange(e) }}
                                                    //         onBlur={() => diaInputHandle()} />
                                                    // </div>) :
                                                    ""
                                                )
                                        )}
                                    </div>

                                    <div className="box-row-3">
                                        <div className="row-item-1">
                                            <label htmlFor="customerName">
                                                Tag Desc
                                            </label>
                                            <input
                                                type="text"
                                                value={
                                                    partItemDetails.tagDescription
                                                }
                                                onChange={(e) => {
                                                    uPartItemDetails({
                                                        ...partItemDetails,
                                                        tagDescription:
                                                            e.target.value,
                                                    });
                                                    handleFormChange(e);
                                                }}
                                            />
                                        </div>
                                        <div className="row-item-1">
                                            <label htmlFor="route">Route</label>
                                            <select
                                                id="route"
                                                value={partItemDetails.routeId}
                                                required
                                                onChange={(e) =>
                                                    uPartItemDetails({
                                                        ...partItemDetails,
                                                        routeId: e.target.value,
                                                    })
                                                }
                                            >
                                                <option value="1">
                                                    Rebar Route
                                                </option>
                                                <option value="2">
                                                    Coil Route
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-2">
                                    <div className="box-row-2">
                                        <div className="row-item-1">
                                            <label htmlFor="prodLength">
                                                Prod. Length (auto-fill)
                                            </label>
                                            <input
                                                type="text"
                                                id="prodLength"
                                                value={
                                                    partItemDetails.productionLength
                                                }
                                                onChange={(e) => {
                                                    handleFormChange(e);
                                                    uPartItemDetails({
                                                        ...partItemDetails,
                                                        productionLength:
                                                            e.target.value,
                                                    });
                                                }}
                                                required
                                            />
                                        </div>

                                        <div className="row-item-1">
                                            <label htmlFor="element">
                                                Element
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            </label>
                                            <select
                                                id="element"
                                                value={
                                                    partItemDetails.orderItemId
                                                }
                                                required
                                            // onChange={(e) => {
                                            //     uPartItemDetails({
                                            //         ...partItemDetails,
                                            //         orderItemId:
                                            //             e.target.value * 1,
                                            //     });
                                            //     if (
                                            //         ordItems?.filter(
                                            //             (item) =>
                                            //                 item.orderItemId ===
                                            //                 e.target.value *
                                            //                     1
                                            //         ).length !== 0
                                            //     ) {
                                            //         uExtraData({
                                            //             ...extraData,
                                            //             gradeName:
                                            //                 ordItems?.find(
                                            //                     (item) =>
                                            //                         item.orderItemId ===
                                            //                         e.target
                                            //                             .value *
                                            //                             1
                                            //                 )?.gradeName,
                                            //             diameter:
                                            //                 ordItems?.find(
                                            //                     (item) =>
                                            //                         item.orderItemId ===
                                            //                         e.target
                                            //                             .value *
                                            //                             1
                                            //                 )?.diameter,
                                            //         });
                                            //     }

                                            //     handleFormChange(e);
                                            // }}
                                            >
                                                {/* <option value="" hidden>
                                                    -Select-
                                                </option> */}
                                                {orItValues.map(
                                                    (value) =>
                                                        value.orderItemId ===
                                                        partItemDetails.orderItemId && (
                                                            <option
                                                                value={
                                                                    value.orderItemId
                                                                }
                                                            >
                                                                {
                                                                    value.orderItemName
                                                                }
                                                            </option>
                                                        )
                                                )}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="box-row-1">
                                        <div className="row-item-1">
                                            <label htmlFor="PlId">Pl Id</label>
                                            <select
                                                value={partItemDetails.plId}
                                                onChange={(e) =>
                                                    uPartItemDetails({
                                                        ...partItemDetails,
                                                        plId: e.target.value,
                                                    })
                                                }
                                            >
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                        <div className="row-item-1">
                                            <label htmlFor="BarType">
                                                Bar Type
                                            </label>
                                            <select
                                                value={extraData.barType}
                                                onChange={(e) =>
                                                    uExtraData({
                                                        ...extraData,
                                                        barType: e.target.value,
                                                    })
                                                }
                                            >
                                                <option value="T">T</option>
                                            </select>
                                        </div>
                                        <div className="row-item-1">
                                            <label htmlFor="noOfSets">
                                                No Of Sets
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                type="text"
                                                value={
                                                    noOfSetsForDataShow?.noOfSets
                                                }
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="box-row-1">
                                        <div className="row-item-1">
                                            <label htmlFor="bmRow">
                                                Position after bmRow
                                            </label>
                                            <select
                                                name="bmRow"
                                                id="bmRow"
                                                // value={partItemDetails.bmRow}
                                                onChange={(e) =>
                                                    uPartItemDetails({
                                                        ...partItemDetails,
                                                        bmRow: e.target.value,
                                                    })
                                                }
                                            >
                                                <option value="" hidden>-Select-</option>
                                                {partItems
                                                    .filter(
                                                        (item) =>
                                                            ((partItemDetails.orderItemId ===
                                                            item.orderItemId) &&
                                                            (id*1 !== item.partItemId*1))
                                                    )
                                                    .map((tag, index) => (
                                                        <option
                                                            value={tag.bmRow}
                                                        >
                                                            {tag.barMarkId}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row-2">
                            <div
                                className="box-3"
                                style={{
                                    position: "relative",
                                    height: "fit-content",
                                    marginBottom: "0",
                                }}
                            >
                                <BBSDraw
                                    bbs={true}
                                    drawingElements={shapeData}
                                    changer={changer}
                                    partItemDetails={partItemDetails}
                                    uPartItemDetails={uPartItemDetails}
                                    saveInit={saveInit}
                                    handleSave={handleSave}
                                    orginX={orginX}
                                    setOrginX={setOrginX}
                                    orginY={orginY}
                                    setOrginY={setOrginY}
                                    mF={mF}
                                    setMF={setMF}
                                    constantLengthValue={actualShape}
                                    canvasRef={canvasRef}
                                    saveTrigger={saveTrigger}
                                />
                                {compAction === 'add' && (
                                    <div className="row-item-1">
                                        <label htmlFor="prodLength">
                                            Prod. Length (auto-fill)
                                        </label>
                                        <input
                                            type="text"
                                            id="prodLength"
                                            style={{ width: '70px' }}
                                            value={
                                                partItemDetails.productionLength
                                            }
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                uPartItemDetails({
                                                    ...partItemDetails,
                                                    productionLength:
                                                        e.target.value,
                                                });
                                            }}
                                            required
                                        />
                                    </div>
                                )}
                                {compAction === "update" && (
                                    <div
                                        className="pBM"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginRight: "2vw",
                                            position: "absolute",
                                            bottom: "0",
                                            right: "0",
                                            marginBottom: "0.5vw",
                                        }}
                                    >
                                        <label
                                            htmlFor=""
                                            style={{
                                                paddingLeft: "0.625rem",
                                                marginBottom: "0.250rem",
                                            }}
                                        >
                                            Change Shape
                                        </label>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <input
                                                id="bbsEntryShapeId"
                                                type="text"
                                                style={{
                                                    width: "4.375rem",
                                                    height: "1rem",
                                                    padding: "0.1875rem",
                                                    marginLeft: "0.625rem",
                                                    border: "2px solid #d5d5d5",
                                                    borderRadius: "0.3125rem",
                                                }}
                                                value={searchKey}
                                                onChange={(e) => {
                                                    setSearchKey(
                                                        e.target.value
                                                    );
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleEditShapeChange(
                                                            searchKey
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                        <Button
                                            label="Update Shape"
                                            onClick={() =>
                                                handleEditShapeChange(searchKey)
                                            }
                                            className="blue"
                                            disabled={searchKey}
                                            message="No Id entered. Please enter an Id and try again"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="box-4">
                                <div
                                    className="table-container"
                                    style={{ maxHeight: `${compAction==='add'?'400px':'300px'}` }}
                                >
                                    <table className="generic-table">
                                        <thead className="stick">
                                            <tr>
                                                <th className="table-header">
                                                    Character
                                                </th>
                                                <th className="table-header">
                                                    Value
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                shapeData.map((el, index) => {
                                                    let Ch1 = "",
                                                        Ch2 = "";
                                                    if (el.Geometry === "Arc") {
                                                        let [Cha1, Cha2] =
                                                            el.Type.split("+");
                                                        Ch1 = Cha1;
                                                        Ch2 = Cha2;
                                                    }



                                                    return (el.Geometry ===
                                                        "Length" &&
                                                        el.Type === "Actual" &&
                                                        el.Length1 !== 0 &&
                                                        el.Length1 !== "0") ||
                                                        el.Geometry === "Spring" ? (
                                                        <tr>
                                                            <td>{el.Match1}</td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    value={
                                                                        el.Length1
                                                                    }
                                                                    tabIndex={0}
                                                                    ref={(e) => (inputsRef.current[index] = e)}
                                                                    onFocus={(e) => { e.target.select(); }}
                                                                    onKeyDown={(e) => updownArrowDisabler(e, index)}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        editShapes(
                                                                            index,
                                                                            "Length1",
                                                                            e.target
                                                                                .value
                                                                        );
                                                                        handleFormChange(
                                                                            e
                                                                        );
                                                                    }}
                                                                    autoFocus={
                                                                        index ===
                                                                        0 ||
                                                                        index === 1
                                                                    }
                                                                    onBlur={(e) => {
                                                                        if (el.Geometry ===
                                                                            "Length" &&
                                                                            el.Type === "Actual") {
                                                                            if (e.target.value < (extraData.diameter * 6)) {
                                                                                toast.warning('Entered length is less than 6 times dia')
                                                                            }

                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ) : el.Geometry === "Angle" &&
                                                        index !== 0 &&
                                                        el.Capt2 === "Yes" ? (
                                                        <tr>
                                                            <td>{el.Match1}</td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    value={el.Angle}
                                                                    tabIndex={0}
                                                                    ref={(e) => (inputsRef.current[index] = e)}
                                                                    onFocus={(e) => { e.target.select(); }}
                                                                    onKeyDown={(e) => updownArrowDisabler(e, index)}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        editShapes(
                                                                            index,
                                                                            "Angle",
                                                                            e.target
                                                                                .value
                                                                        );
                                                                        handleFormChange(
                                                                            e
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ) : el.Geometry === "Arc" ? (
                                                        <tr>
                                                            <td>{el.Match1}</td>
                                                            <td>
                                                                {Ch1}
                                                                {` `}
                                                                <input
                                                                    type="number"
                                                                    value={
                                                                        el.Length1
                                                                    }
                                                                    min={0}
                                                                    tabIndex={0}
                                                                    ref={(e) => (inputsRef.current[index] = e)}
                                                                    onFocus={(e) => { e.target.select(); }}
                                                                    onKeyDown={(e) => updownArrowDisabler(e, index)}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        editShapes(
                                                                            index,
                                                                            "Length1",
                                                                            e.target
                                                                                .value
                                                                        );
                                                                        handleFormChange(
                                                                            e
                                                                        );
                                                                    }}
                                                                    style={{
                                                                        width: "20%",
                                                                    }}
                                                                    autoFocus={
                                                                        index === 0
                                                                    }
                                                                />
                                                                {` `}
                                                                {Ch2}
                                                                {` `}
                                                                <input
                                                                    type="number"
                                                                    value={
                                                                        el.Type ===
                                                                            "Chord+Normal" ||
                                                                            el.Type.includes(
                                                                                "ArcLength"
                                                                            )
                                                                            ? el.Length2
                                                                            : el.Angle
                                                                    }
                                                                    tabIndex={0}
                                                                    ref={(e) => (inputsRef.current[index] = e)}
                                                                    onFocus={(e) => { e.target.select(); }}
                                                                    onKeyDown={(e) => updownArrowDisabler(e, index)}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        editShapes(
                                                                            index,
                                                                            el.Type ===
                                                                                "Chord+Normal" ||
                                                                                el.Type.includes(
                                                                                    "ArcLength"
                                                                                )
                                                                                ? "Length2"
                                                                                : "Angle",
                                                                            e.target
                                                                                .value
                                                                        );
                                                                        handleFormChange(
                                                                            e
                                                                        );
                                                                    }}
                                                                    style={{
                                                                        width: "20%",
                                                                    }}
                                                                    autoFocus={
                                                                        index === 0
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        ""
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="bottom-button">
                                    <Button
                                        label="Save"
                                        className="blue"
                                        type="submit"
                                    />
                                    <Button
                                        label="Cancel"
                                        className="blue"
                                        onClick={() => uTempDis(!tempDis)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BbsShapeSetting;
//End of File
