import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/styles/BBS.css";
import Shapes from "../../master/Shape/Shapes";
import Button from "../../../utils/Button";
import BbsShapeSetting from "./BbsShapeSetting";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchOrder } from "../../../redux-tookit/slices/orderSlice";
import { fetchGrades } from "../../../redux-tookit/slices/gradeMasterSlice";
import { clearOrderItems, fetchOrderItems, resetOrderItems } from "../../../redux-tookit/slices/orderItemsSlice";
import BBSAGTable from "../../../components/Tables/BBSAGTable.jsx";
import {
    clearPartItems,
    deletePartItems,
    fetchPartItems,
    postPartItems,
    resetPartItems,
} from "../../../redux-tookit/slices/partItemsSlice";
import { toast } from "react-toastify";
import { searchShapes } from "../../../redux-tookit/slices/shapeSlice";
import search from "../../../assets/images/button-icons/Search.svg";
import TopButtonContainer from "../../../components/UserAccess/TopButtonContainer";
import { fetchUserAccess } from "../../../redux-tookit/slices/userAccessSlice";
import { dateChanger } from "../../../components/reusableFunctions";
import { ReactComponent as BackIcon } from "../../../assets/images/button-icons/back-icon.svg";
import AddElements from "../OrderDetails/AddElements.jsx";
import { fetchBundles } from "../../../redux-tookit/slices/loadingListSlice.jsx";
import { Document, pdf } from "@react-pdf/renderer";
import MyDocument from "../../../components/pdfCreator/MyDocument.jsx";
import { fetchCustomerParties } from "../../../redux-tookit/slices/customerPartiesSlice.jsx";
import { fetchServiceCenter } from "../../../redux-tookit/slices/serviceCenterSlice.jsx";
import {
    downloadGenPdf,
    uploadGenPdf,
} from "../../../redux-tookit/slices/bbsPdfGenerationSlice.jsx";

const BBS = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { orderId } = useParams();

    const [combId, setCombId] = useState(location.state?.combId);

    const orderData = useSelector((state) => state.order.labeledData).filter(
        (item) => `${item.orderId}` === orderId
    );

    const materials = useSelector((state) => state.gradeMaster.labeledData);
    const partItems = useSelector((state) => state.partItems.labeledData);

    const ordItems = useSelector((state) => state.orderItems.labeledData);
    // .filter((item) => `${item.orderId}` === orderId)

    const [orderItems, setOrderItems] = useState(ordItems);

    const shapes = useSelector((state) => state.shape.shapes);

    const columns = useSelector((state) => state.partItems.columns);
    const loading = useSelector((state) => state.partItems.loading);

    const [diameterValues, setDiameterValues] = useState([]);
    const [gradeValues, setGradeValues] = useState([]);
    const [orderItemValues, setOrderItemValues] = useState([]);
    const [orderItemsFilter, setOrderItemsFilter] = useState(
        location?.state?.orderItemName || ""
    );
    const [selectedPart, uSelectedPart] = useState({
        plId: "1",
        barType: "T",
        gradeName: "IS:1786 - FE550D",
    });

    const [selOrderItem, uSelOrderItem] = useState("");
    const [shDaBBS, setShDaBBS] = useState([]);
    const [shDaBBSEdit, setShDaBBSEdit] = useState([]);
    const [actualShape, setActualShape] = useState([]);

    const [shapeId, setShapeId] = useState(null);
    const [partiesId, setPartiesId] = useState(null);
    const [multiDelete, setMultiDelete] = useState(false);
    const [editData, setEditData] = useState({});
    const [data, setData] = useState([]);
    const [id, setId] = useState(null);
    const [bbsShapeId, setBbsShapeId] = useState(null);
    const [bbsShapeIdEdit, setBbsShapeIdEdit] = useState(null);
    // const [dia, setDia] = useState('');
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [deselector, setDeselector] = useState(0);
    const [shapeIdInput, setShapeIdInput] = useState(true);
    const [mag, setMag] = useState("");
    const [shapeIdInputValue, setShapeIdInputValue] = useState("");
    const [orderItemId, setOrderItemId] = useState("");
    const [dataUpdatedIndex, setDataUpdatedIndex] = useState(0);

    const [searchKey, setSearchKey] = useState("");

    const [lengthDeduction, setLengthDeduction] = useState(true);

    const [elementAdding, setElementAdding] = useState(false);

    const [permission, setPermission] = useState({});

    const [selectedNoOfSets, setSelectedNoOfSets] = useState(1);

    const [quickAddData, setQuickAddData] = useState([]);

    //PDF PRINT STATES START

    const [generatePdfOpen, setGeneratePdfOpen] = useState(false);

    const [generatingPdf, setIsGeneratingPdf] = useState(false);

    const [bundleData, setBundleData] = useState([]);

    const [filteredWeight, setFilteredWeight] = useState(0);

    const bufferToBase64 = (buffer) => {
        try {
            const byteArray = new Uint8Array(buffer);
            const decoder = new TextDecoder("utf-8");

            let result = "";
            for (let i = 0; i < byteArray.length; i += 1024) {
                const chunk = byteArray.subarray(i, i + 1024);
                result += decoder.decode(chunk);
            }

            return result;
        } catch (error) {
            console.log(error);
        }
    };

    const serviceCenter1 =
        useSelector((state) => state.serviceCenter.labeledData).filter(
            (sc) => sc.imageUrl
        )[0] || "";

    const serviceCenterLogo = useMemo(() => {
        return bufferToBase64(serviceCenter1?.imageUrl?.data) || null;
    }, [serviceCenter1]);

    const getBundlles = async () => {
        dispatch(fetchBundles(`?orderId=${orderId}`)).then((res) => {
            if (res.payload) {
                if (
                    res.payload.labeledData.filter(
                        (item) => `${item.orderId}` === orderId
                    ).length === 0
                ) {
                    toast.error("No bundles in this order");
                } else
                    setBundleData(
                        res.payload.labeledData.filter(
                            (item) => `${item.orderId}` === orderId
                        )
                    );
            }
        });
    };

    const parties = useSelector((state) => state.customerParties.labeledData);

    //PDF PRINT STATES END

    useEffect(() => {
        dispatch(fetchUserAccess()).then((res) => {
            if (res.payload) {
                if (res.payload.navbarNames.Transaction["Stock Details"][0]) {
                    setPermission(
                        res.payload.navbarNames.Transaction["Stock Details"][0]
                    );
                }
            }
        });
    }, []);

    const BBSDetailsProps = useMemo(() => {
        return {
            gradeName:
                selectedPart?.gradeName ||
                partItems[partItems.length - 1]?.gradeName,
            diameter:
                selectedPart?.diameter ||
                partItems[partItems.length - 1]?.diameter,
            noOfPieces: selectedPart?.noOfPieces,
            Element: orderItemsFilter,
            orderItemId: orderItemId,
            barMarkIdLength: partItems,
            tagDetails: data,
        };
    }, [
        partItems,
        selectedPart?.gradeName,
        selectedPart?.diameter,
        orderItemsFilter,
        orderItemId,
        selectedPart?.noOfPieces,
    ]);

    useEffect(() => {
        let tempOrderItems = ordItems;

        setOrderItems(
            ordItems.map((item) => {
                if (partItems.length !== 0) {
                    const foundGrade = partItems.find(
                        (data) => data.orderItemId === item.orderItemId
                    );
                    return {
                        ...item,
                        gradeName: foundGrade?.gradeName,
                        diameter: foundGrade?.diameter,
                    };
                } else return item;
            })
        );

        setOrderItemValues([
            ...new Set(tempOrderItems.map((items) => items.orderItemName)),
        ]);
    }, [ordItems]);

    const handleRowSelect = (rowIndex) => {
        setSelectedRowIndex(rowIndex);

        if (multiDelete === false && rowIndex !== null) {
            try {
                dispatch(
                    searchShapes(`?shapeId=${data[rowIndex].shapeId}`)
                ).then((res) => {
                    if (res.payload) {
                        if (res.payload.shapes.length === 0) {
                            toast.error(
                                `No shape found, ${data[rowIndex].shapeId}`
                            );
                            return;
                        }
                        setActualShape(res.payload.shapes[0].imageProperties);
                    }
                });
                setBbsShapeIdEdit(data[rowIndex].id);
                let shapeDA = `${data[rowIndex].imageProperties}`;
                setId(data[rowIndex].partItemId);
                setMag(data[rowIndex].magnifications || "100,147,1");
                if (shapeDA !== "null") setShDaBBSEdit(shapeDA || "[]");
                else setShDaBBSEdit("[]");
            } catch (error) {
                toast.error(`1.${error}`);
            }
        }
    };

    const applyFilters = () => {
        let filteredData = partItems;

        let weight = 0;

        if (orderItemsFilter) {
            filteredData = filteredData.filter(
                (item) => item.orderItemName === orderItemsFilter
            );
        }

        filteredData.forEach((el) => {
            weight += el.productionWeight;
        });

        setData(filteredData);
        setFilteredWeight(Math.round(weight * 1000) / 1000);
        incrementLastChar(filteredData);
    };

    const handleAddButtonClick = () => {
        if (bbsShapeId !== null && bbsShapeId !== undefined) {
            setIsAdding(!isAdding);
            // setDeselector(deselector + 1);

            if (data.length !== 0) {
                setEditData({ ...editData, gradeName: data[0].gradeName });
            }

            // setShapeId(shapeId);
            // setShDaBBS(shapeDA);
            // setBbsShapeId(ogId);
        } else
            toast.error("No shape is selected. Select a shape and try again");
        setShapeIdInputValue("");
    };

    const handleDeleteButtonClick = () => {
        if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
            if (multiDelete === false) {
                const id = [data[selectedRowIndex].partItemId];
                dispatch(deletePartItems({ data: {
                    "partItemIds": id
                } })).then(
                    dispatch(fetchPartItems(`?orderId=${orderId}`))
                );
            } else {
                let ids = selectedRowIndex.map((rowId) => data[rowId].partItemId);

                dispatch(deletePartItems({
                    data: {
                        "partItemIds": ids
                    }
                })).then(()=> {
                    setMultiDelete(false)
                })
            }
        } else
            toast.error("No rows selected, Please select a row and try again");
        setSelectedRowIndex(null);
    };

    const handleEditButtonClick = () => {
        setDeselector(deselector + 1);

        const tempJson = {
            serviceCenterId: 1,
            id: 1, //
            diameter: 6,
            gradeName: "",
            orderItemId: "", //
            diffFactId: 2,
            routeId: 1,
            gradeId: 1,
            noOfPieces: "",
            totalNoOfPieces: "", //
            bundleId: 1,
            bmRow: 1, //posAfterBmrow
            lengthDeduction: 10,
            scale: 1,
            inventoryLength: "",
            productionLength: "",
            inventoryWeight: "",
            productionWeight: "",
            tagDescription: "",
            tagId: 1,
            bundleQuantity: "",
            pinDia: "",
            barMarkId: "",
            plId: 1,
            image: "",
            shapeId: 1
        };

        if (multiDelete === false) {
            if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
                let tempEdit = {};

                Object.keys(tempJson).map((item) => {
                    if (data[selectedRowIndex][item] !== undefined) {
                        tempEdit = {
                            ...tempEdit,
                            [item]: data[selectedRowIndex][item],
                        };
                    }
                });

                setEditData(tempEdit);
                // if (data[selectedRowIndex].magnifications !== null) {
                setMag(data[selectedRowIndex].magnifications);
                // } else setMag("100,147,1");

                if (shDaBBSEdit === "") {
                    toast.error("The shape used in the Tag is Unavailable");
                } else {
                    try {
                        let shapeInfo = JSON.parse(shDaBBSEdit);
                        setIsEditing(true);
                    } catch (error) {
                        toast.error(`2.${error}`);
                    }
                }
            } else
                toast.error(
                    "No rows selected, Please select a row and try again"
                );
            setSelectedRowIndex(null);
        } else toast.error("Uncheck Multiple select and try again");
    };

    const handleShapeInput = (shapeId, condition = false) => {
        let foundShape = false;
        let ogId = null;
        let searchId = shapeId;

        let shapeDA = [];

        setShapeIdInputValue(shapeId);

        dispatch(searchShapes(`?shapeId=${searchId}`)).then((res) => {
            if (res.payload) {
                if (res.payload.shapes.length === 0) {
                    toast.error("No shape found");
                    return;
                } else {
                    const shape = res.payload.shapes[0];
                    foundShape = true;
                    ogId = shape.id;
                    setMag(shape.magnifications);
                    shapeDA = shape.imageProperties;
                }

                shapes.map((el) => {
                    if (el.shapeId === shapeId) {
                        // setId(el.id);
                        ogId = el.id;
                        // setMag(el.magnifications);
                        foundShape = true;
                    }
                    if (el.shapeId === shapeId) {
                        shapeDA = el.imageProperties;
                    }
                });

                if (foundShape === false) {
                    toast.error("No shape matches the Id");
                    setShapeId(null);
                    setShDaBBS(null);
                    setBbsShapeId(null);
                    return;
                }

                setShDaBBS(shapeDA);
                setBbsShapeId(ogId);

                if (condition) {
                    if (ogId !== null && ogId !== undefined) {
                        setIsAdding(true);
                        // setDeselector(deselector + 1);

                        if (data.length !== 0) {
                            setEditData({
                                ...editData,
                                gradeName: data[0].gradeName,
                            });
                        }

                        // setShapeId(shapeId);
                        // setShDaBBS(shapeDA);
                        // setBbsShapeId(ogId);
                    }
                    // setShapeIdInputValue("");
                    return;
                }

                setShapeId(shapeId);
            }
        });
    };

    useEffect(() => {
        dispatch(fetchPartItems(`?orderId=${orderId}`));
        dispatch(fetchOrder());
        dispatch(fetchGrades());
        dispatch(fetchOrderItems(orderId));
        dispatch(fetchCustomerParties());
        dispatch(fetchServiceCenter());
    }, [dispatch]);

    useEffect(() => {
        applyFilters();

        orderItems.forEach((item) => {
            if (item.orderItemName === orderItemsFilter) {
                setOrderItemId(item.orderItemId);
            }
        });
    }, [orderItemsFilter]);

    const onClose = () => {
        setIsAdding(false);
        setIsEditing(false);
        setShapeId(null);
        setShDaBBS(null);
        setBbsShapeId(null);
        // incrementLastChar(
        //     incrementLastChar(
        //         partItems
        //             .filter((item) => `${item.orderId}` === orderId)
        //             .filter((item) =>
        //                 orderItemIdValues.includes(item.orderItemId)
        //             )
        //     )
        // );
    };

    let incrementedChar = "";

    const incrementLastChar = (data, incrementedLastChar) => {
        try {
            let lastData = data[data.length - 1];
            let str = lastData.barMarkId;
            let lastChar = str.slice(-1);
            let restOfString = str.slice(0, -1);

            const characterCode = lastChar.charCodeAt(0);

            if (
                (48 <= characterCode && characterCode <= 57) ||
                (65 <= characterCode && characterCode <= 90) ||
                (97 <= characterCode && characterCode <= 122)
            ) {
                if (lastChar === "9") {
                    // Incrementing from '9' to '0'
                    incrementedChar = "0";
                    // If there's a carry, we need to increment the next character
                    if (restOfString.length > 0) {
                        // Recursively call to handle carry
                        return incrementLastChar(
                            [{ barMarkId: restOfString }],
                            incrementedChar
                        );
                    } else {
                        return incrementLastChar(
                            [{ barMarkId: '0' }],
                            incrementedChar
                        );
                    }
                } else if (lastChar === "z") {
                    // Incrementing from '9' to '0'
                    incrementedChar = "a";
                    // If there's a carry, we need to increment the next character
                    if (restOfString.length > 0) {
                        // Recursively call to handle carry
                        return incrementLastChar(
                            [{ barMarkId: restOfString }],
                            incrementedChar
                        );
                    }
                } else if (lastChar === "Z") {
                    // Incrementing from 'Z' to 'A'
                    incrementedChar = "A";
                    // If there's a carry, we need to increment the next character
                    if (restOfString.length > 0) {
                        return incrementLastChar(
                            [{ barMarkId: restOfString }],
                            incrementedChar
                        );
                    }
                } else {
                    // Incrementing any other character

                    incrementedChar =
                        String.fromCharCode(characterCode + 1) +
                        incrementedChar;
                }
            } else if (incrementedLastChar === "0") {
                restOfString =
                    str +
                    String.fromCharCode(incrementedLastChar.charCodeAt(0) + 1);
                incrementedChar = incrementedLastChar;
            } else {
                restOfString = str + incrementedLastChar;
                incrementedChar = incrementedLastChar;
            }

            setEditData({
                ...editData,
                barMarkId: restOfString + incrementedChar,
                tagDescription: lastData.tagDescription,
                bundleQuantity: lastData.bundleQuantity,
            });

            incrementedChar = "";
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const selectGrade =
            data[data.length - 2]?.gradeName || selectedPart?.gradeName || 0;

        setDiameterValues(
            [
                ...new Set(
                    materials
                        .filter((item) =>
                            selectGrade ? item.gradeName === selectGrade : true
                        )
                        .map((item) => item.diameter)
                ),
            ].sort((a, b) => a - b)
        );

        setGradeValues([...new Set(materials.map((item) => item.gradeName))]);

        setPartiesId(
            orderData.map((el, index) => {
                if (index === 0) {
                    return el.customerPartiesId;
                }
            })[0]
        );
    }, [partItems, materials]);

    useEffect(() => {
        if (partItems.length !== 0) getBundlles();
        applyFilters();
    }, [partItems]);

    const handleQuickAddSave = () => {
        let error = false;
        // console.log(selectedPart);

        // if (
        //     quickAddData.bundleQuantity >
        //     selectedPart.noOfPieces * selectedNoOfSets
        // ) {
        //     toast.error(
        //         "Pcs/bundle cannot be greater than total no. of pieces"
        //     );
        //     return;
        // }

        data.forEach((item) => {
            if (
                item.barMarkId?.toLowerCase() ===
                quickAddData.barMarkId?.toLowerCase()
            ) {
                error = true;
                return;
            }
        });

        if (error) {
            toast.error("barmarkId already exists");
            return;
        }

        let gradeId;
        materials.map((item) => {
            if (
                item.diameter * 1 === selectedPart.diameter * 1 &&
                `${item.gradeName}` === `${selectedPart.gradeName}`
            )
                gradeId = item.gradeId;
        });

        const type = {
            ...quickAddData,
            gradeId,
        };

        dispatch(postPartItems(type)).then((res) => {
            if (res.payload !== undefined) {
                // dispatch(fetchPartItems(`?orderId=${orderId}`));
                // setQuickAddData({});
                // setShapeId("");
                // setShapeIdInputValue("");
                const selectElement = document.getElementById("bbsEntryBmId");
                selectElement.focus();
                setDataUpdatedIndex(data.length-1)
            }
        });
    };

    //PDF PRINT START

    const getCurrentDateTime = () => {
        const date = new Date();

        const formatData = (input) => (input < 10 ? `0${input}` : input);

        const day = formatData(date.getDate());
        const month = formatData(date.getMonth() + 1); // Months are zero-indexed
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const handleUpload = async (type) => {
        if (generatingPdf) {
            return;
        }

        if (partItems.length === 0) {
            toast.error("No tags in the bbs.");
            return;
        }

        if (bundleData.length ===0) {
            toast.error("No bundles in this order");
            return
        }

        const orderParties = parties.filter(
            (item) => item.customerPartiesId * 1 === partiesId * 1
        )[0];

        if (!orderParties) {
            toast.error("Parties data not found.");
            return;
        }

        const orderInfo = orderData[0];

        setIsGeneratingPdf(true);

        if (type === "tag") {
            // getBundlles();
        }

        const diaValues = [
            ...new Set(partItems.map((item) => item.diameter)),
        ].sort((a, b) => a - b);

        const toastId = toast.loading("Generating Pdf...", {
            position: "top-center",
        });

        let partItem = partItems.map((item) => item);

        partItem.sort((a, b) => a.orderItemId - b.orderItemId);

        const blob = await pdf(
            <Document>
                <MyDocument
                    partItem={partItem}
                    bundleData={bundleData}
                    orderInfo={orderInfo}
                    type={type}
                    parties={
                        parties.filter(
                            (item) =>
                                item.customerPartiesId * 1 === partiesId * 1
                        )[0]
                    }
                    diaValues={diaValues}
                    serviceCenterLogo={serviceCenterLogo}
                    lengthDeduction={lengthDeduction}
                />
                {"  "}
            </Document>
        ).toBlob();

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
            const base64data = reader.result; // This is the Base64 string

            // Prepare data for upload
            const data = {
                filePath: base64data,
                fileName: `${orderInfo.combId}-${type}-${getCurrentDateTime()}`,
                fileType: type === "bbs tabular" ? "Bbs" : "Tag",
                orderId: orderId * 1,
                orderType: "readyBuild",
            };

            try {
                dispatch(uploadGenPdf(data)).then((res) => {
                    if (res.payload) {
                        toast.update(toastId, {
                            render: "Generated successfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 3000, // Close the toast after 3 seconds
                        });
                        setIsGeneratingPdf(false);
                    } else {
                        toast.update(toastId, {
                            render: "Failed to generate Pdf",
                            type: "error",
                            isLoading: false,
                            autoClose: 3000, // Close the toast after 3 seconds
                        });
                        setIsGeneratingPdf(false);
                    }
                });
            } catch (error) {
                toast.update(toastId, {
                    render: "Failed to generate Pdf",
                    type: "error",
                    isLoading: false,
                    autoClose: 3000, // Close the toast after 3 seconds
                });
                setIsGeneratingPdf(false);
            }
        };
    };

    const downloadBase64File = (base64Data, filename) => {
        const link = document.createElement("a");

        link.href = base64Data;

        link.download = filename;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    const pdfPrint = (type, action) => {
        const fileType = type === "bbs tabular" ? "Bbs" : "Tag";

        if (action === "generate") {
            if (!generatePdfOpen) {
                // setPdfType(type);
                // setGeneratePdfOpen(true);
                handleUpload(type);
            }
        } else {
            if (generatingPdf) {
                toast.warning("Pdf is getting generated please wait");
                return;
            }
            dispatch(
                downloadGenPdf(
                    `?orderId=${orderId}&fileType=${fileType}&orderType=readyBuild`
                )
            ).then((res) => {
                if (res.payload) {
                    if (res.payload.json[0]) {
                        downloadBase64File(
                            res.payload.json[0].filePath,
                            res.payload.json[0].fileName
                        );
                    }
                }
            });
        }
    };

    const changeElementValue = (elementName = "", passedGrade) => {
        const foundPartItem = partItems.find(
            (item) => item.orderItemName === elementName
        );

        let gradeName;

        if (foundPartItem) {
            gradeName = foundPartItem.gradeName;
        }

        if (passedGrade) {
            gradeName = passedGrade;
        }
        if (gradeName) {
            setDiameterValues(
                [
                    ...new Set(
                        materials
                            .filter((item) =>
                                gradeName ? item.gradeName === gradeName : true
                            )
                            .map((item) => item.diameter)
                    ),
                ].sort((a, b) => a - b)
            );
        } else {
            gradeName = "IS:1786 - FE550D";
            // materials[0].gradeName;
        }

        uSelectedPart({
            ...selectedPart,
            diameter: "",
            noOfPieces: "",
            gradeName,
        });
        setShapeIdInputValue("");
        setQuickAddData({});
    };

    const setElementValueAfterEdit = (value) => {
        // FOR CHANGING THE ELEMENT FILTER WHEN BMROW CHANGES
        // When bmRow is changed order of tags change but the change is
        // element wise, if the element filter is all this changes the
        // filter to that element so that the change is visible.

        const foundItem = orderItems.find((item) => item.orderItemId === value);

        setSelectedNoOfSets(foundItem.noOfSets);
        setOrderItemsFilter(foundItem.orderItemName);
        changeElementValue(foundItem.orderItemName);
    };
    //PDF PRINT END

    // console.log(quickAddData);

    const triggerShapePopup = (value) => {
        if (!editData.barMarkId) {
            toast.error("Enter Barmark ID");
            return 0;
        }
        if (!selectedPart.diameter) {
            toast.error("Enter diameter");
            return 0;
        }
        if (!selectedPart.noOfPieces) {
            toast.error("Enter No. of pieces");
            return 0;
        }

        if (orderItemsFilter) {
            handleShapeInput(value, true);
            setSearchKey(value);
            setShapeIdInputValue(value);
        } else {
            toast.error("Please select an elemenet");
            return 0;
        }
    };

    const updownArrowDisabler = (e) => {
        if ([38, 40].includes(e.keyCode)) {
            // Prevent the default behavior of the arrow key
            e.preventDefault();
        }
    }

    return (
        <div className="bbs" style={{ padding: "0" }}>
            <div
                className="head"
                style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    marginBottom: "0",
                    gap: "2rem",
                    alignItems: "center",
                    position: "relative",
                }}
            >
                <BackIcon
                    onClick={() =>{ navigate(`/${"order details"}`);dispatch(resetOrderItems()); dispatch(resetPartItems())}}
                    style={{ position: "absolute", left: "0" }}
                    title="Close"
                />
                <b style={{ fontSize: "1.4rem" }}>
                    BBS Details for Order Id : {combId}
                </b>

                <div
                    className="top-button-container"
                    style={{ alignItems: "center" }}
                >
                    <input
                        placeholder="Enter Shape Id"
                        className={`ShapeIdInput ${shapeIdInput === true ? "active" : ""
                            }`}
                        type="text"
                        value={shapeIdInputValue}
                        onChange={(e) => {
                            setSearchKey(e.target.value);
                            setShapeIdInputValue(e.target.value);
                        }}
                        // onBlur={() => handleAddButtonClick()}
                        // autoFocus
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                if (shapeId === e.target.value) {
                                    // handleAddButtonClick();
                                } else if (searchKey !== "") {
                                    handleShapeInput(searchKey);
                                }
                            }
                        }}
                    />

                    <img
                        src={search}
                        alt="search"
                        style={{ marginRight: "10px" }}
                        // autoFocus
                        onClick={() => {
                            if (searchKey !== "") {
                                handleShapeInput(searchKey);
                            }
                        }}
                    />
                    <TopButtonContainer
                        add={handleAddButtonClick}
                        edit={handleEditButtonClick}
                        del={handleDeleteButtonClick}
                        access={permission}
                        accessOverride={[0, 1, 1]}
                    />
                </div>
                <div
                    className="bbsSetting"
                    style={{ display: "flex", color: "#000" }}
                >
                    <div style={{ padding: "0 10px" }}>
                        <label htmlFor="LnDed">Length Deduction:</label>
                        <input
                            type="radio"
                            checked={lengthDeduction}
                            name="LnDed"
                            id="Yes"
                            onClick={() => setLengthDeduction(true)}
                        />
                        <label htmlFor="Yes">Yes</label>
                        <input
                            type="radio"
                            checked={!lengthDeduction}
                            name="LnDed"
                            id="No"
                            onClick={() => setLengthDeduction(false)}
                        />
                        <label htmlFor="No">No</label>
                    </div>

                    {/* <div>
              <label htmlFor="Scale">Scale:</label>
              <input type='radio' name='Scale' id='Yes' />
              <label htmlFor="Yes">Yes</label>
              <input type="radio" name='Scale' id='No' />
              <label htmlFor="No">No</label>
              </div> */}

                    <div className="printDrop">
                        <Button label="Print" className="blue" />
                        <div className="dropdown-1">
                            <ul style={{ margin: "0" }}>
                                <li
                                    className="printOption"
                                    style={{ padding: "5px" }}
                                >
                                    BBS Tabular
                                    <ul>
                                        <li
                                            onClick={() =>
                                                pdfPrint(
                                                    "bbs tabular",
                                                    "generate"
                                                )
                                            }
                                        >
                                            Generate
                                        </li>
                                        <li
                                            onClick={() =>
                                                pdfPrint(
                                                    "bbs tabular",
                                                    "download"
                                                )
                                            }
                                        >
                                            Download
                                        </li>
                                    </ul>
                                </li>
                                <li
                                    className="printOption"
                                    style={{ padding: "5px" }}
                                >
                                    Tag List
                                    <ul>
                                        <li
                                            onClick={() =>
                                                pdfPrint("tag", "generate")
                                            }
                                        >
                                            Generate
                                        </li>
                                        <li
                                            onClick={() =>
                                                pdfPrint("tag", "download")
                                            }
                                        >
                                            Download
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="infoShapeContainer">
                <div className="shapeBbsContainer">
                    <Shapes
                        bbs={true}
                        setShDaBBS={setShDaBBS}
                        orderId={orderId}
                        setShapeId={setShapeId}
                        shapeId={shapeId}
                        partiesId={partiesId}
                        setBbsShapeId={setBbsShapeId}
                        setMg={setMag}
                        triggerShapePopup={triggerShapePopup}
                    />
                    <div className="quickEnter">
                        <div className="inputCombo">
                            <label htmlFor="bbsEntryBmId">Bar Mark Id </label>
                            <input
                                id="bbsEntryBmId"
                                type="text"
                                value={editData.barMarkId}
                                autoFocus
                                onChange={(e) => {
                                    setEditData({
                                        ...editData,
                                        barMarkId: e.target.value,
                                    });
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Tab") {
                                        let error = false;
                                        if (!orderItemsFilter) {
                                            toast.error(
                                                "Please select an elemenet"
                                            );
                                            return;
                                        }

                                        data.forEach((item) => {
                                            if (
                                                item.barMarkId?.toLowerCase() ===
                                                e.target.value?.toLowerCase()
                                            ) {
                                                error = true;
                                                return;
                                            }
                                        });

                                        if (error) {
                                            toast.error(
                                                "barmarkId already exists"
                                            );
                                            return;
                                        }
                                    }
                                }}
                                style={{ width: "5.5rem" }}
                            />
                        </div>

                        <div className="inputCombo">
                            <label htmlFor="bbsEntryDia">Diameter </label>
                            <select
                                id="bbsEntryDia"
                                value={selectedPart.diameter}
                                onChange={(e) =>
                                    uSelectedPart({
                                        ...selectedPart,
                                        diameter: e.target.value,
                                    })
                                }
                            >
                                <option hidden value=""></option>
                                {diameterValues.map((value, index) => (
                                    <option key={index} value={value}>
                                        {" "}
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="inputCombo">
                            <label htmlFor="bbsEntryNoOfPieces">
                                No of Pcs{" "}
                            </label>
                            <input
                                id="bbsEntryNoOfPieces"
                                type="number"
                                min={0}
                                max={10000}
                                value={selectedPart.noOfPieces || ""}
                                style={{ width: "4rem" }}
                                onKeyDown={(e) => updownArrowDisabler(e)}
                                onChange={(e) => {
                                    if (orderItemsFilter) {
                                        if (e.target.value < 100001) {
                                            if (e.target.value >= 0) {
                                                if (data.length === 0) {
                                                    uSelectedPart({
                                                        ...selectedPart,
                                                        noOfPieces:
                                                            e.target.value,
                                                        bundleQuantity:
                                                            e.target.value *
                                                            selectedNoOfSets,
                                                    });
                                                } else {
                                                    uSelectedPart({
                                                        ...selectedPart,
                                                        noOfPieces:
                                                            e.target.value,
                                                    });
                                                }
                                            } else {
                                                e.target.value = "";
                                            }
                                        }
                                    } else {
                                        toast.error(
                                            "Please select an elemenet"
                                        );
                                        e.target.value = "";
                                    }
                                }}
                            />
                        </div>

                        <div className="inputCombo">
                            <label htmlFor="bbsEntryShapeId">Shape Id </label>
                            <input
                                id="bbsEntryShapeId"
                                type="text"
                                style={{ width: "70px" }}
                                value={shapeId || shapeIdInputValue}
                                onChange={(e) => {
                                    setSearchKey(e.target.value);
                                    setShapeIdInputValue(e.target.value);
                                    setShapeId(e.target.value);
                                    // setQuickAddData({
                                    //     ...quickAddData,
                                    //     shapeId: e.target.value
                                    // })
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" || e.key === "Tab") {
                                        triggerShapePopup(e.target.value);
                                    }
                                }}
                            />
                        </div>
                        <div className="inputCombo">
                            <label htmlFor="bbsEntryNoOfPieces">
                                Total no. of Pcs{" "}
                            </label>
                            <input
                                id="bbsEntryNoOfPieces"
                                type="number"
                                readOnly
                                value={
                                    selectedPart.noOfPieces *
                                    selectedNoOfSets || ""
                                }
                                style={{ width: "4rem" }}
                            />
                        </div>
                        <div className="inputCombo">
                            <label htmlFor="bbsEntryPcsPerBundle">
                                Pcs/bundle{" "}
                            </label>
                            <input
                                id="bbsEntryPcsPerBundle"
                                type="number"
                                value={
                                    selectedPart.bundleQuantity === undefined
                                        ? editData.bundleQuantity
                                        : selectedPart.bundleQuantity
                                }
                                onKeyDown={(e) => updownArrowDisabler(e)}
                                onChange={(e) => {
                                    uSelectedPart({
                                        ...selectedPart,
                                        bundleQuantity: e.target.value,
                                    });
                                }}
                                onBlur={(e) => {
                                    setQuickAddData({
                                        ...quickAddData,
                                        bundleQuantity: e.target.value,
                                    });
                                }}
                                style={{ width: "4rem" }}
                            />
                        </div>

                        <div className="inputCombo">
                            <label htmlFor="bbsEntryProductionLength">
                                Production length{" "}
                            </label>
                            <input
                                id="bbsEntryProductionLength"
                                type="number"
                                onChange={(e) => {
                                    setQuickAddData({
                                        ...quickAddData,
                                        productionLength: e.target.value * 1,
                                    });
                                }}

                                value={quickAddData.productionLength || ""}
                                style={{ width: "4rem" }}
                                onKeyDown={(e) => {
                                    updownArrowDisabler(e)
                                    // if (
                                    //     e.key === "Enter" ||
                                    //     (e.key === "Tab" && !e.shiftKey)
                                    // ) {
                                    //     handleQuickAddSave();
                                    // }
                                }}
                            />
                        </div>
                        <div className="inputCombo">
                            <label htmlFor="bbsEntryTagDescription">
                                Tag description{" "}
                            </label>
                            <input
                                id="bbsEntryTagDescription"
                                type="text"
                                value={
                                    selectedPart.tagDescription ||
                                    editData.tagDescription
                                }
                                onChange={(e) => {
                                    uSelectedPart({
                                        ...selectedPart,
                                        tagDescription: e.target.value,
                                    });
                                    setQuickAddData({
                                        ...quickAddData,
                                        tagDescription: e.target.value,
                                    });
                                }}
                                onFocus={(e) => {
                                    uSelectedPart({
                                        ...selectedPart,
                                        tagDescription: e.target.value,
                                    });
                                    setQuickAddData({
                                        ...quickAddData,
                                        tagDescription: e.target.value,
                                    });
                                }}
                                onKeyDown={(e) => {
                                    updownArrowDisabler(e)
                                    if (
                                        e.key === "Enter" ||
                                        (e.key === "Tab" && !e.shiftKey)
                                    ) {
                                        handleQuickAddSave();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="section1">
                    <div className="shadow-box-1">
                        <div className="box-item-1">
                            <label htmlFor="customerName">Customer Name</label>
                            {orderData.map((order, index) =>
                                index === 0 ? (
                                    <input
                                        type="text"
                                        readOnly
                                        value={order.soldToParty}
                                    />
                                ) : (
                                    ""
                                )
                            )}
                        </div>
                        <div className="box-item-1">
                            <label htmlFor="customerName">
                                Delivery Address
                            </label>
                            {orderData.map((order, index) =>
                                index === 0 ? (
                                    <textarea
                                        id="DeliveryAddress"
                                        type="text"
                                        readOnly
                                        value={order.address}
                                    />
                                ) : (
                                    ""
                                )
                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "50%",
                            }}
                        >
                            <div
                                className="content-box"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div className="box-item">
                                    <label htmlFor="totalTags">
                                        Total Tags
                                    </label>
                                    {orderData.map((order, index) =>
                                        index === 0 ? (
                                            <select
                                                id="totalTags"
                                                type="text"
                                                readOnly
                                                value={partItems.length}
                                                style={{
                                                    appearance: "none",
                                                    padding: "0.125rem 0.25rem",
                                                }}
                                            >
                                                <option
                                                    value={partItems.length}
                                                    hidden
                                                >
                                                    {partItems.length}
                                                </option>
                                            </select>
                                        ) : (
                                            ""
                                        )
                                    )}
                                </div>
                                <div className="box-item">
                                    <label htmlFor="weight">Weight (T)</label>

                                    <select
                                        id="weight"
                                        type="text"
                                        readOnly
                                        value={filteredWeight}
                                        style={{
                                            appearance: "none",
                                            padding: "0.125rem 0.25rem",
                                        }}
                                    >
                                        <option value={filteredWeight} hidden>
                                            {filteredWeight}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div className="box-item">
                                    <label htmlFor="OrderDate">
                                        Order Date
                                    </label>
                                    {orderData.map((order, index) =>
                                        index === 0 ? (
                                            <input
                                                id="Order Date"
                                                type="date"
                                                readOnly
                                                value={dateChanger(
                                                    order.createdTs
                                                )}
                                            />
                                        ) : (
                                            ""
                                        )
                                    )}
                                </div>
                                <div className="box-item">
                                    <label htmlFor="DeliveryDate">
                                        Delivery Date
                                    </label>
                                    {orderData.map((order, index) =>
                                        index === 0 ? (
                                            <input
                                                id="DeliveryDate"
                                                type="date"
                                                readOnly
                                                value={order.deliveryDate}
                                            />
                                        ) : (
                                            ""
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="shadow-box-2">
                        <div className="box-item-1">
                            <label htmlFor="diameter">Diameter</label>
                            <select
                                id="diameter"
                                value={selectedPart.diameter}
                                onChange={(e) =>
                                    uSelectedPart({
                                        ...selectedPart,
                                        diameter: e.target.value,
                                    })
                                }
                            >
                                <option value="">select</option>
                                {diameterValues.map((value, index) => (
                                    <option key={index} value={value}>
                                        {" "}
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="box-item-1">
                            <label htmlFor="PlId">Pl Id</label>
                            <select
                                value={selectedPart.plId}
                                onChange={(e) =>
                                    uSelectedPart({
                                        ...selectedPart,
                                        plId: e.target.value,
                                    })
                                }
                            >
                                <option value="1">1</option>
                            </select>
                        </div>
                        <div className="box-item-1">
                            <label htmlFor="barType">Bar Type</label>
                            <select
                                value={selectedPart.barType}
                                onChange={(e) =>
                                    uSelectedPart({
                                        ...selectedPart,
                                        barType: e.target.value,
                                    })
                                }
                            >
                                <option value="T">T</option>
                            </select>
                        </div>
                        <div className="box-item-1">
                            <label htmlFor="el">Element</label>
                            <select
                                value={orderItemsFilter}
                                onChange={(e) => {
                                    if (e.target.value === "1") {
                                        setElementAdding(true);
                                    } else if (e.target.value === "") {
                                        setSelectedNoOfSets("");
                                        setOrderItemsFilter(e.target.value);
                                        changeElementValue(e.target.value);
                                    } else {
                                        const foundItem = orderItems.find(
                                            (item) =>
                                                item.orderItemName ===
                                                e.target.value
                                        );

                                        setSelectedNoOfSets(foundItem.noOfSets);
                                        setOrderItemsFilter(e.target.value);
                                        changeElementValue(e.target.value);
                                    }
                                }}
                            >
                                <option value="">All</option>
                                {orderItemValues.map((value, index) => (
                                    <option key={index} value={value}>
                                        {" "}
                                        {index + 1}.{value}
                                    </option>
                                ))}
                                <option value={"1"}>(New)</option>
                            </select>
                        </div>
                        <div className="box-item-1">
                            <label htmlFor="grade">Grade</label>
                            <select
                                id="grade"
                                value={selectedPart.gradeName}
                                onChange={(e) => {
                                    if (data.length === 0) {
                                        changeElementValue(
                                            orderItemsFilter,
                                            e.target.value
                                        );
                                    } else
                                        uSelectedPart({
                                            ...selectedPart,
                                            gradeName: e.target.value,
                                        });
                                }}
                            >
                                {orderItemsFilter && data.length !== 0 ? (
                                    <option
                                        key={selectedPart.gradeName}
                                        value={selectedPart.gradeName}
                                    >
                                        {" "}
                                        {selectedPart.gradeName}
                                    </option>
                                ) : (
                                    gradeValues.map((value, index) => (
                                        <option key={index} value={value}>
                                            {" "}
                                            {value}
                                        </option>
                                    ))
                                )}
                            </select>
                        </div>
                    </div>

                    <div className="bottombbs">
                        <div className="bottomMultiDel">
                            <input type="checkbox" id="multiDel"
                                checked={multiDelete === true}
                                onClick={(e) => setMultiDelete(!multiDelete)} />
                            <label htmlFor="multiDel">Multiple delete</label>
                        </div>

                    </div>
                </div>
            </div>

            <div
                className="table-container"
                style={{ maxHeight: "750px", overflowY: "hide" }}
            >
                {loading !== "pending" ? (
                    <BBSAGTable
                        columns={columns}
                        data={data}
                        onSelectRow={handleRowSelect}
                        selectedRow={selectedRowIndex}
                        bbs={true}
                        rowSelection={`${multiDelete === false ? "single" : "multiple"
                            }`}
                        height={370}
                        deselector={deselector}
                        dataUpdatedIndex={dataUpdatedIndex}
                    />
                ) : (
                    <div
                        style={{
                            height: "300px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div
                            className="circle-loader3"
                            style={{
                                position: "relative",
                                left: "0",
                                top: "0",
                            }}
                        ></div>
                    </div>
                )}
            </div>

            {isAdding === true ? (
                <BbsShapeSetting
                    BBSDetailsProps={BBSDetailsProps}
                    tempDis={isAdding}
                    orderId={orderId}
                    uTempDis={onClose}
                    gradeValues={gradeValues}
                    orderItemValues={orderItemValues}
                    diameterValues={diameterValues}
                    shapeD={JSON.parse(shDaBBS)}
                    ordItems={orderItems}
                    bbsShapeId={bbsShapeId}
                    materials={materials}
                    mag={mag}
                    editData={editData}
                    lastData={data[data.length - 1] || []}
                    actualShape={JSON.parse(shDaBBS)}
                    setQuickAddData={setQuickAddData}
                    incrementLastChar={incrementLastChar}
                    filteredData={data}
                    changeElementValue={changeElementValue}
                />
            ) : (
                ""
            )}
            {isEditing === true ? (
                <BbsShapeSetting
                    BBSDetailsProps={BBSDetailsProps}
                    tempDis={isEditing}
                    orderId={orderId}
                    uTempDis={onClose}
                    gradeValues={gradeValues}
                    orderItemValues={orderItemValues}
                    diameterValues={diameterValues}
                    shapeD={JSON.parse(shDaBBSEdit)}
                    ordItems={orderItems}
                    bbsShapeId={bbsShapeIdEdit}
                    materials={materials}
                    editData={editData}
                    id={id}
                    compAction={"update"}
                    mag={mag}
                    actualShape={JSON.parse(actualShape)}
                    setActualShape={setActualShape}
                    setBbsShapeIdEdit={setBbsShapeIdEdit}
                    setMag={setMag}
                    setShDaBBSEdit={setShDaBBSEdit}
                    partItems={partItems}
                    setElementValueAfterEdit={setElementValueAfterEdit}
                    incrementLastChar={incrementLastChar}
                    filteredData={data}
                    changeElementValue={changeElementValue}
                />
            ) : (
                ""
            )}

            <AddElements
                isAdding={elementAdding}
                setIsAdding={setElementAdding}
                orderId={orderId || ""}
                bbs={true}
            // setOrderItemValues={setOrderItemValues}
            />
        </div>
    );
};

export default BBS;
//End of File
