import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/styles/NewPlan.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../utils/Button";
import { useNavigate } from "react-router-dom";
import {
  editScrapDefinition,
  fetchPlan,
  fetchPlanStocks,
  fetchScrapDefinition,
  postPlan,
} from "../../redux-tookit/slices/planSlice";
import AGTable from "../../components/AGTable";
import Loader from "../../components/Loader/Loader";
import { setNavOptions } from "../../redux-tookit/slices/navbarSlice";
import { fetchUserAccess } from "../../redux-tookit/slices/userAccessSlice";
import CustomBundleTable from "./Dispatch/CustomBundleTable";

const NewPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState("fulfilled");
  const orderItemsColumns = useSelector((state) => state.plan.columns);
  const orderItemsData = useSelector((state) => state.plan.labeledData);
  const scrapDefColumns = useSelector((state) => state.plan.scrapDefColumns);
  const scrapDefData = useSelector((state) => state.plan.scrapDefData);
  // .filter(item => item.totalTags!==0);
  const [orderItemStocks, setOrderItemStocks] = useState([]);

  const orderitemStockColumns = {
    gradeName: { label: "Grade" },
    diameter: { label: "Diameter" },
    length: { label: "Length" },
    totalPieces: { label: "No. of pieces" },
    // "gradeId": { label: "Grade" },
    weight: { label: "Weight" },
    stockType: { label: "Stock type" },
  };

  const [inventoryIds, setInventoryIds] = useState([]);

  const [selectedRowIndex2, setSelectedRowIndex2] = useState([]);

  // console.log(scrapDefColumns, scrapDefData);

  const [selectedRowIndex, setSelectedRowIndex] = useState([]);

  const [scrapDefVisible, setScrapDefVisible] = useState(false);

  const [permission, setPermission] = useState({});

  const [inventoryData, setInventoryData] = useState([]);

  const [inventoryShow, setInventoryShow] = useState(false);

  const [inventoryType, setInventoryType] = useState("self");

  const inventoryColumns = {
    grade: { label: "Grade" },
    diameter: { label: "Diameter" },
    requiredPieces: { label: "Pieces Required" },
    requiredLength: { label: "Length Required" },
  };

  //   "requiredInventory": [
  //     {
  //         "partItemId": 5335,
  //         "grade": "IS1786 FE550SD",
  //         "diameter": 10,
  //         "requiredPieces": 200,
  //         "requiredLength": 387
  //     }
  // ]

  useEffect(() => {
    dispatch(fetchUserAccess()).then((res) => {
      if (res.payload) {
        if (res.payload.navbarNames.Transaction["New Plan"][0]) {
          setPermission(res.payload.navbarNames.Transaction["New Plan"][0]);
        }
      }
    });
  }, []);

  const handleFetchStocks = (rowIndex) => {
    if (rowIndex.length !== 0) {
      let orderItemIdValues = [];

      rowIndex.map((rowIndex) => {
        orderItemIdValues = [
          ...orderItemIdValues,
          orderItemsData[rowIndex].orderItemId,
        ];
      });

      const newRow = {
        orderItemIds: orderItemIdValues,
      };

      dispatch(fetchPlanStocks(newRow)).then((res) => {
        // console.log(res.payload.jsonData.serviceCenterInv);
        if (res.payload.jsonData) {
          setOrderItemStocks(res.payload.jsonData.serviceCenterInv);
        }
      });
    }
  };

  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex2([])
    setInventoryIds([])
    if (rowIndex !== undefined) {
      setSelectedRowIndex(rowIndex);
      if (inventoryType === "userdefined") {
        handleFetchStocks(rowIndex);
      }
    } else {
      setSelectedRowIndex(null);
      setOrderItemStocks([])
    }
  };

  const handleInventoryStockSelect = (rowIndex) => {
    if (rowIndex?.length !== 0) {
      let selectInventoryIds = [];

      rowIndex.map((index) => {
        selectInventoryIds = [
          ...selectInventoryIds,
          orderItemStocks[index]?.inventoryId,
        ];
      });

      setInventoryIds(selectInventoryIds);
    }
  };

  const handleRowSelect2 = (rowIndex) => {
    if (rowIndex?.length !== 0) {
      setSelectedRowIndex2(rowIndex);
      handleInventoryStockSelect(rowIndex);
    } else {
      setSelectedRowIndex2(null);
      setInventoryIds(null)
    }
  };

  const handleInventoryTypeChange = () => {
    setSelectedRowIndex2([])
    setInventoryIds([])
    if (inventoryType === "self") {
      setInventoryType("userdefined");
      if (selectedRowIndex !== null) {
        handleFetchStocks(selectedRowIndex);
      }
    } else setInventoryType("self");
  };
  // console.log(orderItemStocks);

  const handleOptimizer = async () => {
    let orderItemIdValues = [];

    selectedRowIndex.map((rowIndex) => {
      orderItemIdValues = [
        ...orderItemIdValues,
        orderItemsData[rowIndex].orderItemId,
      ];
    });

    if (inventoryType === "userdefined" && inventoryIds?.length === 0) {
      toast.error("No stocks are selected");
      return;
    }

    let newRow = {
      orderItemIds: orderItemIdValues,
      scrapDefn: scrapDefData?.values,
      type: clusterFilter,
      inventoryType, 
    };

    if (inventoryType === "userdefined") {
      newRow = { ...newRow, inventoryIds: inventoryIds };
    }

    // console.log(newRow);

    setLoading("pending");

    dispatch(postPlan(newRow)).then((res) => {
      setLoading("fulfilled");
      if (res.payload !== undefined) {
        // console.log(res.payload);
        if (res.payload.message) {
          toast.error(`${res.payload.message}`);

          setInventoryData(res.payload.requiredInventory);
          setInventoryShow(true);
          //   "requiredInventory": [
          //     {
          //         "partItemId": 5335,
          //         "grade": "IS1786 FE550SD",
          //         "diameter": 10,
          //         "requiredPieces": 200,
          //         "requiredLength": 387
          //     }
          // ]
          return;
        }
        dispatch(setNavOptions({ expOpt: "Reports", selOpt: "Plan Details" }));
        navigate("/plan details", { state: { planId: res.payload.planId } });
      }
    });

    // navigate('/summary');
  };

  const openScrapDefinition = () => {
    setScrapDefVisible((prev) => !prev);
  };

  useEffect(() => {
    dispatch(fetchPlan());
    dispatch(fetchScrapDefinition());
  }, [dispatch]);

  const [clusterFilter, setClusterFilter] = useState("serviceCenter");

  let debounceTimer;

  const getClusterData = (e, value = "self") => {
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      setClusterFilter(e.target.value || value);
    }, 0);
  };

  // console.log(clusterFilter);

  return (
    <div className="sdbr">
       
        <>
          <div className="head">
            New Plan
            <div
              style={{
                display: "flex",
                color: "#000",
                fontSize: "1rem",
                alignItems: "flex-end",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                {/* <b>Level:</b> */}

                <div style={{ flexDirection: "row" }}>
                  <input
                    type="checkbox"
                    readOnly
                    name="defLevel"
                    id="self"
                    value={"serviceCenter"}
                    checked={true}
                  />
                  <label htmlFor="self">Service center</label>
                </div>
                <div style={{ flexDirection: "row" }}>
                  <input
                    type="radio"
                    name="level"
                    id="clear"
                    style={{ display: "none" }}
                    value={"serviceCenter"}
                    checked={clusterFilter === "serviceCenter"}
                    onChange={(e) => {
                      getClusterData(e);
                    }}
                  />
                  <label
                    htmlFor="clear"
                    className="blue"
                    style={{ fontSize: "1rem" }}
                  >
                    Clear
                  </label>
                </div>
                <div style={{ flexDirection: "row" }}>
                  <input
                    type="radio"
                    name="level"
                    id="cluster"
                    value={"cluster"}
                    checked={clusterFilter === "cluster"}
                    onChange={(e) => {
                      getClusterData(e);
                    }}
                  />
                  <label htmlFor="cluster">Cluster</label>
                </div>
                <div style={{ flexDirection: "row" }}>
                  <input
                    type="radio"
                    name="level"
                    id="region"
                    value={"region"}
                    checked={clusterFilter === "region"}
                    onChange={(e) => {
                      getClusterData(e);
                    }}
                  />
                  <label htmlFor="region">Region</label>
                </div>
                <div style={{ flexDirection: "row" }}>
                  <input
                    type="radio"
                    name="level"
                    id="panindia"
                    value={"national"}
                    checked={clusterFilter === "national"}
                    onChange={(e) => {
                      getClusterData(e);
                    }}
                  />
                  <label htmlFor="panindia">National</label>
                </div>
              </div>
            </div>
          </div>

          <div className="normal-head"></div>
          <AGTable
            data={orderItemsData}
            columns={orderItemsColumns}
            onSelectRow={handleRowSelect}
            rowSelection={"multiple"}
          />

          <div
            className="bottom-button-container"
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <div style={{ marginTop: "10px" }}>
              <input
                type="checkbox"
                readOnly
                name="chooseInv"
                id="Inv"
                value={"self"}
                checked={inventoryType === "userdefined"}
                onChange={() => {
                  handleInventoryTypeChange();
                }}
              />
              <label htmlFor="Inv">Select stocks</label>
            </div>
            {permission.updatePermission === true ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  className="blue"
                  label="Scrap Definition"
                  onClick={() => {
                    openScrapDefinition();
                  }}
                />
                <Button
                  className="blue"
                  label="Run Optimizer"
                  onClick={() => {
                    handleOptimizer(selectedRowIndex);
                  }}
                  disabled={selectedRowIndex}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          {inventoryType === "userdefined" && (
            <div>
              <div className="head">Stocks</div>
              <AGTable
                data={orderItemStocks}
                columns={orderitemStockColumns}
                onSelectRow={handleRowSelect2}
                rowSelection={"multiple"}
              />
            </div>
          )}
          {scrapDefVisible && (
            <ScrapDefPopup
              data={scrapDefData}
              columns={scrapDefColumns}
              onClose={openScrapDefinition}
            />
          )}

          {inventoryShow && (
            <InventoryShow
              data={inventoryData}
              columns={inventoryColumns}
              onClose={setInventoryShow}
            />
          )}
        </>
       { loading === "pending" ? (
        <div className="bg-dark"><Loader /></div>
        
      ) :''}
    </div>
  );
};

export default NewPlan;

const ScrapDefPopup = (props) => {
  const dispatch = useDispatch();

  const { data = [], columns = {}, onClose } = props;

  const [innerData, setInnerData] = useState(data?.values);

  const handleEdit = (header, value) => {
    setInnerData({ ...innerData, [header]: value * 1 });
  };

  const handleSave = () => {
    const id = data?.scrapDefinationId;
    const updatedData = { values: { ...innerData } };

    dispatch(editScrapDefinition({ id, updatedData })).then(() => {
      onClose();
      dispatch(fetchScrapDefinition());
    });
  };

  useEffect(() => {}, [data]);

  return (
    <div>
      <form>
        <div className="AddContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "fit-content",
              backgroundColor: "#fff",
              borderRadius: "10px",
              paddingBottom: "1vw",
            }}
          >
            <div
              className="table-container"
              style={{ height: "300px", overflow: "scroll" }}
            >
              <table className="generic-table">
                <thead>
                  <tr>
                    <th className="table-header">Diameter</th>
                    <th className="table-header">Scrap Length</th>
                  </tr>
                </thead>
                <tbody>
                  {columns.map((header, index) => (
                    <tr key={index}>
                      <td>{header}</td>
                      <td
                        style={{
                          padding: "0",
                          paddingLeft: "0",
                          paddingRight: "0",
                        }}
                      >
                        <input
                          type="number"
                          value={innerData[header]}
                          style={{ padding: "10px" }}
                          onChange={(e) => handleEdit(header, e.target.value)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="bottom-button-container">
              <Button
                label="Save"
                className="blue"
                onClick={() => handleSave()}
              />

              <Button
                label="Close"
                className="blue"
                onClick={() => onClose()}
              />
            </div>
          </div>
        </div>
        <div className="bg-dark"></div>
      </form>
    </div>
  );
};

const InventoryShow = (props) => {
  const { data = [], columns = {}, onClose } = props;

  return (
    <>
      <div>
        <form>
          <div className="AddContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "fit-content",
                backgroundColor: "#fff",
                borderRadius: "10px",
                paddingBottom: "1vw",
              }}
            >
              <div
                className="table-container"
                style={{
                  maxHeight: "400px",
                  overflow: "scroll",
                  minHeight: "auto",
                }}
              >
                <table className="generic-table">
                  <thead>
                    <tr>
                      {Object.keys(columns).map((data, index) => (
                        <th className="table-header" key={index}>
                          {columns[data].label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((data, index) => (
                      <tr key={index}>
                        {Object.keys(columns).map((header) => (
                          <td>{data[header]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="bottom-button-container">
                <Button
                  label="Close"
                  className="blue"
                  onClick={() => onClose(false)}
                />
              </div>
            </div>
          </div>
          <div className="bg-dark"></div>
        </form>
      </div>
    </>
  );
};
