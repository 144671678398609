//Title:
//Author
//Date
//Description
//Dependencies: node v18.0.1,
import CustomerWiseOrder from "./pages/reports/CustomerWiseOrders/CustomerWiseOrder.jsx";
import CubeUsage from "./pages/reports/Inventory/CubeUsage.jsx";
import Navbar from "./layouts/Navbar";
import "./App.css";
import Header from "./layouts/Header";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Shapes from "./pages/master/Shape/Shapes";
import MaxRebarPerCycle from "./pages/master/MaxRebarPerCycle";
import GradeDetails from "./pages/master/Grade/GradeDetails";
import Costing from "./pages/master/Costing";
import CustomerDetails from "./pages/master/Customer/CustomerDetails";
import DiaWiseRoute from "./pages/sdbr/DiaWiseRoute";
import DiffFactFormulae from "./pages/sdbr/DiffFactFormulae";
import DiffFactShape from "./pages/sdbr/DiffFactShape";
import PlantCapacity from "./pages/sdbr/PlantCapacity";
import TpmRate from "./pages/sdbr/TpmRate";
import OrderDetails from "./pages/transaction/OrderDetails/OrderDetails";
import OrderDeatilsDiaWise from "./pages/transaction/OrderDetails/OrderDetailsDiaWise";
import SDBR from "./pages/transaction/SDBR";
import NewPlan from "./pages/transaction/NewPlan";
import OpenPlan from "./pages/transaction/OpenPlan";
import ProductionRecording from "./pages/transaction/ProductionRecording";
import ProductionUploading from "./pages/transaction/ProductionUploading";
import ProductionUploadingOpt from "./pages/transaction/ProductionUploadingOpt";
import LoadingDetails from "./pages/transaction/Dispatch/LoadingDetails";
import ProductionHistory from "./pages/reports/ProductionHistory";
import ProductionRecorded from "./pages/reports/ProductionRecorded";
import RouteLoad from "./pages/reports/RouteLoad";
import BarMarkWiseQty from "./pages/reports/BarMarkWiseQty";
import PlantLoad from "./pages/reports/PlantLoad";
import ListOfShapes from "./pages/help/ListShapes/ListOfShapes.jsx";
import UserFeedBack from "./pages/help/UserFeedback/UserFeedBack.jsx";
import AddUserFeedback from "./pages/help/UserFeedback/AddUserFeedback.jsx";
import AddNew from "./pages/transaction/OrderDetails/AddNew";
import SapDocument from "./pages/reports/SapDocument";
import OrderFeatures from "./pages/reports/OrderFeatures";
import OrderDateComparison from "./pages/reports/OrderDateComparison";
import BBS from "./pages/transaction/BBS/BBS";
import OrderSummary from "./pages/transaction/OrderSummary";
import SAPOrderSummary from "./pages/transaction/SAPOrderSummary";
import ElementCopy from "./pages/transaction/OrderDetails/ElementCopy";
import Element from "./pages/transaction/OrderDetails/Element";
import OrderStatusHistory from "./pages/reports/orderStatusHistory";
import YieldMonitoring from "./pages/reports/YieldMonitoring";
import OrderProgress from "./pages/reports/OrderProgress";
import OrderWiseProgress from "./pages/reports/OrderWiseProgress";
import LengthWiseProdQty from "./pages/reports/LengthWiseProdQty";
import DiaWiseBalanceOrder from "./pages/reports/DiaWiseBalanceOrder";
import Order_LL_Relation from "./pages/reports/Order_LL_Relation";
import AutoDispatchOrder from "./pages/reports/AutoDispatchOrder";
import ProcessType from "./pages/master/ProcessType/ProcessType";
import EquipmentCategory from "./pages/master/EquipmentCategory/EquipmentCategory";
import Logo from "./assets/images/icons/content-bottom-logo.svg";
import Organization from "./pages/master/Organization/Organization";
import ProductMaster from "./pages/master/Product/ProductMaster";
import Partner from "./pages/master/Partner/Partner";
import ServiceCenter from "./pages/master/ServiceCenter/ServiceCenter";
import FacilityMaster from "./pages/master/Facility/FacilityMaster";
import EquipmentMaster from "./pages/master/Equipments/EquipmentMaster";
import AddNewShape from "./pages/master/Shape/AddNewShape";
import ProcessCenter from "./pages/master/ProcessCenter/ProcessCenter";
import ServiceCenterEquipments from "./pages/master/Equipments/ServiceCenterEquipments";
import { useDispatch, useSelector } from "react-redux";
import CustomerParties from "./pages/master/Customer/CustomerParties";
import ShapeUpdate from "./pages/master/Shape/ShapeUpdate";
import PdfViewer from "./components/pdfCreator/PdfViewer";
import ElementShow from "./pages/transaction/OrderDetails/ElementShow";
import { createContext, useEffect, useState } from "react";
import CreateLoadingDetails from "./pages/transaction/Dispatch/CreateLoadingDetails";
import NotFound from "./pages/NotFound";
import Dashboard from "../src/pages/reports/Dashboard/Dashboard";
import TempSummary from "./pages/reports/TempSummary";
import GatePassPrint from "./pages/transaction/Dispatch/Print/GatePassPrint";
import PrintLoadingList from "./pages/transaction/Dispatch/Print/LoadingList/PrintLoadingList.jsx";
import Presales from "./pages/transaction/Presales/Presales";
import PricingDetails from "./pages/reports/PricingDetails";
import UpdateLoadingDetails from "./pages/transaction/Dispatch/UpdateLoadingDetails";
import PresalesMaster from "./pages/master/PresalesMaster";
import PdfContainer from "./pages/reports/CuttingSequence/PdfContainer";
import CombinedInventory from "./pages/transaction/StockDetails/CombinedInventory";
import { fetchUserAccess } from "./redux-tookit/slices/userAccessSlice";
import AccessSettings from "./pages/Settings/UserAccess/AccessSettings.jsx";
import UserMaster from "./pages/Settings/UserCreation/UserMaster.jsx";
import { toast, ToastContainer } from "react-toastify";
import FalseLoginHandle from "./pages/FalseLoginHandle.jsx";
import UpdatePassword from "./pages/Settings/Password/UpdatePassword.jsx";
import DataConverter from "./components/DataMigration/DataConverter.jsx";
import Inventory from "./pages/reports/Inventory/Inventory.jsx";
import BlankLoadingPage from "./pages/BlankLoadingPage.jsx";
import SpringUploadFile from "./components/DataMigration/SpringUploadFile.jsx";
import ProductionParams from "./pages/transaction/ProdutionParams/ProductionParams.jsx";
import ProductSelectPage from "./pages/ProductSelectPage.jsx";
import ParamsPdfViewer from "./pages/transaction/ProdutionParams/pdfPrints/ParamsPdfViewer.jsx";
import ShapeExcelCreator from "./components/ShapeExcelCreator/ShapeExcelCreator.jsx";
// import Summary from "./pages/reports/Summary";
// import TagDetails from "./pages/transaction/TagDetails";
// import StockDetails from "./pages/transaction/StockDetails/StockDetails";

export const UserAccessContext = createContext();

function App() {
  const token = useSelector((state) => state.login.token);

  // const [token, setToken] = useState(sessionStorage.getItem('token'));
  // const[t,setT] = useState();
  //setT(sessionStorage.getItem('token'));

  const dispatch = useDispatch();

  // const [selectedOption, setSelectedOption] = useState('')
  const navbarData = useSelector((state) => state.userAccess.navbarNames);
  const [permission, setPermission] = useState({});



  const [productName, uProductName] = useState(sessionStorage.getItem('productName'))

  const setProductName = (name) => {
    uProductName(name)
    sessionStorage.setItem('productName', name)
  }
  console.log(productName);

  // const location = useLocation();

  // useEffect(() => {
  //   setSelectedOption(location.pathname);
  // }, [])

  console.log(navbarData);

  useEffect(() => {
    if (token !== null) {
      dispatch(fetchUserAccess())
        .then(res => {
          try {
            if (res.payload) {

              if (res.payload.navbarNames) {
                setPermission(res.payload.navbarNames)
              }

            }

          } catch (error) {
            toast.error(`${error.message}`)
          }
        })
       
    }
  }, []);

  useEffect(()=>{
    const tempName = sessionStorage.getItem('productName')
    if(tempName !== productName) {
      setProductName(tempName)
    }
  })

  return (
    <div className="app">
      <ToastContainer />

      {token !== null ? (
        <>
          <Navbar
            // selOption={selectedOption}
            navbarData={navbarData}
            productName={productName}
          />
          {/* {console.log('this runs')} */}
          <Header productName={productName} setProductName={setProductName} />
           <ProductSelectPage setProductName={setProductName} productName={productName}/> 

        </>
      ) : (
        ""
      )}

      {token !== null ? (
        <>
          <div className="content">
            <UserAccessContext.Provider value={{ navbarData, permission, productName}}>
              <Routes>

                <Route exact path="/order details" element={<OrderDetails />} />
                {/* <Route exact path="/product select page" element={<ProductSelectPage />} /> */}
                <Route exact path="/shapes" element={<Shapes />} />
                <Route
                  exact
                  path="/max rebar per cycle"
                  element={<MaxRebarPerCycle />}
                />
                <Route exact path="/process type" element={<ProcessType />} />
                <Route
                  exact
                  path="/equipment category"
                  element={<EquipmentCategory />}
                />
                <Route
                  exact
                  path="/equipment master"
                  element={<EquipmentMaster />}
                />
                <Route exact path="/grade details" element={<GradeDetails />} />
                <Route
                  exact
                  path="/customer details"
                  element={<CustomerDetails />}
                />
                <Route
                  exact
                  path="/customer parties"
                  element={<CustomerParties />}
                />
                <Route exact path="/pricing master" element={<Costing />} />
                <Route exact path="/organization" element={<Organization />} />
                <Route exact path="/partner" element={<Partner />} />
                <Route
                  exact
                  path="/service center"
                  element={<ServiceCenter />}
                />
                <Route
                  exact
                  path="/facility master"
                  element={<FacilityMaster />}
                />
                <Route
                  exact
                  path="/product master"
                  element={<ProductMaster />}
                />
                <Route exact path="/add new shape" element={<AddNewShape />} />
                <Route
                  exact
                  path="/shape update/:selectedShape"
                  element={<ShapeUpdate />}
                />
                <Route
                  exact
                  path="/process center"
                  element={<ProcessCenter />}
                />
                <Route
                  exact
                  path="/service center equipments"
                  element={<ServiceCenterEquipments />}
                />
                <Route
                  exact
                  path="/presales master"
                  element={<PresalesMaster />}
                />

                <Route
                  exact
                  path="/dia wise route"
                  element={<DiaWiseRoute />}
                />
                <Route
                  exact
                  path="/diff fact formulae"
                  element={<DiffFactFormulae />}
                />
                <Route
                  exact
                  path="/diff fact shape"
                  element={<DiffFactShape />}
                />
                <Route
                  exact
                  path="/plant capacity"
                  element={<PlantCapacity />}
                />
                <Route exact path="/tpm rate" element={<TpmRate />} />
                <Route exact path="/Cube+ Usage" element={<CubeUsage />} />
                <Route
                  exact
                  path="/Customer Wise Orders"
                  element={<CustomerWiseOrder />}
                />

                <Route
                  exact
                  path="/stock details"
                  element={<CombinedInventory />}
                />
                <Route exact path="/order details" element={<OrderDetails />} />
                <Route exact path="/add-new" element={<AddNew />} />
                <Route
                  exact
                  path="/order details dia wise"
                  element={<OrderDeatilsDiaWise />}
                />
                <Route exact path="/presales" element={<Presales />} />
                <Route exact path="/sdbr" element={<SDBR />} />
                <Route exact path="/new plan" element={<NewPlan />} />
                <Route exact path="/open plan" element={<OpenPlan />} />
                <Route
                  exact
                  path="/production recording"
                  element={<ProductionRecording />}
                />
                <Route
                  exact
                  path="/production uploading"
                  element={<ProductionUploading />}
                />
                <Route
                  exact
                  path="/production uploading opt"
                  element={<ProductionUploadingOpt />}
                />
                <Route
                  exact
                  path="/loading details"
                  element={<LoadingDetails />}
                />
                <Route
                  exact
                  path="/create loading details"
                  element={<CreateLoadingDetails />}
                />
                <Route
                  exact
                  path="/update loading details"
                  element={<UpdateLoadingDetails />}
                />
                <Route
                  exact
                  path="/gate pass print"
                  element={<GatePassPrint />}
                />
                <Route
                  exact
                  path="/print loading list"
                  element={<PrintLoadingList />}
                />

                <Route exact path="/bbs detail/:orderId" element={<BBS />} />

                <Route exact path="/production parameters/:orderId" element={<ProductionParams />} />

                <Route exact path="/add new/:orderId" element={<AddNew />} />

                <Route
                  exact
                  path="/summary/:orderId"
                  element={<OrderSummary />}
                />
                <Route
                  exact
                  path="/sap summary/:sapId"
                  element={<SAPOrderSummary />}
                />
                <Route
                  exact
                  path="/element/:orderId/copy"
                  element={<ElementCopy />}
                />
                <Route
                  exact
                  path="/element show/:id"
                  element={<ElementShow />}
                />
                <Route exact path="/element/:orderId" element={<Element />} />

                <Route exact path="/plan details" element={<TempSummary />} />
                <Route
                  exact
                  path="/cutting sequence"
                  element={<PdfContainer />}
                />
                <Route exact path="/Dashboard" element={<Dashboard />} />
                <Route
                  exact
                  path="/Presales Details"
                  element={<PricingDetails />}
                />
                <Route
                  exact
                  path="/production history"
                  element={<ProductionHistory />}
                />
                <Route
                  exact
                  path="/production recorded"
                  element={<ProductionRecorded />}
                />
                <Route exact path="/route load" element={<RouteLoad />} />
                <Route exact path="/plant load" element={<PlantLoad />} />
                <Route
                  exact
                  path="/bar mark wise qty"
                  element={<BarMarkWiseQty />}
                />
                <Route exact path="/sap document" element={<SapDocument />} />
                <Route
                  exact
                  path="/order features"
                  element={<OrderFeatures />}
                />
                <Route
                  exact
                  path="/order date comparision"
                  element={<OrderDateComparison />}
                />
                <Route
                  exact
                  path="/order status hist"
                  element={<OrderStatusHistory />}
                />
                <Route
                  exact
                  path="/yield monitoring"
                  element={<YieldMonitoring />}
                />
                <Route
                  exact
                  path="/order progress"
                  element={<OrderProgress />}
                />
                <Route
                  exact
                  path="/order wise progress"
                  element={<OrderWiseProgress />}
                />
                <Route
                  exact
                  path="/length wise product quantity"
                  element={<LengthWiseProdQty />}
                />
                <Route
                  exact
                  path="/dia wise balance order"
                  element={<DiaWiseBalanceOrder />}
                />
                <Route
                  exact
                  path="/production history"
                  element={<ProductionHistory />}
                />
                <Route
                  exact
                  path="/order ll relation"
                  element={<Order_LL_Relation />}
                />
                <Route
                  exact
                  path="/auto dispatch order"
                  element={<AutoDispatchOrder />}
                />
                <Route
                  exact
                  path="/download bbs tag/:orderId"
                  element={<PdfViewer />}
                />
                <Route exact path="/download bbs tag" element={<PdfViewer />} />

                <Route
                  exact
                  path="/list of shapes"
                  element={<ListOfShapes />}
                />
                <Route exact path="/Inventory" element={<Inventory />} />
                <Route exact path="/feedback" element={<UserFeedBack />} />
                <Route exact path="/Add-Issue" element={<AddUserFeedback />} />
                <Route
                  exact
                  path="/access settings"
                  element={<AccessSettings />}
                />
                <Route exact path="/user master" element={<UserMaster />} />
                <Route
                  exact
                  path="/update password"
                  element={<UpdatePassword />}
                />
                <Route
                  exact
                  path="/data converter"
                  element={<DataConverter />}
                />
                <Route
                  exact
                  path="/shape excel creator"
                  element={<ShapeExcelCreator />}
                />
                <Route
                  exact
                  path="/spring upload file"
                  element={<SpringUploadFile />}
                />
                <Route exact path="/params pdf viewer" element={<ParamsPdfViewer/>}/>

                <Route exact path="/login" element={<FalseLoginHandle />} />
                <Route path="/" element={<BlankLoadingPage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </UserAccessContext.Provider>
          </div>
          <div className="app-logo-container">
            <img src={Logo} alt="logo" />
          </div>
        </>
      ) : (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </div>
  );
}

export default App;

//End of File
