import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import axios from 'axios';
import { resetState } from './loginSlice';
import { toast } from 'react-toastify';

const initialState = {
  columns: {},
  scrapDefColumns: {},
  scrapDefData: [],
  stock1Columns: {},
  stock1Data: [],
  labeledData: [],
  loading: 'idle',
  error: null,
};

export const fetchPlan = createAsyncThunk('data/fetchPlan', async (params='') => {
  try {
    const response = await api.listPlanReadyOrder(params);
    const jsonData = response.data;

    // Extracting relevant data from the nested structure
    const labeledData = jsonData.rows.flatMap((row) =>
      row.Order.OrderItems.map((orderItem) => ({
        orderId: row.orderId,
        combId: row.Order.combId,
        orderRemarks: row.Order.orderRemarks,
        orderItemId: orderItem.orderItemId,
        orderItemName: orderItem.orderItemName,
        orderItemStatus: orderItem.orderItemStatus,
        soldToParty: row.soldToParty,
        poDate: row.Order.poDate,
        totalTags: orderItem.noOfTags,
        prodWeight: orderItem.totalProductionWeight,
        deliveryDate: row.Order.deliveryDate,
        orderRegion: row?.Order?.orderRegion || null, // Assuming orderRegion is present in your JSON
      }))
    ).filter(item => item.totalTags !== 0 && item.orderItemStatus!=='FG');

    const columns = {
      orderId: { label: 'ID', type: 'number', editable: false, isRequired: false },
      combId: { label: 'Order Id', type: 'number', editable: false, isRequired: false },
      orderRemarks: { label: 'Order Remarks', type: 'text', editable: true, isRequired: true },
      orderItemId: { label: 'Element ID', type: 'number', editable: false, isRequired: false },
      orderItemName: { label: 'Element Name', type: 'text', editable: true, isRequired: true },
      soldToParty: { label: 'Sold To Party', type: 'text', editable: true, isRequired: true },
      poDate: { label: 'PO Date', type: 'text', editable: true, isRequired: true },
      totalTags: { label: 'Total Tags', type: 'text', editable: true, isRequired: true },
      prodWeight: { label: 'Production Weight (T)', type: 'text', editable: true, isRequired: true },
      // orderItemStatus: {label: 'Order Item Status', type: 'text', editable: true, isRequired: true },
      deliveryDate: { label: 'Delivery Date', type: 'text', editable: true, isRequired: true },
      // orderRegion: { label: 'Order Region', type: 'text', editable: true, isRequired: false }, // Add more as needed
    };



    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});

export const fetchPlanStocks = createAsyncThunk('data/fetchPlanStocks', async (value) => {
  try {
    const response = await api.listPlanStocks(value);
    const jsonData = response.data;

    // Extracting relevant data from the nested structure
   
    return { jsonData };
  } catch (error) {
    throw error;
  }
});

export const postPlan = createAsyncThunk('data/postPlan', async (newRow) => {
  try {

    const response = await api.addPlan(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});

export const fetchStock1 = createAsyncThunk('data/fetchStock1', async (id) => {
  try {
    console.log(id)
    let url = 'http://13.234.248.118:5300/plan/selectstock/[' + id.join(',') + ']';
    console.log(url)
    const response = await axios.post(url);
    const json = response.data;
    //console.log(json)
    const columnLabels = {
      inventoryId: { label: 'Inventory Id', type: 'number', editable: false, isRequired: false },
      weight: { label: 'Weight', type: 'text', editable: true, isRequired: true },
      gradeName: { label: 'Grade', type: 'text', editable: true, isRequired: true },
      barType: { label: 'Bar Type', type: 'text', editable: true, isRequired: true },
      diameter: { label: 'Diameter', type: 'text', editable: true, isRequired: true },
      length: { label: 'Length', type: 'text', editable: true, isRequired: true },
      materialType: { label: 'Stock Type', type: 'text', editable: true, isRequired: true },
      totalPieces: { label: 'Total Pieces', type: 'text', editable: true, isRequired: true },
      // gradeId: {label:'Grade Id',type:'text',editable:true,isRequired:true},

      // Add more as needed
    };

    const selectedColumns = ['inventoryId', 'gradeName', 'barType', 'diameter', 'materialType', 'length', 'weight', 'totalPieces'];

    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.inventory.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });

    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});


export const fetchScrapDefinition = createAsyncThunk('data/fetchScrapDefinition', async () => {
  try {
    const response = await api.listScrapDefinition()
    const json = response.data.rows[0];
    console.log(json);

    const columns = Object.keys(json.values)

    return { columns, json };
  } catch (error) {
    throw error;
  }
});

export const editScrapDefinition = createAsyncThunk('data/editScrapDefinition', async ({ id, updatedData }) => {
  try {

    console.log(id, updatedData);

    const response = await api.updateScrapDefinition(id, updatedData);
    const data = response.data;
    console.log(data);
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetState, () => initialState)
      .addCase(fetchPlan.pending, (state) => {
        state.loading = 'pending';

      })
      .addCase(fetchPlan.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchPlan.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchScrapDefinition.pending, (state) => {
        state.loading = 'pending';

      })
      .addCase(fetchScrapDefinition.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        console.log("qpidj");
        state.scrapDefColumns = action.payload.columns;
        state.scrapDefData = action.payload.json;
      })
      .addCase(fetchScrapDefinition.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(editScrapDefinition.pending, (state) => {
        state.loading = 'pending';

      })
      .addCase(editScrapDefinition.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        toast.success(`Scrap defination updated successfully.`)
        // state.scrapDefData = action.payload.updatedData;
      })
      .addCase(editScrapDefinition.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
  },
});

export default planSlice.reducer;