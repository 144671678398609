import React, { useRef } from "react";
import Button from "../../utils/Button";
import { useDispatch } from "react-redux";
import { fetchShapes } from "../../redux-tookit/slices/shapeSlice";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx-color";
import { toast } from "react-toastify";

const ShapeExcelCreator = () => {
  const dispatch = useDispatch();

  let processRunning = false;

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";

  let toastId = useRef(null);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fileName = `ShapesBBSExcel-${getCurrentDate()}`;

  const createValueArray = async (shapesData) => {
    let valuesArray = [];

    for (let index = 0; index < shapesData.length; index++) {
      const shapeData = shapesData[index];

      let shapeId = shapeData.shapeId;

      let propertiesArray = JSON.parse(shapeData.imageProperties);

      let shapeStyleData = [shapeId];

      for (let j = 0; j < propertiesArray.length; j++) {
        const element = propertiesArray[j];

        let cellValue = "";

        if (element.Geometry === "Angle") {
          if (element.Capt2 === "yes" || element.Capt2 === "Yes") {
            cellValue = element.Match1;

            shapeStyleData.push( cellValue );
          } else {
            cellValue = element.Angle;
            shapeStyleData.push( cellValue );
          }
        } else if (element.Geometry === "Length") {
          cellValue = element.Match1;
          shapeStyleData.push( cellValue );
        }
        // shapeIdProperties.push();
      }

      valuesArray.push(shapeStyleData);
    }

    return valuesArray;
  };

  const giveShapesData = async () => {
    toastId = toast.loading("started");

    let shapesData = [];

    toast.update(toastId, {
        render: "Fetching shapes",
      });

    dispatch(fetchShapes()).then((res) => {
      if (res.payload) {
        shapesData = res.payload.shapes;

        toast.update(toastId, {
          render: "Shapes fetched",
        });

        convertShapesDataToExcelFormat(shapesData);
      }
    });
  };

  const convertShapesDataToExcelFormat = async (shapesData) => {
    const valueArray = await createValueArray(shapesData);

    toast.update(toastId, {
      render: "Value array generated",
    });

    console.log(valueArray);
    

    const ws = XLSX.utils.aoa_to_sheet(valueArray);


    const noOfShapes = shapesData.length;

    for (let index = 0; index < noOfShapes; index++) {
      const shapeData = shapesData[index];

      let shapeId = shapeData.shapeId;

      let propertiesArray = JSON.parse(shapeData.imageProperties);

      let shapeStyleData = [{ cellValue: shapeId, cellTextColor: "000000" }];

      for (let j = 0; j < propertiesArray.length; j++) {
        const element = propertiesArray[j];

        let cellValue = "";
        let cellTextColor = "D3D3D3";

        if (element.Geometry === "Angle") {
          if (element.Capt2 === "yes" || element.Capt2 === "Yes") {
            cellTextColor = "FF0000";
            cellValue = element.Match1;

            shapeStyleData.push({ cellValue, cellTextColor });
          } else {
            cellValue = element.Angle;
            shapeStyleData.push({ cellValue, cellTextColor });
          }
        } else if (element.Geometry === "Length") {
          cellTextColor = "000000";
          cellValue = element.Match1;
          shapeStyleData.push({ cellValue, cellTextColor });
        }
        // shapeIdProperties.push();
      }

      for (let i = 0; i < shapeStyleData.length; i++) {
        const cellData = shapeStyleData[i];

        ws[XLSX.utils.encode_cell({ r: index, c: i })] = {
          v: cellData.cellValue,
          s: {
            font: {
              color: { rgb: cellData.cellTextColor },
              bold: i?true:false,
              name: "Aptos Narrow",
            },
          },
        };
      }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, JSON.stringify(fileName) + fileExtension);

    toast.update(toastId, {
      render: "Generated successfully",
      type: "success",
      isLoading: false,
      autoClose: 3000, // Close the toast after 3 seconds
    });
  };

  const startShapeExcelCreation = async () => {
    if (processRunning) {
        toast.warning("Process already running")
    } else {
        processRunning = true;
        giveShapesData();
    }
  };

  return (
    <div>
      <Button
        label="Create shape Excel"
        className="blue"
        onClick={startShapeExcelCreation}
      />
    </div>
  );
};

export default ShapeExcelCreator;
