import React, { useEffect } from 'react'
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

import font1 from "../../../assets/fonts/Poppins-SemiBold.ttf"
import font2 from "../../../assets/fonts/Raleway-Regular.ttf"
import font3 from "../../../assets/fonts/Poppins-Regular.ttf"
import ArrowCircle from '../../../assets/images/icons/pdf/arrow-circle.png'

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        fontFamily: "Poppins-SemiBold",
        flexWrap: 'wrap',
        backgroundColor: 'white',
        border: '1px solid black',
        width: '100%',
        height: '100%',
        padding: '1%'
    },
});



const CuttingSequence = (props) => {

    Font.register({
        family: "Poppins-SemiBold", format: "truetype", src: font1
    });
    Font.register({
        family: "Raleway-Regular", format: "truetype", src: font2
    });
    Font.register({
        family: "Poppins-Regular", format: "truetype", src: font3
    });

    const { sequence, planId, offcutT, offcutP, scrapT, scrapP } = props

    const date = new Date();
    const showTime = date.getHours()
        + ':' + date.getMinutes()
        + ":" + date.getSeconds();
    const showDate = date.toLocaleDateString();

    return (
        <Document>
            {Object.keys(sequence).map((dia, index) => (
                <Page size="A4" style={styles.page}>
                    <View style={{ width: '100%', border: '1px solid black' }}>
                        <View style={{ height: '70px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontSize: '1' }}>.</Text>
                            <Text style={{ fontSize: '15' }}>Cutting Sequence</Text>
                            <Text style={{ fontSize: '8', fontFamily: 'Raleway-Regular' }}>Page {index + 1}</Text>
                        </View>
                        <View style={{ height: '150px' }}>
                            <DiaCuttingDetails dataJson={sequence[dia][0]} planId={planId} date={showDate} time={showTime} diaSequence={sequence[dia]} offcutT={offcutT} offcutP={offcutP} scrapT={scrapT} scrapP={scrapP} />
                        </View>
                        <View>
                            <SequenceTable diaSequence={sequence[dia]} />
                        </View>
                    </View>
                </Page>
            ))}
        </Document>
    )
}



const DiaCuttingDetails = (props) => {

    // const dispatch = useDispatch();
    const { dataJson, planId, date, time, diaSequence, offcutT, offcutP, scrapT, scrapP } = props;
    // const id = planId;
    //  useEffect(() => {
    //     // Fetch cutting sequence when the component mounts
    //     dispatch(fetchCuttingSeq())
    //         .then((res) => {
    //             console.log(res.payload);
    //             if (res.payload) {
    //                 // Handle the result here if needed
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching cutting sequence:', error);
    //             // Handle errors here if needed
    //         });
    // }, [dispatch, id]);

    let totalProdWeight = 0, totalScrapWeight = 0, totalOffcutWeight = 0, totalThreads = 0, totalHalfThread = 0, totalFullThread = 0;

    diaSequence.forEach((data) => {
        totalProdWeight += data.productionWeight;
        totalScrapWeight += data.scrap;
        totalOffcutWeight += data.offcut;
        totalThreads +=data.threads
        totalHalfThread += (data.totalNoOfPieces * data.halfThread)||0;
        totalFullThread += (data.totalNoOfPieces * data.fullThread)||0;

    })



    return (
        <>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: '0 20px' }}>
                <View style={{}}>
                    <LabelValue label='Plan Id' value={planId} />
                    <LabelValue label='Date' value={date} />
                    <LabelValue label='Time' value={time} />
                    <LabelValue label='Production weight(T)' value={Math.floor(totalProdWeight * 1000) / 1000} />
                </View>
                <View style={{}}>
                    <LabelValue label='Grade' value={dataJson.gradeName} />
                    <LabelValue label='Diameter' value={dataJson.diameter} />
                    <LabelValue label='Offcut(T)' value={Math.floor((totalOffcutWeight) * 1000) / 1000} />
                    <LabelValue label='Scrap(T)' value={Math.floor((totalScrapWeight) * 1000) / 1000} />
                    <LabelValue label='Offcut(%)' value={(Math.floor((totalOffcutWeight / totalProdWeight * 100) * 1000) / 1000) + "%"} />
                    <LabelValue label='Scrap(%)' value={(Math.floor((totalScrapWeight / totalProdWeight * 100) * 1000) / 1000) + "%"} />
                    <LabelValue label='Threads' value={totalThreads} />
                    {/* <LabelValue label='Half threads' value={totalHalfThread} />
                    <LabelValue label='Full threads' value={totalFullThread} /> */}


                </View>
            </View>

        </>
    )
}

const LabelValue = ({ label, value }) => {
    return (<View style={{ flexDirection: 'row', marginBottom: '5px' }}>
        <Text style={{ fontSize: '8', marginRight: '5px' }}>{label} : </Text>
        <Text style={{ fontSize: '8', fontFamily: 'Poppins-Regular' }}>{value}</Text>
    </View>)
}

const SequenceTable = ({ diaSequence }) => {


    let balanceLength = 0;
    let sequenceContinue = 0;

    let tableData = [
        { label: "Row", width: '4%', key: 'index' },
        { label: "Order Id", width: '11%', key: 'combId' },
        { label: "Element Name", width: '10%', key: 'orderItemName' },
        { label: "Dia", width: '4%', key: 'diameter' },
        { label: "BM Id", width: '6%', key: 'barMarkId' },
        { label: "Pieces required", width: '8%', key: 'requiredPieces' },
        { label: "Required Length (mm)", width: '9%', key: 'requiredLength' },
        { label: "Pieces Used", width: '7%', key: 'piecesUsed' },
        { label: "Length used", width: '9%', key: 'inventoryLength' },
        { label: "Cuts", width: '4%', key: 'noOfCuts' },
        { label: "Balance Length", width: '7%', key: 'balanceLength' },
        { label: "Inventory type", width: '8%', key: 'inventoryType' },
        { label: "No. of Pieces Used from inventory", width: '9%', key: 'remainingInventoryPieces' },
        { label: "Balance from ", width: '6%', key: 'remainingInventoryBalanceLength' },
        { label: "Derived to", width: '5%', key: 'derived' },
    ]

   

    const keepTrackBalance = ({invLen, reqLen}) => {
        balanceLength = invLen%reqLen
    }

 const getLengthUsed = (derivedFrom) => {

        const length = balanceLength
        // diaSequence.find(item => item.partItemId === partItemId).availablebalanceLength
        return length
    }


    

    console.log(diaSequence);


    return (

        <View style={{ flexDirection: 'column' }}>
            <TableHeader tableData={tableData} />
            {diaSequence.map((rowData, index) => {
                try {
                     const seqCheckAndBalLen = () => {
                    
                    if ( rowData.derived ) {
                        if (!sequenceContinue ) {
                            sequenceContinue = true
                        }
                    } else if(sequenceContinue){
                        sequenceContinue = false
                    }
                    if ( rowData.derivedFrom || rowData.derived) {
                       return balanceLength 
                    } else {
                        return rowData.balanceLength
                    }
                    
                }

                const getInventoryLength = ({invLen, reqLen}) => {
                    if (sequenceContinue) {
                        const beforeLength = balanceLength;
                        balanceLength = balanceLength%reqLen
                        return beforeLength
                    } else {
                        balanceLength = invLen % reqLen;
                        return invLen
                    }
                    
                }

                // if (sequenceContinue) {
                //     keepTrackBalance({invLen: balanceLength, reqLen: rowData.requiredLength })
                // }
                


                return(<View style={{ flexDirection: 'row', height: '40px', width: '100%', border: '0.5px solid black' }}>
                    {tableData.map((data) => (
                        <>
                            <View style={{
                                flexDirection: 'column',
                                width: data.width,
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '9'
                            }}>

                                <Text style={{ fontSize: '6' }}>{

                                    data.key === 'index' ? index + 1 :
                                        (data.key === 'derived' && rowData.derived === true ?
                                            // <Image src={ArrowCircle} style={{ height: '20px', width: '20px' }}></Image>
                                            'Offcut':
                                            data.key === 'inventoryLength' && (rowData.derived || rowData.derivedFrom) ? getInventoryLength({invLen: rowData.inventoryLength, reqLen: rowData.requiredLength}):
                                             data.key === 'balanceLength' && (rowData.derived || rowData.derivedFrom)?  seqCheckAndBalLen():
                                              data.key === 'inventoryType' && rowData.derivedFrom ? 'Offcut':
                                             rowData[data.key])

                                }
                                </Text>
                            </View>
                            {
                                rowData.remainingInventoryBalanceLength === true ? '' : ''
                            }
                        </>
                    ))}
                </View>)
                
                } catch (error) {
                    
                }

               

                

})}
        </View>
    )
}

const TableHeader = ({ tableData }) => {

    console.log("utfl");

    return (
        <View style={{ flexDirection: 'row', height: '40px', width: '100%', border: '1px solid black' }}>
            {tableData.map((data, index) => (
                <View style={{
                    flexDirection: 'column',
                    width: data.width,
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderLeft: `${index === 0 ? '0px' : '1px solid black'}`,
                    fontSize: '7'
                }}>

                    <Text style={{ fontSize: '6' }}>{data.label}</Text>
                </View>
            ))}
        </View>
    )
}

export default CuttingSequence
