import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../utils/Button';
import '../../assets/styles/NewPlan.css'
import { fetchOpenPlan, freezePlan } from '../../redux-tookit/slices/openPlanSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AGTable from '../../components/Tables/AGTableOpenPlan';
import { setNavOptions } from '../../redux-tookit/slices/navbarSlice';
import { fetchUserAccess } from '../../redux-tookit/slices/userAccessSlice';
import Loader from '../../components/Loader/Loader.jsx'



const OpenPlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const columns = useSelector((state) => state.openPlan.columns);
    const originalData = useSelector((state) => state.openPlan.labeledData);
    const loading = useSelector(state => state.openPlan.loading)

    const [statusFilter, setStatusFilter] = useState('Not Freezed')

    const statusValues = ['Freezed', 'Not Freezed']

    const [data, setData] = useState(originalData);

    // console.log(data, originalData);

    const applyFilters = () => {
        let filteredData = originalData;

        if (statusFilter) {
            filteredData = filteredData.filter((item) => {
                if (statusFilter === 'Freezed') {
                    // console.log(item.planStatus);
                    if (item.planStatus * 1 === 1) {
                        return true
                    } else return false
                } else if (statusFilter === 'Not Freezed') {
                    if (item.planStatus * 1 === 0) {
                        return true
                    } else return false
                } else return true

            });
        }

        setData(filteredData);
    };

    const [planId, setPlanId] = useState(null)
    const [selectedOption, setSelectedOption] = useState("planWiseOrder");
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const handleFreeze = async (id) => {
        if (id === null) {
            toast.error('Select a Plan');
            return;
        }
        if (data[selectedRowIndex].planStatus * 1 === 1) {
            toast.error('Plan already freezed');
            return;
        }

        dispatch(freezePlan(id)).then((res) => {
            if (res.payload !== undefined) {
                dispatch(fetchOpenPlan())
            }
        })
    }

    const [permission, setPermission] = useState({});
    useEffect(() => {
        dispatch(fetchUserAccess())
            .then(res => {
                if (res.payload) {
                    if (res.payload.navbarNames.Transaction["Open Plan"][0]) {
                        setPermission(res.payload.navbarNames.Transaction["Open Plan"][0])
                    }
                }
            })
    }, [])

    const openPlanDetail = (id) => {
        if (id === null) {
            toast.error('Select a Plan');
            return;
        }
        dispatch(setNavOptions({ expOpt: 'Reports', selOpt: 'Plan Details' }))
        navigate('/plan details', { state: { planId: planId } });
    }
    
    useEffect(() => {

        dispatch(fetchOpenPlan());
    }, [dispatch]);

    useEffect(() => {
        setData(originalData)
        applyFilters()
    }, [originalData, statusFilter]);

    const handleRowSelect = (rowIndex) => {

        if (rowIndex !== selectedRowIndex && rowIndex !== undefined) {
            setSelectedRowIndex(rowIndex);
            setPlanId(data[rowIndex].planId)
        } else {
            setSelectedRowIndex(null)
            setPlanId(null)
        }

    };

    return (
        <div className='sdbr'>
            {loading === 'pending' ? <Loader /> :
                <>
                    <div className="head">Open plan</div>
                    <div className='filter-card'>
                        <div className="filter-layout" style={{ gridTemplateColumns: '1fr', gridTemplateRows: '1fr' }}>

                            <div className="filter" style={{ flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                <label htmlFor="planStatus">Plan Status</label>
                                <select name="planStatus" id="planStatus" value={statusFilter} style={{ width: 'fit-content' }} onChange={(e) => setStatusFilter(e.target.value)}>
                                    <option value="">All</option>
                                    {statusValues.map(data => (

                                        <option value={data}>{data}</option>

                                    ))}
                                </select>
                            </div>
                        </div>


                    </div>

                    {/* {selectedOption === "planWiseOrder" && <div className='head'>Plan Order Relation</div>}
            {selectedOption === "orderWisePlan" && <div className='head'>Order Plan Relation
                <img src={deletebutton} style={{ width: "15px" }} alt="" />
            </div>}
 
            {selectedOption === "planWiseOrder" && <GenericTable1 columns={columns_PlanWiseOrder} data={data_PlanWiseOrder} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />}
            {selectedOption === "orderWisePlan" && <GenericTable columns={columns_OrderWisePlan} data={data_OrderWisePlan} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />}
  */}


                    <AGTable data={data} columns={columns} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />


                    {/* Rendering buttons based on the radio button selected */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{ backgroundColor: 'red', width: '20px', height: '20px', display: 'flex' }}></div>
                            <div>Freezed</div>
                        </div>
                        <div>
                            <div style={{ backgroundColor: 'green', width: '20px', height: '20px' }}></div>
                            <div>Not Freezed</div>
                        </div>
                        {selectedOption === "planWiseOrder" && <div className="bottom-button-container ">
                            {/* <Button label="Plan Feedback" className="blue" />
                    <Button label="Export to excel" className="blue" /> */}
                            <Button label="Open" className="blue" onClick={() => openPlanDetail(planId)} disabled={planId} message='No plan selected. Select a plan and try again' />
                            {permission.updatePermission === true ?
                                <Button label="Freeze" className="blue" onClick={() => handleFreeze(planId)} disabled={planId} message='No plan selected. Select a plan and try again' /> : ''}
                        </div>}


                        {selectedOption === "orderWisePlan" && <div className="bottom-button-container">
                            <Button label="Plan Feedback" className="blue" />

                            <Button label="Export to excel" className="blue" />
                        </div>}
                    </div>
                </>
            }
        </div>
    )
}

export default OpenPlan