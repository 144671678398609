import { Document, Page, View, StyleSheet, Text, Image, Font } from '@react-pdf/renderer'
import React from 'react'
import font from "../../../../../assets/fonts/OpenSans-Variable.ttf"
import font2 from "../../../../../assets/fonts/OpenSans-Bold.ttf"

import { dateChanger } from '../../../../../components/reusableFunctions';
import readyBuild from '../../../../../assets/images/icons/readybuild.png'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    // flexWrap: 'wrap',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    padding: '5% 8% ',
    fontFamily: 'OpenSans',
    fontSize: '10px'

  },
  elementHeader: {
    borderBottom: '1px solid black',
    fontSize: '12',
    padding: '5px 0'
  }
});

const TopSection = (props) => {

  const { serviceCenterLogo, listDetails } = props
  return (
    <View style={{ width: '100%', borderBottom: '1px solid black' }}>
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Image src={serviceCenterLogo} style={{ height: '27px', border: '1px solid black' }}></Image>
        <Image src={readyBuild} style={{ height: '27px', border: '1px solid black' }}></Image>
      </View>
      <View style={{ border: '2px solid black', flexDirection: 'row', justifyContent: 'center' }}>
        <Text style={{ color: 'brown', fontFamily: 'OpenSans-Bold' }}>DELIVERY NOTE</Text>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
        <View><Text ><Text style={{ fontFamily: 'OpenSans-Bold' }}> Loading List Name: </Text> {listDetails.loadingListName}</Text></View>
        <View><Text></Text></View>
        <View><Text><Text style={{ fontFamily: 'OpenSans-Bold' }}>Gate Pass Print Date: </Text> {dateChanger(listDetails.gatePassPrintDate)}</Text></View>
      </View>
    </View>
  )
}

const TagContainer = (props) => {

  const { data } = props

  const url = String.fromCharCode.apply(null, new Uint8Array(data.image.data));


  return (
    <View style={{ width: '33%', height: '', fontSize: '8px', padding: '8px', flexDirection: 'row' }}>
      <View style={{ width: '40%', flexDirection: 'column', gap: '8px' }}>
        <View>
          <Text><Text style={{ fontFamily: 'OpenSans-Bold' }}>Dia: </Text>{data.diameter}</Text>
        </View>
        <View>
          <Text><Text style={{ fontFamily: 'OpenSans-Bold' }}>Qty: </Text>{data.quantity}</Text>
        </View>
        <View>
          <Text><Text style={{ fontFamily: 'OpenSans-Bold' }}>BM: </Text>{data.barMarkId}</Text>
        </View>
        <View>
          <Text><Text style={{ fontFamily: 'OpenSans-Bold' }}>Item: </Text>{data.orderItemName}</Text>
        </View>
      </View>
      <View style={{ width: '60%', overflow: 'hidden' }}>
        <Image src={url} style={{ width: '100%' }}></Image>
      </View>
    </View>
  )

}


const BigTable = (props) => {

  const { elementWiseWeight } = props

  return (
    <View style={{ width: '100%', flexDirection: 'column', marginTop: '40px' }} wrap={false}>
      <View style={{ flexDirection: 'row', fontFamily: 'OpenSans-Bold' }}>
        <View style={{ flexDirection: 'row', border: '1px solid black', width: '10%', justifyContent: 'center' }}><Text >Sl.no</Text> </View>
        <View style={{ flexDirection: 'row', border: '1px solid black', width: '60%', justifyContent: 'center', borderLeft: '0' }}><Text >Element</Text></View>
        <View style={{ flexDirection: 'row', border: '1px solid black', width: '30%', justifyContent: 'center', borderLeft: '0' }}><Text >Weight(T)</Text></View>
      </View>
      {Object.keys(elementWiseWeight).map((data, index) => (
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', border: '1px solid black', width: '10%', justifyContent: 'center', borderTop: '0', padding: '0 8px' }}><Text >{index + 1}</Text> </View>
          <View style={{ flexDirection: 'row', border: '1px solid black', width: '60%', borderTop: '0', borderLeft: '0', padding: '0 8px' }}><Text >{data}</Text></View>
          <View style={{ flexDirection: 'row', border: '1px solid black', width: '30%', borderTop: '0', borderLeft: '0', padding: '0 8px' }}><Text >{Math.floor(elementWiseWeight[data] * 1000) / 1000}</Text></View>
        </View>
      ))}
    </View>
  )
}

const SmallTable = (props) => {

  const { diaWiseWeight, diaWiseThreads, diaWiseHalfThread, diaWiseFullThread } = props

  let sum = 0
  let totalThreads = 0;
  let totalHalfThread = 0;
  let totalFullThread = 0;

  Object.keys(diaWiseWeight).forEach(data => {
    sum += diaWiseWeight[data] * 1;
    totalThreads += diaWiseThreads[data] * 1;
    totalHalfThread += diaWiseHalfThread[data] * 1;
    totalFullThread += diaWiseFullThread[data] * 1;
  })


  return (
    <View style={{ width: '100%', flexDirection: 'row', marginTop: '20px', justifyContent: 'center' }} wrap={false}>
      <View style={{ flexDirection: 'column', width: '70%' }}>
        <View style={{ flexDirection: 'row', fontFamily: 'OpenSans-Bold' }}>
          <View style={{ flexDirection: 'row', border: '1px solid black', width: '50%', justifyContent: 'center' }}><Text >BAR DIA</Text></View>
          <View style={{ flexDirection: 'row', border: '1px solid black', width: '60%', justifyContent: 'center', borderLeft: '0' }}><Text >Weight(T)</Text></View>
          {/* <View style={{ flexDirection: 'row', border: '1px solid black', width: '60%', justifyContent: 'center', borderLeft: '0' }}><Text >Threads</Text></View> */}
          {totalHalfThread &&<View style={{ flexDirection: 'row', border: '1px solid black', width: '80%', justifyContent: 'center', borderLeft: '0' }}><Text >Half threads</Text></View>}
          {totalFullThread &&<View style={{ flexDirection: 'row', border: '1px solid black', width: '80%', justifyContent: 'center', borderLeft: '0' }}><Text >Full threads</Text></View>}

        </View>
        {Object.keys(diaWiseWeight).map((data, index) => (
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flexDirection: 'row', border: '1px solid black', width: '50%', borderTop: '0', padding: '0 8px', justifyContent: 'center', fontFamily: 'OpenSans-Bold' }}><Text >{data}</Text></View>
            <View style={{ flexDirection: 'row', border: '1px solid black', width: '60%', borderTop: '0', borderLeft: '0', padding: '0 8px', justifyContent: 'center' }}><Text >{Math.floor(diaWiseWeight[data] * 1000) / 1000}</Text></View>
            {/* {totalThreads && <View style={{ flexDirection: 'row', border: '1px solid black', width: '60%', borderTop: '0', borderLeft: '0', padding: '0 8px', justifyContent: 'center' }}><Text >{diaWiseThreads[data]}</Text></View>} */}
            {totalHalfThread && <View style={{ flexDirection: 'row', border: '1px solid black', width: '80%', borderTop: '0', borderLeft: '0', padding: '0 8px', justifyContent: 'center' }}><Text >{diaWiseHalfThread[data]}</Text></View>}
            {totalFullThread && <View style={{ flexDirection: 'row', border: '1px solid black', width: '80%', borderTop: '0', borderLeft: '0', padding: '0 8px', justifyContent: 'center' }}><Text >{diaWiseFullThread[data]}</Text></View>}

          </View>
        ))}
        <View style={{ flexDirection: 'row' }}>

          <View style={{ flexDirection: 'row', border: '1px solid black', width: '50%', borderTop: '0', padding: '0 8px', fontFamily: 'OpenSans-Bold', justifyContent: 'center' }}><Text >Total</Text></View>
          <View style={{ flexDirection: 'row', border: '1px solid black', width: '60%', borderTop: '0', borderLeft: '0', padding: '0 8px', justifyContent: 'center', alignItems: 'center' }}><Text >{Math.floor(sum * 1000) / 1000}</Text></View>
          {/* {totalThreads && <View style={{ flexDirection: 'row', border: '1px solid black', width: '60%', borderTop: '0', borderLeft: '0', padding: '0 8px', justifyContent: 'center', alignItems: 'center' }}><Text >{totalThreads}</Text></View>} */}
          {totalHalfThread && <View style={{ flexDirection: 'row', border: '1px solid black', width: '80%', borderTop: '0', borderLeft: '0', padding: '0 8px', justifyContent: 'center', alignItems: 'center' }}><Text >{totalHalfThread}</Text></View>}
          {totalFullThread && <View style={{ flexDirection: 'row', border: '1px solid black', width: '80%', borderTop: '0', borderLeft: '0', padding: '0 8px', justifyContent: 'center', alignItems: 'center' }}><Text >{totalFullThread}</Text></View>}

        </View>
      </View>
    </View>
  )
}

const TableHeader = () => {
  let tableData = [{ label: "Sl No", width: '4%' },
  { label: "Bar Mark Id", width: '14%' },
  // { label: "Element", width: '12%' },
  { label: "Tag Desc.", width: '12%' },
  { label: "Shape", width: '18%' },
  { label: "Dia (mm)", width: '5%' },
  { label: "Pin Dia (mm)", width: '5%' },
  { label: "Length (mm)", width: '8%' },
  { label: "Total Pieces", width: '8%' },
  { label: "Bundle quantity", width: '8%' },
  { label: "Threads (half /full)", width: '8%' },
  { label: "Weight (T)", width: '10%' }
  ]
  
  return (
    <View style={{ flexDirection: 'row', height: '40px', width: '100%' }}>
      {tableData.map((data, tableIndex) => (
        <View style={{
          flexDirection: 'column',
          width: data.width,
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid black',
          fontSize: '9',
          fontFamily: 'OpenSans-Bold',
          borderLeft: `${tableIndex === 0 ? '1px' : '0px'}`
        }}>

          <Text style={{textAlign:'center'}}>{data.label || ''}</Text>
        </View>
      ))}
    </View>
  )
}

const PartItemMapper = ({ partItem, index }) => {

  let tableData = [
    { key: "index", width: '4%', },
    { key: "barMarkId", width: '14%' },
    // { key: "orderItemName", width: '12%' },
    { key: "tagDescription", width: '12%' },
    { key: "shape", width: '18%' },
    { key: "diameter", width: '5%' },
    { key: "pinDia", width: '5%' },
    { key: "productionLength", width: '8%' },
    { key: "totalNoOfPieces", width: '8%' },
    { key: "bundles", width: '8%' },
    { key: "threads", width: '8%' },
    { key: "productionWeight", width: '10%' }
  ]

  const url = String.fromCharCode.apply(null, new Uint8Array(partItem.image.data));
  // console.log(url);

  return (
    <View wrap={false} style={{ flexDirection: 'row', height: '90px', width: '100%' }}>

      {tableData.map((data, partIndex) => (
        <>

          <View style={{
            flexDirection: 'column',
            width: data.width,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid black',
            fontSize: '9',
            borderTop: '0px',
            borderLeft: `${partIndex === 0 ? '1px' : '0px'}`,
            borderTop: `${index % 10 == 0 ? '1px' : '0px'}`

          }}>
{/* TT-${partItem[data.key]} */}
            {
              data.key === 'index' ? <Text style={{ fontWeight: "bold" }}>{index + 1 || ''}</Text> :
                data.key === 'shape' ? <Image src={url} style={{ width: '100%' }}></Image> :
                  data.key === 'threads' ?
                    <Text style={{ fontWeight: "bold", textAlign: 'center' }}>{`HT-${partItem.totalNoOfPieces * partItem.halfThread || '0'}
FT-${partItem.totalNoOfPieces * partItem.fullThread || '0'}`}</Text> :
                    <Text style={{ fontWeight: "bold" }}>{partItem[data.key]}</Text>
            }

          </View>
        </>
      ))}
    </View>
  )
}



//main function ##############################################################################################

const CreateLoadingListPDF = (props) => {

  const { serviceCenterLogo, listDetails, partItems, bundleData } = props

  let actualPartData = partItems

  const diaValues = [...new Set(partItems.map(item => item.diameter))].sort((a, b) => a - b)

  const orderItemValues = [...new Set(partItems.map(item => item.orderItemName))].sort((a, b) => a - b)

  let diaWiseWeight = {};
  let diaWiseThreads = {};
  let diaWiseHalfThread = {};
  let diaWiseFullThread = {};

  let elementWiseWeight = {};

  // .map(item => {
  //   return {
  //     diameter: item.diameter,
  //     bmRow: item.barMarkId,
  //     orderItemName: item.orderItemName
  //   }
  // })

  console.log(actualPartData, 'before');


  partItems.forEach((ele, index) => {
    let tempData = { quantity: 0, weight: 0 }
    bundleData.forEach(data => {
      if (ele.partItemId === data.partItemId) {
        tempData = {
          quantity: tempData.quantity + data.noOfPieces,
          weight: tempData.weight + data.weight
        }
        console.log(tempData);
      }
    })

    actualPartData[index] = { ...actualPartData[index], ...tempData }

  });

  let diaWeight = {};
  let diaThreads = {};
  let diaHalfThread = {};
  let diaFullThread = {};

  let elementWeight = {};

  // const calculateDiaWeight = () => {

    diaValues.map((dia) => {
      const header = `T${dia}`;
      let weightSum = 0;
      let threadSum = 0;
      let halfThreadSum = 0;
      let fullThreadSum = 0;
      actualPartData.map((item) => {
        if (item.diameter * 1 === dia * 1) {
          weightSum += item.weight;
          threadSum += item.threads || 0;
          halfThreadSum += (item.halfThread * item.totalNoOfPieces) || 0;
          fullThreadSum += (item.fullThread * item.totalNoOfPieces) || 0
        }
      })
      diaWeight = { ...diaWeight, [header]: weightSum }
      diaThreads = { ...diaThreads, [header]: threadSum }
      diaHalfThread = { ...diaHalfThread, [header]: halfThreadSum }
      diaFullThread = { ...diaFullThread, [header]: fullThreadSum }
    })
    console.log(diaWeight);
    diaWiseWeight = { ...diaWiseWeight, ...diaWeight }
    diaWiseThreads = { ...diaWiseThreads, ...diaThreads }
    diaWiseHalfThread = { ...diaWiseHalfThread, ...diaHalfThread }
    diaWiseFullThread = { ...diaWiseFullThread, ...diaFullThread }

    orderItemValues.map((itemNames) => {
      let weightSum = 0;
      actualPartData.map((item) => {
        if (item.orderItemName === itemNames) {
          weightSum += item.weight;
        }
      })
      elementWeight = { ...elementWeight, [itemNames]: weightSum }
    })
    elementWiseWeight = { ...elementWiseWeight, ...elementWeight };
  
  console.log(diaWiseWeight, elementWiseWeight, diaWiseThreads, diaWiseHalfThread, diaWiseFullThread);

  // useEffect(() => {
  //   calculateDiaWeight()
  // }, [partItems])

  console.log(actualPartData, 'after');

  Font.register({
    family: "OpenSans", format: "truetype", src: font
  });

  Font.register({
    family: "OpenSans-Bold", format: "truetype", src: font2
  });

  let elementName = '';

  return (
    <Document>
      <Page size="A4" style={styles.page}>

        <Text style={{ textAlign: 'right', fontSize: '8' }} render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )} fixed />

        <View render={({ pageNumber, totalPages }) => {

          return (pageNumber !== 1 && pageNumber !== totalPages) && (<>
            <TableHeaderFixed />
          </>)

        }} fixed />

        <TopSection
          serviceCenterLogo={serviceCenterLogo}
          listDetails={listDetails}
        />



        <TableHeader />


        {partItems.map((element, index) => {
          elementName = element.orderItemName;
          return (<View wrap={false}>

            {
              index !== 0 ? partItems[index].orderId !== partItems[index - 1].orderId || (index ) % 5 === 0 ?
                <View style={{...styles.elementHeader, borderBottom: '0', paddingBottom: '0'}}><Text>OrderId: {partItems[index].combId}</Text></View> :
                <View /> :
                <View style={{...styles.elementHeader, borderBottom: '0', paddingBottom: '0'}}><Text>OrderId: {partItems[index].combId}</Text></View>
            }
            {
              index !== 0 ? partItems[index].orderItemName !== partItems[index - 1].orderItemName || (index) % 5 === 0 ?
                <View style={styles.elementHeader}><Text>Element: {partItems[index].orderItemName}</Text></View> :
                <View /> :
                <View style={styles.elementHeader}><Text>Element: {partItems[index].orderItemName}</Text></View>
            }

            <PartItemMapper partItem={element} index={index} />

          </View>
          )
        }
        )}






      </Page>
      <Page size="A4" style={styles.page}>

        <Text style={{ textAlign: 'right', fontSize: '8' }} render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )} fixed />

        <BigTable elementWiseWeight={elementWiseWeight} />

        <SmallTable diaWiseWeight={diaWiseWeight} diaWiseThreads={diaWiseThreads} diaWiseHalfThread={diaWiseHalfThread} diaWiseFullThread={diaWiseFullThread} />
      </Page>
    </Document>
  );
};

export default CreateLoadingListPDF;

const TableHeaderFixed = () => {

  let tableData = [{ label: "Sl No", width: '4%' },
  { label: "Bar Mark Id", width: '14%' },
  // { label: "Element", width: '12%' },
  { label: "Tag Desc.", width: '12%' },
  { label: "Shape", width: '18%' },
  { label: "Dia (mm)", width: '5%' },
  { label: "Pin Dia (mm)", width: '5%' },
  { label: "Length (mm)", width: '8%' },
  { label: "Total Pieces", width: '8%' },
  { label: "Bundle quantity", width: '8%' },
  { label: "Threads", width: '8%' },
  { label: "Weight (T)", width: '10%' }
  ]

  return (
    <View style={{ flexDirection: 'row', height: '40px', width: '100%' }}>
      {tableData.map((data, tableIndex) => (
        <View style={{
          flexDirection: 'column',
          width: data.width,
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid black',
          fontSize: '9',
          fontFamily: 'OpenSans-Bold',
          borderLeft: `${tableIndex === 0 ? '1px' : '0px'}`
        }}>

          <Text style={{}}>{data.label || ''}</Text>
        </View>
      ))}
    </View>
  )
}