import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/styles/orderDetails.css'
import Button from '../../utils/Button';
import FilterComponent from '../../components/FilterComponent';
import SDBRTable from '../../components/SDBRTable';
import { confirmPO, unConfirmPO, fetchSdbr, runSdbr } from '../../redux-tookit/slices/sdbrDataSlice';
import { toast } from 'react-toastify';
import refresh from '../../assets/images/button-icons/Refresh.svg';
import { fetchUserAccess } from '../../redux-tookit/slices/userAccessSlice';
import Loader from '../../components/Loader/Loader'
import {useNavigate} from 'react-router-dom'
import { setNavOptions } from '../../redux-tookit/slices/navbarSlice';


const SDBR = () => {
    const dispatch = useDispatch();
    const navigate=useNavigate();
    const columns = useSelector((state) => state.sdbrData.columns);
    const originalData = useSelector((state) => state.sdbrData.labeledData);
    // const orderList = useSelector(state => state.order.labeledData)
    const loading = useSelector(state => state.sdbrData.loading);
    
    const [data, setData] = useState(originalData);
    const [selectedRowIndex, setSelectedRowIndex] = useState([]);

    const [customerNameFilter, setCustomerNameFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('Order Created');
    const [fromDateFilter, setFromDateFilter] = useState('');
    const [toDateFilter, setToDateFilter] = useState('');
    const [filterButton, setFilterButton] = useState('Close');
    const [customerNameValues, setCustomerNameValues] = useState([]);
    const [statusValues, setStatusValues] = useState([])

    console.log(originalData, data)

    const [permission, setPermission] = useState({});
    useEffect(() => {
        dispatch(fetchUserAccess())
            .then(res => {
                if (res.payload) {
                    if (res.payload.navbarNames.Transaction["SDBR"][0]) {
                        setPermission(res.payload.navbarNames.Transaction["SDBR"][0])
                    }
                }
            })
    }, [])

    const handleRowSelect = (rowIndex) => {
        console.log(rowIndex);
        if (selectedRowIndex.includes(rowIndex)) {
            let tempArray = []
            selectedRowIndex.map(data => {
                if (data !== rowIndex) {
                    tempArray.push(data)
                }
            })
            setSelectedRowIndex(() => { return tempArray });
        } else {
            setSelectedRowIndex(() => {
                return [...selectedRowIndex, rowIndex]
            });
        }

    };
    console.log(selectedRowIndex);

    const onFilterClick = () => {
        filterButton === 'Filter' ? setFilterButton('Close') : setFilterButton('Filter');
    }

    const applyFilters = () => {
        let filteredData = originalData;

        if (customerNameFilter) {
            filteredData = filteredData.filter((item) =>
                item.customerName === customerNameFilter
            );
        }

        if (statusFilter) {
            filteredData = filteredData.filter((item) =>
                item.orderStatus === statusFilter
            );
        }

        if (fromDateFilter && toDateFilter) {
            const fromDate = new Date(fromDateFilter);
            const toDate = new Date(toDateFilter);

            filteredData = filteredData.filter((item) => {
                const orderDate = new Date(item.deliveryDate);
                return orderDate >= fromDate && orderDate <= toDate;
            });
        }
        console.log('statusFilter', statusFilter);
        setData(filteredData);
    };

    const handleRunSdbr = async () => {
        let runValues = []

        // runValues = [...runValues, originalData[selectedRowIndex].orderId]

        // { ...runValues, [column]: originalData[selectedRowIndex][column] }

        selectedRowIndex.map(rowIndex => {
            runValues = [...runValues, data[rowIndex].orderId]
            dateChecker(data[rowIndex].deliveryDate)
        })


        dispatch(runSdbr({ orderId: runValues })).then((res) => {
            if (res.payload !== undefined) {
                dispatch(fetchSdbr())
                setStatusFilter('Sdbr run')
            }
        })

        // orderId: data[selectedRowIndex].orderId,
        // productWeight: data[selectedRowIndex].productWeight,
        // groupNo: data[selectedRowIndex].groupNo,
        // sequenceNo: data[selectedRowIndex].sequenceNo
        setSelectedRowIndex([])
        console.log(runValues)
    }

    const handleConfirmPO = async () => {
        let id = null
        if (selectedRowIndex.length > 1) {
            toast.error('Multiple Confirm PO not possible')
            setSelectedRowIndex([])
            return
        }
        if (data[selectedRowIndex].orderStatus.toLowerCase() === 'po confirmed') {
            toast.error('PO already confirmed')
            setSelectedRowIndex([])
            return
        }
        try {
            id = data[selectedRowIndex].sdbrId;
        } catch (error) {
            toast.error(`${error}`)
        }



        console.log(id)
        dispatch(confirmPO(id)).then((res) => {
            if (res.payload !== undefined) {
                fetchSdbr();
                dispatch(setNavOptions({ expOpt: 'Transaction', selOpt: 'New Plan' }))
              
                 setTimeout(()=>{
                   navigate("/new plan")
                 }, 3000)    
            }
        })
        setSelectedRowIndex([])
    }

    const handleUnConfirmPO = async () => {
        let runValues = []
        selectedRowIndex.map(rowIndex => {
            runValues = [...runValues, data[rowIndex].orderId]
        })

        dispatch(unConfirmPO({ orderId: runValues })).then((res) => {
            if (res.payload !== undefined) {
                dispatch(fetchSdbr())
                setStatusFilter('Sdbr run')
            }
        })
        setSelectedRowIndex([])
    }


    const handleRefresh = () => {
        setCustomerNameFilter('')
        setStatusFilter('Order Created')
        setFromDateFilter('');
        setToDateFilter('');
    }

    const dateChecker = (dateString) => {
        console.log('Critical');
        const [year, month, day] = dateString.split('-');

        // Convert day, month, and year to numbers
        const numericDay = parseInt(day, 10);
        const numericMonth = parseInt(month, 10) - 1; // Adjust for zero-based months
        const numericYear = parseInt(year, 10);

        const parsedDate = new Date(numericYear, numericMonth, numericDay);
        const today = new Date();

        if (today > parsedDate) {
            toast.error("Delivery date cannot be past date");
            return;
        }
    }


    useEffect(() => {
        dispatch(fetchSdbr());
    }, [dispatch]);

    useEffect(() => {
        setCustomerNameValues([...new Set(originalData.map(item => item.customerName))]
        .sort((a,b) => a.localeCompare(b))
    );
        setStatusValues(['Po Confirmed', 'Sdbr run', 'Order Created']);
        setData(originalData)
        applyFilters();

    }, [originalData])

    useEffect(() => {
        applyFilters();

    }, [customerNameFilter, statusFilter, fromDateFilter, toDateFilter]);


    return (
        <div className='sdbr'>
            {loading === 'pending' ?
                <Loader />
                :
                <>
                    <Button label={filterButton} onClick={onFilterClick} className='blue' />
                    <div className="Filter-drop" style={{ display: `${filterButton === 'Close' ? `block` : `none`}` }}>
                        <div className="filter-card">
                            <div className='sdbrFilterCont'>
                                <FilterComponent
                                    label="Customer"
                                    filterValue={customerNameFilter}
                                    options={customerNameValues}
                                    onFilterChange={setCustomerNameFilter}
                                />
                                <FilterComponent
                                    label="Order Status"
                                    filterValue={statusFilter}
                                    options={statusValues}
                                    onFilterChange={setStatusFilter}
                                />
                                <div></div>
                            </div>

                            <div className="filter-bottom">
                                <div className='DateCont'>
                                    <label className='DateHead'>Order Delivery Date</label>
                                    <div className="DateFilt">
                                        <div className="filterD">
                                            <label htmlFor="fromDateFilter">From</label>
                                            <input
                                                id="fromDateFilter"
                                                type="date"
                                                value={fromDateFilter}
                                                onChange={(e) => setFromDateFilter(e.target.value)}
                                            />
                                        </div>
                                        <div className="filterD">
                                            <label htmlFor="toDateFilter">To</label>
                                            <input
                                                id="toDateFilter"
                                                type="date"
                                                value={toDateFilter}
                                                onChange={(e) => setToDateFilter(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                    <img src={refresh} alt="refresh" onClick={handleRefresh} title='Refresh filters' />

                                </div>

                            </div>

                        </div>
                    </div>
                    {/* <div className="Update-multi">
                <div className="filterD">
                    <input
                        id="fromDateFilter"
                        type="date"
                        value=""
                    />
                    <Button label="Update Multiple Delivery Date" className="blue" />
                </div>

            </div> */}

                    <div className='head'>SDBR
                        <div className="bottom-button-container">
                            {/* <Button label="Cancel" className="blue" /> */}
                            {/* <Button label="Show Hours" className="blue" /> */}
                            {/* <Button label="Release Hours" className="blue" /> */}
                            {/* <Button label="Unconfirm PO" className="blue" /> */}
                            {/* <Button label="Plant Load" className="blue" /> */}
                            {/* <Button label="Excel Sequencing" className="blue" /> */}
                            {/* <Button label="" className="blue" /> */}
                            {permission.updatePermission === true ? <>
                                <Button label="Un Confirm PO" className="blue" onClick={() => handleUnConfirmPO()} disabled={selectedRowIndex} />
                                <Button label="Confirm PO" className="blue" onClick={() => handleConfirmPO()} disabled={selectedRowIndex} />
                                <Button label="RUN SDBR" className="blue" onClick={() => handleRunSdbr()} disabled={selectedRowIndex} />
                            </> : ''}
                        </div>
                    </div>
                    <SDBRTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} setSelectedRowIndex={setSelectedRowIndex} />
                </>}
        </div>
    );
};

export default SDBR
//End of File
