import React, { useContext } from 'react'

import ReadyBuildProdRecording from './ReadyBuildProdRecording';
import { UserAccessContext } from '../../App';
import SmartFabProdRecording from './SmartFabProdRecording';


const ProductionRecording = () => {

  const productName = useContext(UserAccessContext).productName;

  return (
    <>
      {
        productName === 'smartFab' ?
          <SmartFabProdRecording/>
          :
          <ReadyBuildProdRecording />
      }
    </>
  )
}

export default ProductionRecording