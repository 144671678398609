import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/styles/ElementCopy.css";
import Button from "../../../utils/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  copyOrderItems,
  fetchOrderItems,
} from "../../../redux-tookit/slices/orderItemsSlice";
import AGTable from "../../../components/AGTable";
import { fetchOrder } from "../../../redux-tookit/slices/orderSlice";
import { toast } from "react-toastify";
import ElementCopyTable from "./ElementCopyTable";

const ElementCopy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId } = useParams();

  const combId = location.state?.combId;

  const data = useSelector((state) => state.order.labeledData)

  let orderIdValues = data.map((item) => {
    return { orderId: item.orderId, combId: item.combId };
  })
  orderIdValues.sort((a, b) => a.combId.localeCompare(b.combId));

  const [fromOrderId, setFromOrderId] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState([]);

  const elementColumns = useSelector((state) => state.orderItems.columns2);
  const elementData = useSelector((state) => state.orderItems.labeledData);
  const [orderItems, setOrderItems] = useState(
    elementData.filter((item) => item.noOfTags != 0)
  );
  // const oldElementColumns = elementColumns.filter((column) => column.key !== 'orderId'&&column.key !== 'tag_print_status'&&column.key !== 'total_weight'&&column.key !== 'element_status');
  console.log(elementData);
  const [tempCopyData, setTempCopyData] = useState([]);

  const handleFromOrderChange = (e) => {
    setFromOrderId(e.target.value);
    setSelectedOrderId(e.target.value);
    console.log(e.target.value);
    const selectedOrder = data.find(
      (order) => `${order.orderId}` === e.target.value
    );
    setCustomerName(selectedOrder ? selectedOrder.soldToParty : "");
    setOrderItems(
      elementData
        .filter((item) => item.noOfTags != 0)
        .filter((item) => `${item.orderId}` === e.target.value)
    );
  };

  const handleCopyButtonClick = async () => {
    const tempCopyItems = [];

    if (selectedRowIndex.length !== 0) {
      selectedRowIndex.map((item) => {
        let temp = orderItems[item];
        temp = {
          ...temp,
          tagPrintStatus: "not printed",
          orderId: orderId * 1,
          orderItemName: `Copy of ${orderItems[item].orderItemName}`,
          oldName: orderItems[item].orderItemName,
        };
        tempCopyItems.push(temp);
      });

      // await dispatch(copyOrderItems(`${copyItems}`))
      //   .then((res)=>{console.log(res);})
      setTempCopyData(tempCopyItems);
      toast.success("Elements selected for copy");
    } else toast.error("No Elements selected.");
    console.log(tempCopyItems);
  };

  const handleClearButtonClick = () => {
    const windowResponse = window.confirm(
      "Are you sure you want to Clear the copied Elements"
    );
    if (windowResponse) setTempCopyData([]);
  };

  const handleOkButtonClick = async () => {
    if (tempCopyData.length !== 0) {
      const finalData = [];
      tempCopyData.map((data) => {
        const newData = {};
        Object.keys(data).map((value) => {
          if (value !== "oldName") newData[value] = data[value];
        });
        finalData.push(newData);
      });
      console.log(tempCopyData);

      dispatch(copyOrderItems(finalData)).then((res) => {
        navigator(res.payload);
      });
    }
  };

  const navigator = (payload) => {
    if (payload !== undefined) {
      setTimeout(() => {
        navigate(`/element/${orderId}`, { state: { combId: combId, orderId: orderId } });
      }, 700);
    }
  };

  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
  };

  const handleNameChange = (value, index) => {
    setTempCopyData((prev) => {
      const newData = [...prev];
      newData[index].orderItemName = value;
      return newData;
    });
  };

  useEffect(() => {
    dispatch(fetchOrderItems());
    dispatch(fetchOrder());
  }, [dispatch]);

  useEffect(() => {
    setOrderItems(elementData.filter((item) => item.noOfTags != 0));
  }, [elementData]);

  return (
    <div className="sdbr">
      <div className="head">Element Copy</div>
      <div className="card1 copyPage" style={{ padding: "15px 20px" }}>
        <div className="order-details">
          <div style={{ width: "40%" }}>Customer Name: {customerName}</div>

          <div className="order-dropdown" style={{ marginBottom: "3px" }}>
            <label
              htmlFor="soldToParty"
              style={{ marginLeft: "150px", position: "fixed" }}
            >
              Order Id{" "}
            </label>
            <select
              name="soldToParty"
              id="soldToParty"
              onChange={(e) => handleFromOrderChange(e)}
            >
              <option value="" hidden>
                -Select an Order-
              </option>
              {orderIdValues.map((value, index) => (

                
                  <option key={index} value={value.orderId}>
                    {value.combId}
                    {/* _{data[index].createdTs} */}
                  </option> 
              ))}
            </select>
          </div>
          <Button
            label="Copy"
            className="blue"
            onClick={() => handleCopyButtonClick()}
            disabled={selectedRowIndex}
          />
        </div>

        <div className="element">
          <AGTable
            columns={elementColumns}
            data={orderItems}
            onSelectRow={handleRowSelect}
            rowSelection={"multiple"}
            height={200}
          />
        </div>

        <div className="copy"></div>
      </div>

      <div className="card1 copyPage">
        <div className="order-details" style={{ paddingBottom: "5px" }}>
          Copy to Order Id: {combId}
        </div>
        <ElementCopyTable
          data={tempCopyData}
          handleNameChange={handleNameChange}
        />
        <div className="copy">
          <Button
            label="OK"
            className="blue"
            onClick={() => handleOkButtonClick()}
            disabled={tempCopyData}
            message={"No Elements Copied"}
          />
          <Button
            label="Clear"
            className="blue"
            onClick={() => handleClearButtonClick()}
            disabled={tempCopyData}
            message={"No data to Clear"}
          />
          <Button
            label="Exit"
            className="blue"
            onClick={() => navigate(`/order details`)}
          />
        </div>
      </div>
    </div>
  );
};

export default ElementCopy;
//End of File
