import React from 'react';
import { Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Compressor from 'compressorjs';
import HeaderRight from '../../assets/images/icons/readybuildCompressed.jpg';
import font2 from "../../assets/fonts/NotoSansSymbols-Regular.ttf"
import font3 from "../../assets/fonts/NotoSansSymbols2-Regular.ttf"
import font4 from "../../assets/fonts/Quivira-A8VL.ttf"
import font5 from "../../assets/fonts/Roboto-Bold.ttf"
import QRCode from 'qrcode';
import BbsTabular from './BbsTabular';

const serviceCenterName = sessionStorage.getItem('serviceCenterName');
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
  tag: {
    height: '20%',
    width: '50%',
    padding: '0.5%',
    margin: '0.5%',
    boxSizing: 'border-box',
    border: '2px solid black',
    borderRadius: 7
  },
  content: {
    fontSize: '8'
  },
});

const dataURLToBlob = async(dataURL) => {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

const compressUrl = async(url, convertSize = 1000, maxHeight = 300) => {
  const blob = await dataURLToBlob(url);

  // Compress the QR code image
  return new Promise((resolve, reject) => {
    new Compressor(blob, {
      quality: 0.0, convertSize, maxHeight, // Adjust the quality as needed
      success(result) {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      },
      error(err) {
        console.error('Error compressing QR Code:', err);
        reject(url);
      },
    });
  });
}

const genQRCode = async (data) => {
  try {
    const url = await QRCode.toDataURL(data);

    return await compressUrl(url)

    // return url;
  } catch (error) {
    console.error('Error generating QR Code:', error);
    return '';
  }
};
//##############################################################
//###################################
const Heading1 = (props) => {
  const { oI, serviceCenterLogo } = props;
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <View style={{ width: '20%', height: '100%' }}>
        {
          serviceCenterLogo != null && serviceCenterLogo !== '' ?
            <Image src={serviceCenterLogo} style={{ height: '22px', border: '1px solid black' }}></Image>
            : <Text style={{ fontSize: '10', border: '1px solid black' }}>{serviceCenterName}</Text>
        }
      </View>
      <View style={{ flexDirection: 'column', fontSize: '8', gap: '5px' }}>
        <Text style={{ fontFamily: 'OpenSans-Bold' }}>Order Id: {oI.combId || ''}</Text>
        <Text style={{ fontFamily: 'OpenSans-Bold' }}>{oI.soldToParty || ''}</Text>
      </View>
      <Image src={HeaderRight} style={{ height: '22px' }}></Image>
    </View>
  )
}

const Select = (props) => {
  const { el, index, ind = 0, oI, bundleData, uniIcon, serviceCenterLogo, bundleTrack, lengthDeduction } = props;
  Font.register({
    family: "OpenSans-Bold", format: "truetype", src: font5
  });
  Font.register({
    family: "NotoSansSymbols-Regular", format: "truetype", src: font2
  });
  Font.register({
    family: "NotoSansSymbols2-Regular", format: "truetype", src: font3
  });
  Font.register({
    family: "Quivira-A8VL", format: "truetype", src: font4
  });
  // console.log(bundleData.weight, el.productionWeight);
  const splitStringIntoChunks = (str) => {
    let chunkSize = 2
    const chunks = [];
    for (let i = 0; i < str.length; i += chunkSize) {
      chunks.push(str.slice(i, i + chunkSize));
    }
    return chunks;
  };
  const url = String.fromCharCode.apply(null, new Uint8Array(el.image.data));
  const data = `CUSTOMER:${oI.soldToParty || ''}
DLV ADDR:${oI.address || ''}
ORDER DATE:${oI.createdTs || ''}
SUPPLIED FROM:${serviceCenterName || ''}
QTY ORDERED:${el.bundleQuantity || ''}Nos
QTY BUNDLED:${el.totalNoOfPieces || ''}Nos
Weight:${bundleData.weight || ''}T
GRADE/BAR TYPE/DIA:${el.gradeName || ''}/T/${el.diameter || ''}
SCANNER INPUT:${el.partItemId || ''},${bundleData.bundleId || ''},${oI.orderId || ''}`;
  // console.log(uniIcon);
  // console.log(props);
  // #SUPPLIED FROM: Test
  return (
    <View wrap={false} style={{
      height: '19%',
      width: '49%',
      padding: '0.5%',
      margin: '0.5%',
      // boxSizing: 'border-box',
      border: '2px solid black',
      borderRadius: 7,
      // marginTop: `${ind - 1 % 10 === 0 || ind - 1 % 10 === 1 ? '0.5%' : '1%'}`,
    }}>
      <Heading1 el={el} oI={oI} serviceCenterLogo={serviceCenterLogo} />

      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '70%' }}>
        <LeftContent el={el} i={index} bundleData={bundleData} ind={ind} oI={oI} lengthDeduction={lengthDeduction} />
        <View style={{ width: '80px', height: '80%', marginTop: '5pt', zIndex: '9' }}>
          <Image src={genQRCode(data)} style={{ width: '100%' }} />
        </View>
        <View style={{ width: '45%', height: '80%', overflow: 'hidden', border: '1px solid black' }}>
          <Image src={url} style={{ width: '100%' }}></Image>
        </View>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', height: '15%', fontSize: '8' }}>
        <View style={{ flexDirection: 'column', flexGrow: '1', gap: '3px', fontSize: '8px', width:'60%' }}>
          <View style={{ height: '11px', width: '90%', flexDirection: 'row' }}>
            <Text style={{ fontSize: '8' }}>Tag Desc: </Text><Text style={{ fontFamily: 'OpenSans-Bold', width:'80%' }}>{splitStringIntoChunks(el.tagDescription) || ''}</Text>
          </View>
          <View style={{ height: '11px', width: '90%', flexDirection: 'row' }}>
            <Text style={{ fontSize: '8' }}>Remarks: </Text><Text style={{ fontFamily: 'OpenSans-Bold',  width:'80%'  }}>{splitStringIntoChunks(oI.orderRemarks) || ''}</Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', gap: '3'}}>
          {
            <View style={{ height: '120%', alignSelf: 'center', marginRight: '2' }}>
              <Text style={{ marginRight: '2' }}>
                {`Tag No: ${ind}/${bundleTrack || ''}
${(el.halfThread || el.fullThread) ? `HT/FT:
${el.halfThread * el.totalNoOfPieces || 0}/${el.fullThread * el.totalNoOfPieces || 0}` : ''}`}</Text>
            </View>}
          <View style={{ flexDirection: 'row', border: '1px solid black' }}>
            <View style={{ height: '150%' }}>
              <View style={{ flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', height: '40px', width: '30px' }}>
                {uniIcon === '2690' ?
                  <Text style={{ fontSize: '12px', fontFamily: 'NotoSansSymbols-Regular' }}>
                    {String.fromCharCode(parseInt(uniIcon, 16))}
                  </Text>
                  : <Text style={{ fontSize: '12px', fontFamily: 'NotoSansSymbols2-Regular', marginTop: '2px' }}>
                    {String.fromCharCode(parseInt(uniIcon, 16))}
                  </Text>
                }
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}
const LeftContent = (props) => {
  const { el, bundleData, ind, lengthDeduction } = props;
  Font.register({
    family: "OpenSans-Bold", format: "truetype", src: font5
  });
  const splitStringIntoChunks = (str) => {
    let chunkSize = 11
    const chunks = [];
    for (let i = 0; i < str.length; i += chunkSize) {
      chunks.push(str.slice(i, i + chunkSize));
    }
    return chunks;
  };
  return (
    <View style={{ flexDirection: 'column', fontSize: '8', gap: '3px', width: '35%', alignSelf: 'flex-start' }}>
      <View style={{ flexDirection: 'row', height: '10%' }}>
        <View style={{ height: '120%', }}>
          <View style={{ height: '20px', zIndex: '10', width: '320%', flexDirection: 'row' }}><Text>Element: </Text><Text style={{ fontSize: '8', fontFamily: 'OpenSans-Bold', width: "80%" }}>{el.orderItemName || ''} </Text></View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', height: '10%' }}>
        <View style={{ height: '120%' }}>
          <View style={{ height: '20px', zIndex: '10', width: '230%', flexDirection: 'row', overflow: 'hidden', alignItems: 'center' }}><Text>BM:  </Text><Text style={{fontFamily: 'OpenSans-Bold', fontSize: '10', width: "38%" }}>{splitStringIntoChunks(el.barMarkId) || ''} </Text></View>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flexDirection: 'row', width: '40%', alignItems: 'center' }}>
          <Text >Dia: </Text><Text style={{ fontFamily: 'OpenSans-Bold', fontSize: '10' }}>{el.diameter || ''}</Text>
        </View>
        <View style={{ flexDirection: 'row', width: '60%', alignItems: 'center' }}>
          <Text >Len: </Text><Text style={{ fontFamily: 'OpenSans-Bold', fontSize: '10' }}>{(lengthDeduction ? el.productionLength : el.inventoryLength) || ''}</Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ border: '1px solid black', height: '20px', width: '30%', marginRight: '9px', padding: '0 2px' }}>
          <Text>Punch Here </Text>
        </View>
        <View style={{ flexDirection: 'column', gap: '2px' }}>
          <Text style={{ fontSize: '7' }}>Threads: {el.threads / el.totalNoOfPieces * bundleData.noOfPieces || 0}</Text>
          <Text style={{}}>Pin: <Text style={{}}>{el.pinDia || ''}</Text> </Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <Text style={{ width: '40%', fontSize: '8' }}>Pg: {Math.floor((ind - 1) / 10 + 1)}</Text>
        <Text style={{ width: '60%' }}>Wt: <Text style={{ fontFamily: 'OpenSans-Bold' }}>{Math.floor(bundleData.weight * 1000) / 1000} T</Text></Text>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text>Qty:</Text><Text style={{ fontSize: '10', fontFamily: 'OpenSans-Bold' }}>{bundleData.noOfPieces}({el.totalNoOfPieces || ''})</Text>
      </View>
      <View><Text style={{ fontSize: '8' }}>Grade: <Text style={{ fontSize: '6' }}>{el.gradeName || ''}</Text></Text></View>
    </View>
  )
}
// Create Document Component
const MyDocument = (props) => {
  const { partItem, type = 'bbs tabular', bundleData, orderInfo, parties, diaValues, serviceCenterLogo, lengthDeduction } = props;
  Font.register({
    family: "NotoSansSymbols-Regular", format: "truetype", src: font2
  });
  Font.register({
    family: "NotoSansSymbols2-Regular", format: "truetype", src: font3
  });
  Font.register({
    family: "OpenSans-Bold", format: "truetype", src: font5
  });
  let orderItemCount = 0
  let tagCount = 0

  const compressedScLogo = compressUrl(serviceCenterLogo, 200, 250);

  const charList = [
    '2690',
    '2742',
    '275B',
    '25A0', '25A1', '25B2', '25B3', '25CF', '25CB', '2680',
    '2687', '2689', '268A', '268B', '268C', '268D', '268E', '268F', '26D2', '2700',
    '2710', '2751', '275D', '2794', '29BF', '2B12', '2B19', '2B1F', '2B20', '2B22',
    '2B85', '2B89', '2B8C', '2B90', '2B94', '2B98', '2B9C', '2BB0', '2BD2', '2BD3',
    '2BD4', '2BD5', '2BD6', '2BD7', '2BD8', '2BD9', '2BDA', '2BDB', '2BDC', '2BDD',
    '2BDE', '2BDF', '2BE0', '2BE1', '2BE3', '2BE4', '2BE5', '2BE6', '2BE7', '2BEC',
    '2BF4',
  ]
  // console.log(type);
  return (
    <>
      {type === 'tag' ?
        <Page size="A4" style={styles.page}>
          {partItem.map((element, index) => {
            const uniIcon = charList[index !== 0 ? partItem[index].orderItemId !== partItem[index - 1].orderItemId ? ++orderItemCount : orderItemCount : 0];
            const filteredData = bundleData.filter(item => item.partItemId === element.partItemId);
            let bundleTrack = filteredData.length
            return filteredData.map((data, ind) => {
              tagCount++
              return <Select el={element} index={index} bundleTrack={bundleTrack--} ind={tagCount} oI={orderInfo} bundleData={data}
                serviceCenterLogo={compressedScLogo} key={index}
                uniIcon={uniIcon} lengthDeduction={lengthDeduction}
              />
            }
            )
          }
          )}
          {/* {partItem.map((element, index) => (
            <Select el={element} index={index} oI={orderInfo} bundleData={bundleData}
              serviceCenterLogo={serviceCenterLogo} key={index}
              uniIcon={charList[index !== 0 ? partItem[index].orderItemId !== partItem[index - 1].orderItemId ? ++orderItemCount : orderItemCount : orderItemCount]}
            />
          ))} */}
        </Page> :
        type === 'bbs tabular' ?
          <BbsTabular parties={parties} partItem={partItem} orderInfo={orderInfo} diaValues={diaValues} serviceCenterLogo={serviceCenterLogo} lengthDeduction={lengthDeduction} />
          : ""}
    </>
  )
}
export default MyDocument