import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../utils/Button';
import React, { useState, useEffect, useContext } from 'react'
import { fetchRmInventory, fetchRmInventorySF, postMultiInventory, postMultiInventorySF, postRmInventory, postRmInventorySF, updateRmInventory, updateRmInventorySF } from '../../../redux-tookit/slices/inventorySlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import MultipleAddStock from './MultipleAddStock';
import { UserAccessContext } from '../../../App';

const A1 = (props) => {

    const dispatch = useDispatch();
    const productName = useContext(UserAccessContext).productName
    const { isAdding, setIsAdding, isEditing, setIsEditing, editData, setEditData, onSelectRow } = props
    const columns = useSelector((state) => state.inventory.columns);
    const gradeMasterData = useSelector((state) => state.gradeMaster.labeledData);

    console.log(columns);
    

    const columnKeys = Object.keys(columns);

    const [tempArray, setTempArray] = useState(
        productName==='smartFab'?
        [{ 'productName': '', 'diameter': '', 'material': '', 'invoiceWeight': '', 'batchNo': '', 'noOfPieces': '', 'description': '' }]
        :
        [{ 'gradeName': '', 'diameter': '', 'material': '', 'stockType': '', 'invoiceWeight': '', 'batchNo': '', 'noOfPieces': '', 'description': '' }]

    )
    const [multiAddActive, setMultiAddActive] = useState(false)


    const [f, setF] = useState(true);

    const getCurrentDateTime = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0')
        return `${year}-${month}-${day}T${hours}:${minutes}`
    }

    const [grossWeight, uGrossWeight] = useState(0);
    const [tareWeight, uTareWeight] = useState(0);
    const [netWeight, uNetWeight] = useState(0);
    const [totalPieces, uTotalPieces] = useState(0);
    const [minLength, uMinLength] = useState(0);
    const [maxLength, uMaxLength] = useState(0);
    const [newRow, setNewRow] = useState({ batchNo: '', grossWeightTs: getCurrentDateTime(), unloadedTs: getCurrentDateTime() });
    const [gradeDrop, uGradeDrop] = useState({});
    const [multiAdd, setMultiAdd] = useState(false);

    console.log(newRow);

    const handleInputChange = (key, e) => {
        const updatedData = JSON.parse(JSON.stringify(editData));
        updatedData[key] = e.target.value;
        setEditData(updatedData);
        if (key === 'unloadedTs') {
            const inputDate = new Date(e.target.value);
            const otherDate = new Date(editData.grossWeightTs);
            if (inputDate < otherDate) {
                setF(false);
                toast.error("Error: Gross Weight Timestamp should not be greater than Unloading Timestamp");
                return;
            } else {
                setF(true);
            }

        }
        if (key === 'grossWeightTs') {
            const inputDate = new Date(e.target.value);
            const otherDate = new Date(editData.unloadedTs);
            if (inputDate > otherDate) {
                setF(false);
                toast.error("Error: Gross Weight Timestamp should not be greater than Unloading Timestamp");
                return;
            } else {
                setF(true);
            }
        }


    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (editData.unloadedTs < editData.grossWeightTs) {
            toast.error("Error: Gross Weight Timestamp should not be greater than Unloading Timestamp");
            return;
        }
        // if (editData.tierWeight >= editData.grossWeight) {
        //     toast.error('Gross weight should always be greater than tare weight');
        //     return;
        // }

        let tempData = {};

        if (editData.grossWeight == 0 || editData.grossWeight === null) {
        } else {
            tempData = { ...tempData, grossWeightTs: editData.grossWeightTs }
        }

        if (editData.tierWeight == 0 || editData.tierWeight === null) {
        } else {
            tempData = { ...tempData, unloadedTs: editData.unloadedTs }
        }

        const id = editData.rmReceiptId;
        const type = {
            vehicleNo: editData.vehicleNo,
            invoiceNumber: editData.invoiceNumber,
            invoiceType: editData.invoiceType,
            grossWeight: editData.grossWeight,
            tierWeight: editData.tierWeight,
            batchNo: editData.batchNo === '' ? ' ' : editData.batchNo,
            gradeId: editData.gradeId,
            invoiceWeight: editData.invoiceWeight,
            noOfPieces: editData.noOfPieces,
            description: editData.description,
            netWeight: editData.netWeight * 1,
            stockType: editData.materialType,
            minLength: editData.minLength,
            maxLength: editData.maxLength,
            ...tempData
            // grossWeightTs: editData.grossWeight == 0 || editData.grossWeight === null ? '' : editData.grossWeightTs,
            // unloadedTs: editData.tierWeight == 0 || editData.tierWeight === null ? '' : editData.unloadedTs,
        };
        if (productName === 'smartFab') {
            dispatch(updateRmInventorySF({ id, type })).then((res) => {
                if (res.payload !== undefined)
                    dispatch(fetchRmInventorySF())
            });
        } else {
            dispatch(updateRmInventory({ id, type })).then((res) => {
                if (res.payload !== undefined)
                    dispatch(fetchRmInventory())
            });
        }

        onSelectRow('A')
        setIsEditing(false)

    }

    const onBlurChangeHandle3 = async (value) => {

        if (editData.tierWeight * 1 > 0 && editData.grossWeight * 1 > 0) {
            const netW = value * 1 - editData.tierWeight * 1;
            const length = (editData.minLength * 1 + editData.maxLength * 1) / 2;
            const totalPiece = Math.floor(netW * 4000000000000 / (length * Math.PI * 7850 * editData.diameter * editData.diameter));
            setEditData({ ...editData, noOfPieces: totalPiece });
            setEditData({ ...editData, netWeight: netW });

        } else if (editData.invoiceWeight > 0) {
            const netW = editData.invoiceWeight;
            const length = (editData.minLength * 1 + editData.maxLength * 1) / 2;
            const totalPiece = Math.floor(netW * 4000000000000 / (length * Math.PI * 7850 * editData.diameter * editData.diameter));
            setEditData({ ...editData, noOfPieces: totalPiece });
            setEditData({ ...editData, netWeight: netW });
        }
    }

    const onBlurChangeHandle2 = async (value) => {
        if (editData.tierWeight * 1 > 0 && editData.grossWeight * 1 > 0) {
            if (editData.grossWeight * 1 > editData.tierWeight * 1) {
                const netW = editData.grossWeight * 1 - value * 1;
                const length = (editData.minLength * 1 + editData.maxLength * 1) / 2;
                const totalPiece = Math.floor(netW * 4000000000000 / (length * Math.PI * 7850 * editData.diameter * editData.diameter));
                const tempItem = {
                    noOfPieces: totalPiece,
                    netWeight: netW
                }
                setEditData({ ...editData, ...tempItem });
            } else {
                const tempItem = {
                    noOfPieces: "",
                    netWeight: ""
                }
                setEditData({ ...editData, ...tempItem });
                toast.error('Tare weight should be less than the Gross weight')
            }

        } else if (editData.invoiceWeight > 0) {
            const netW = editData.invoiceWeight;
            const length = (editData.minLength * 1 + editData.maxLength * 1) / 2;
            const totalPiece = Math.floor(netW * 4000000000000 / (length * Math.PI * 7850 * editData.diameter * editData.diameter));
            setEditData({ ...editData, noOfPieces: totalPiece });
            setEditData({ ...editData, netWeight: netW });
        }
    }

    const handleInvoiceWeight = (e) => {
        if (editData.tierWeight * 1 > 0 || editData.grossWeight * 1 > 0) {
        } else {
            const netW = e.target.value;
            const length = (editData.minLength * 1 + editData.maxLength * 1) / 2;
            const totalPiece = Math.floor(netW * 4000000000000 / (length * Math.PI * 7850 * editData.diameter * editData.diameter));
            const temp = { netWeight: netW, invoiceWeight: netW, noOfPieces: totalPiece }
            // setEditData({ ...editData, noOfPieces: totalPiece });
            setEditData({ ...editData, ...temp });
        }

    }

    const onBlurChangeHandle = async (material = null, weight = null) => {
        if (grossWeight * 1 > 0 && tareWeight * 1 > 0) {


            const netW = grossWeight * 1 - tareWeight * 1;
            let length = 0
            let materialName = material;
            if (material === null) {
                materialName = gradeDrop.material
            }
            gradeMasterData.map(el => {
                if (el.material === materialName) { length = (el.minLength + el.maxLength) / 2; }
            })

            uNetWeight(netW);
            if (gradeDrop.materialType === 'COIL') {
                return
            }
            const totalPiece = Math.floor(netW * 4000000000000 / (length * Math.PI * 7850 * gradeDrop.diameter * gradeDrop.diameter));
            uTotalPieces(totalPiece);
            setNewRow({ ...newRow, netWeight: netW });
            if (grossWeight * 1 <= tareWeight * 1) {
                toast.error('Gross weight should always be greater than tare weight')
                return
            }
            setNewRow({ ...newRow, noOfPieces: totalPiece });
        } else {
            if (weight !== null) {

                const netW = weight;
                let length = 0
                let materialName = material;


                materialName = gradeDrop.material

                gradeMasterData.map(el => {
                    if (el.material === materialName) { length = (el.minLength + el.maxLength) / 2; }
                })

                uNetWeight(netW);
                if (gradeDrop.materialType === 'COIL') {
                    return
                }
                const totalPiece = Math.floor(netW * 4000000000000 / (length * Math.PI * 7850 * gradeDrop.diameter * gradeDrop.diameter));
                uTotalPieces(totalPiece);
                setNewRow({ ...newRow, netWeight: netW });

                setNewRow({ ...newRow, noOfPieces: totalPiece });
            }
        }
    }

    const findGradeId = (data, material, gradeName, diameter) => {

        let foundId 
        if(productName!=='smartFab'){
         foundId = data.find((item) => {
            return item.material === material && item.gradeName === gradeName && item.diameter == diameter;
        });   
        } else {
            foundId = data.find((item) => {
                //gradeName = productName
                return item.material === material && item.productName === gradeName && (!diameter || item.diameter == diameter);
            });   
        }
        

        if (foundId) {
            if(productName!=='smartFab'){
            return foundId.gradeId;}
            else return foundId
        }

        else {
            return null;
        }
    }

    const formatDate = (dateString) => {
        // Extract date components
        if (dateString === null) return null;
        const match = dateString.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\s*(AM|PM)$/);

        if (!match) {
            return null;
        }

        // Extract components from the matched groups
        const [, year, month, day, hours, minutes, seconds, ampm] = match;

        // Convert hours to 24-hour format
        let formattedHours = parseInt(hours, 10);

        if (ampm === 'PM' && formattedHours !== 12) {
            formattedHours += 12;
        } else if (ampm === 'AM' && formattedHours === 12) {
            formattedHours = 0;
        }

        // Create a new Date object
        const formattedDate = new Date(year, month - 1, day, formattedHours, minutes);

        // Check if the date is valid
        if (isNaN(formattedDate.getTime())) {
            console.error('Invalid date format');
            return null;
        }

        // Format the date to YYYY-MM-DDTHH:mm
        const result = formattedDate.toISOString().slice(0, 16);

        return result;
    };

    const handleSaveButtonClick = async (e) => {
        e.preventDefault();
        if (multiAdd === true) {
            let finalMultiJSON = [];
            const sampleArray = ['stockType', 'gradeId', 'invoiceWeight', 'batchNo', 'noOfPieces', 'description', 'minLength', 'maxLength']
            tempArray.forEach(data => {
                let rowData = {}
                sampleArray.forEach(header => {
                    if (header === 'gradeId') {
                        if(productName!=='smartFab'){
                            const gradeId = findGradeId(gradeMasterData, data.material, data.gradeName, data.diameter);
                            rowData = { ...rowData, [header]: gradeId }
                        } else{
                            if (data.productName.includes("MS")) {
                                const grade = findGradeId(gradeMasterData, data.material, data.productName);
                                rowData = { ...rowData, material: grade.material, smartFabProductTypeId: grade.smartFabProductTypeId, diameter: data.diameter }
                            } else{
                                const grade = findGradeId(gradeMasterData, data.material, data.productName, data.diameter);
                                rowData = { ...rowData, material: grade.material, smartFabProductTypeId: grade.smartFabProductTypeId, diameter: grade.diameter, gradeId: grade.gradeId }
                            }

                        }
                                            
                        
                    } else {
                        rowData = { ...rowData, [header]: data[header] }
                    }
                })
                rowData = { ...rowData, tolerance: 0 }
                finalMultiJSON.push(rowData);
            })
            const newRow1 = {
                ...newRow,
                addMultiple: finalMultiJSON
            }
            console.log(newRow1);
            if (productName === 'smartFab') {
                dispatch(postMultiInventorySF({ ...newRow1 })).then((res) => {
                if (res.payload !== undefined)
                    dispatch(fetchRmInventorySF())
            });
            } else {

                console.log(newRow1);
                
                dispatch(postMultiInventory({ ...newRow1 })).then((res) => {
                if (res.payload !== undefined)
                    dispatch(fetchRmInventory())
                });
            }

        } else {
            const inputDate = new Date(newRow.unloadedTs);
            const otherDate = new Date(newRow.grossWeightTs);
            if (inputDate < otherDate) {
                toast.error("Error: Gross Weight Timestamp should not be greater than Unloading Timestamp");
                return;
            }
            // if (newRow.tierWeight > newRow.grossWeight) {
            //     toast.error('Gross weight should always be greater than tare weight');
            //     return;
            // }
            // if (grossWeight <= 0) {
            //     toast.error('Gross weight should always be greater than 0');
            //     return;
            // }

            if (grossWeight === 0 || grossWeight === null) {
                setNewRow({ ...newRow, grossWeightTs: '' })
            }

            if (tareWeight === 0 || tareWeight === null) {
                setNewRow({ ...newRow, unloadedTs: '' })
            }


            let gradeId 

            if(productName!=='smartFab'){
                 gradeId = findGradeId(gradeMasterData, gradeDrop.material, gradeDrop.gradeName, gradeDrop.diameter);
            } else{
                 gradeId = findGradeId(gradeMasterData, gradeDrop.material, gradeDrop.productName, gradeDrop.diameter);
            }

            const newRow1 = {
                ...newRow,
                gradeId: gradeId,
                stockType: gradeDrop.materialType, //new added attribute .......................................<<<
                grossWeight: grossWeight,
                tierWeight: tareWeight,
                noOfPieces: totalPieces || 0,
                netWeight: netWeight
            }

            
            if (productName === 'smartFab') {
                dispatch(postRmInventorySF({ ...newRow1 })).then((res) => {
                if (res.payload !== undefined)
                    dispatch(fetchRmInventorySF())
            });
            } else {
                dispatch(postRmInventory({ ...newRow1 })).then((res) => {
                if (res.payload !== undefined)
                    dispatch(fetchRmInventory())
            });
            }

            
        }

        setIsAdding(false);
        setNewRow({});
    };




    useEffect(() => {
        uGrossWeight(0);
        uTareWeight(0);
        uNetWeight(0)
        uTotalPieces(0);
        uMaxLength(0);
        uMinLength(0);
    }, [isAdding]);

    const handleMultiAdd = (value) => {
        setMultiAdd(value)
        if (value === true) {
            setNewRow({
                vehicleNo: newRow.vehicleNo,
                invoiceNumber: newRow.invoiceNumber,
                invoiceType: newRow.invoiceType,
                addMultiple: []
            })
        } else {
            setNewRow({
                batchNo: '',
                grossWeightTs: getCurrentDateTime(),
                unloadedTs: getCurrentDateTime(),
                vehicleNo: newRow.vehicleNo,
                description: newRow.description,
                invoiceNumber: newRow.invoiceNumber,
                invoiceType: newRow.invoiceType
            })
        }
    }

    const handleNewMultiAdd = () => {
        setMultiAddActive(true)
        // setNewRow((prev) => {
        //     let multiArray = JSON.parse(JSON.stringify(newRow.addMultiple))
        //     multiArray.push({'gradeId':'', 'invoiceWeight':'', 'batchNo':'', 'noOfPieces':'', 'description':''})
        //     return { ...prev, addMultiple: multiArray }
        // })
    }

    return (
        <div>
            <form onSubmit={handleSaveButtonClick}>{isAdding &&
                (multiAdd !== true ?
                    <div>
                        <div className="AddContainer" >
                            <div className="form-container">
                                <div className="head">Add Stock
                                    <div className="bottomMultiDel" style={{ fontSize: '15px', color: 'black', alignContent: 'flex-end' }}>
                                        <input type="checkbox" id="multiAdd"
                                            checked={multiAdd === true}
                                            onClick={(e) => { handleMultiAdd(!multiAdd) }} />
                                        <label htmlFor="multiAdd">Multiple Add</label>
                                    </div>
                                </div>
                                <div className="table-container">


                                    {columnKeys.map((column) => (
                                        column !== 'barType' && column !== 'rmReceiptId' && column !== 'materialType' && column !== 'gradeId' && column !== 'rmReceiptId' && column !== 'rmReceiptId' && column !== 'noOfPieces' && column !== 'unloadedTs' && column !== 'grossWeightTs' ?
                                            (column === 'gradeName' || column === 'smartFabProductType' ?
                                                ( productName!=='smartFab'?
                                                    <> 
                                                        <div className="filter" >
                                                            <label>
                                                                Stock type
                                                                {/* {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>} */}
                                                            </label>
                                                            <select
                                                                id="materialType"
                                                                // required
                                                                value={gradeDrop.materialType}
                                                                onChange={(e) => {
                                                                    uGradeDrop({ ...gradeDrop, materialType: e.target.value, material: '', gradeName: '' });
                                                                    uTotalPieces('');
                                                                }}
                                                            >

                                                                <option value=''>Select</option>
                                                                {[...new Set(gradeMasterData
                                                                    // .filter((item) => item.gradeName === gradeDrop.gradeName && item.diameter * 1 === gradeDrop.diameter * 1)
                                                                    .map(item => item.materialType))].sort((a, b) => a - b).map((value, index) => (
                                                                        <option key={index} value={value}>
                                                                            {value}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                        <div className="filter">
                                                            <label>
                                                                {columns[column].label}
                                                                {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                            </label>
                                                            <select
                                                                id="gradeId"
                                                                required
                                                                value={gradeDrop.gradeName}
                                                                onChange={(e) => {
                                                                    uGradeDrop({ ...gradeDrop, [column]: e.target.value, diameter: '', material: '' });
                                                                    uTotalPieces('');
                                                                }}
                                                            >

                                                                <option value=''>Select</option>
                                                                {[...new Set(gradeMasterData.filter(item => item.materialType === gradeDrop.materialType)
                                                                .map(item => item.gradeName))]
                                                                .map((value, index) => (
                                                                    <option key={index} value={value}>
                                                                        {value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                    </>:
                                                    <>
                                                    <div className="filter" >
                                                            <label>
                                                                Product type
                                                                {/* {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>} */}
                                                            </label>
                                                            <select
                                                                id="productType"
                                                                // required
                                                                value={gradeDrop.productName}
                                                                onChange={(e) => {
                                                                    uGradeDrop({ ...gradeDrop, productName: e.target.value, material: '', gradeName: '' });
                                                                    uTotalPieces('');
                                                                }}
                                                            >

                                                                <option value=''>Select</option>
                                                                {[...new Set(gradeMasterData
                                                                    // .filter((item) => item.gradeName === gradeDrop.gradeName && item.diameter * 1 === gradeDrop.diameter * 1)
                                                                    .map(item => item.productName))].sort((a, b) => a - b).map((value, index) => (
                                                                        <option key={index} value={value}>
                                                                            {value}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    </>
                                                ) :
                                                column === 'diameter' ?
                                                    (
                                                        <> {productName!=='smartFab'?
                                                            <div className="filter">
                                                                <label>
                                                                    {columns[column].label} (mm)
                                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                                </label>
                                                                <select
                                                                    id="diameter"
                                                                    required
                                                                    value={gradeDrop.diameter}
                                                                    onChange={(e) => {
                                                                        uGradeDrop({ ...gradeDrop, [column]: e.target.value, material: '' });

                                                                        uTotalPieces('');
                                                                    }}
                                                                >
                                                                    <option value=''>Select</option>

                                                                    {[...new Set(gradeMasterData
                                                                    .filter((item) => item.gradeName === gradeDrop.gradeName && item.materialType === gradeDrop.materialType)
                                                                    .map(item => item.diameter))].sort((a, b) => a - b).map((value, index) => (
                                                                        <option key={index} value={value}>
                                                                            {value}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            :<div className="filter">
                                                            <label>
                                                                {columns[column].label} (mm)
                                                                {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                            </label>
                                                            {
                                                            gradeDrop.productName?.includes('MS')?
                                                            <input type="number"
                                                            className='table-input'
                                                            id="diameter"
                                                            required
                                                            min={2}
                                                            max={10}
                                                            step={0.01}
                                                            value={gradeDrop.diameter}
                                                            onChange={(e) => {
                                                                uGradeDrop({ ...gradeDrop, [column]: e.target.value, material: '' });

                                                                uTotalPieces('');
                                                            }}
                                                             />
                                                            
                                                            :
                                                            <select
                                                                id="diameter"
                                                                required
                                                                value={gradeDrop.diameter}
                                                                onChange={(e) => {
                                                                    uGradeDrop({ ...gradeDrop, [column]: e.target.value, material: '' });

                                                                    uTotalPieces('');
                                                                }}
                                                            >
                                                                <option value=''>Select</option>

                                                                {[...new Set(gradeMasterData
                                                                .filter((item) => item.productName === gradeDrop.productName)
                                                                .map(item => item.diameter)
                                                            )]
                                                                .sort((a, b) => a - b).map((value, index) => (
                                                                    <option key={index} value={value}>
                                                                        {value}
                                                                    </option>
                                                                ))}
                                                            </select>}
                                                            
                                                            </div>}
                                                        </>
                                                    ) :
                                                    column === 'invoiceType' ?
                                                        (<div className="filter"> <label>
                                                            {columns[column].label}
                                                            {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                        </label>
                                                            <select
                                                                id="invoiceType"
                                                                required
                                                                onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                                                            >
                                                                <option value=''>Select</option>
                                                                <option value="TSL Invoice">TSL Invoice</option>
                                                                <option value="Delivery Challan">Delivery Challan</option>
                                                                <option value="Customer Invoice">Customer Invoice</option>
                                                            </select></div>) :
                                                        column === 'material' ?
                                                            (
                                                                productName!=='smartFab'?
                                                            <div className="filter">
                                                                <label>
                                                                    {columns[column].label}
                                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                                </label>
                                                                <select
                                                                    id="materialId"
                                                                    value={gradeDrop.material}
                                                                    onChange={(e) => {
                                                                        uGradeDrop({ ...gradeDrop, [column]: e.target.value });
                                                                        const foundMaterial = gradeMasterData.find(item => item.material === e.target.value)
                                                                        if(foundMaterial){
                                                                        setNewRow({
                                                                        ...newRow, 
                                                                        minLength: foundMaterial.minLength,
                                                                        maxLength: foundMaterial.maxLength
                                                                        })
                                                                        }
                                                                        onBlurChangeHandle(e.target.value)
                                                                    }}
                                                                >

                                                                    <option value=''>Select</option>
                                                                    {gradeMasterData.filter((item) =>
                                                                        item.gradeName === gradeDrop.gradeName &&
                                                                        `${item.diameter}` === gradeDrop.diameter &&
                                                                        item.materialType === gradeDrop.materialType 
                                                                        // && item.minLength <= newRow.maxLength &&
                                                                        // item.maxLength >= newRow.minLength
                                                                        )
                                                                        .map((value, index) => (
                                                                            <option key={index} value={value.material}>
                                                                                {value.material}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                </div>
                                                                :
                                                                <div className="filter">
                                                                <label>
                                                                    {columns[column].label}
                                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                                </label>
                                                                <select
                                                                    id="materialId"
                                                                    value={gradeDrop.material}
                                                                    onChange={(e) => {
                                                                        uGradeDrop({ ...gradeDrop, [column]: e.target.value });
                                                                        onBlurChangeHandle(e.target.value)
                                                                    }}
                                                                >
                                                                    <option value=''>Select</option>
                                                                    {gradeMasterData.filter((item) =>
                                                                        item.productName === gradeDrop.productName &&
                                                                        (gradeDrop.productName?.includes('MS') || `${item.diameter}` === gradeDrop.diameter)

                                                                    )
                                                                        .map((value, index) => (
                                                                            <option key={index} value={value.material}>
                                                                                {value.material}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                </div>
                                                            ) :
                                                            column === 'grossWeight' ?
                                                                (<div style={{ display: 'flex', flexDirection: 'row', gap: '10px', padding: '6px' }}>
                                                                    <div className="filter" style={{ flexDirection: "column" }}>
                                                                        <label >
                                                                            Gross Weight (T)
                                                                        </label>
                                                                        <input
                                                                            key='grossWeight'
                                                                            type='number'
                                                                            className='table-input'
                                                                            value={grossWeight}
                                                                            max={100}
                                                                            onFocus={(e) => { e.target.select() }}
                                                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                                                            onChange={(e) => uGrossWeight(e.target.value)}
                                                                            onBlur={() => onBlurChangeHandle()}
                                                                        />
                                                                    </div>
                                                                    <div className="filter" style={{ width: 'fit-content', flexDirection: "column" }}>
                                                                        <label>{columns.grossWeightTs.label} {columns.grossWeightTs.isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                                        <input
                                                                            key='grossWeightTs'
                                                                            type='datetime-local'
                                                                            className='table-input'
                                                                            value={newRow.grossWeightTs || ''}
                                                                            required
                                                                            // min={getCurrentDateTime()}
                                                                            style={{ boxSizing: 'border-box', height: '37px' }}
                                                                            onChange={(e) => setNewRow({ ...newRow, grossWeightTs: e.target.value })}

                                                                        /></div>

                                                                </div>) :
                                                                column === 'tierWeight' ? (<div style={{ display: 'flex', flexDirection: 'row', gap: '30px', padding: '6px' }}>
                                                                    <div className="filter" style={{ flexDirection: "column" }}>
                                                                        <label>Tare weight (T)</label>
                                                                        <input
                                                                            key='tierWeight'
                                                                            type='number'
                                                                            className='table-input'
                                                                            value={tareWeight}
                                                                            max={100}
                                                                            onFocus={(e) => { e.target.select() }}
                                                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                                                            onChange={(e) => uTareWeight(e.target.value)}
                                                                            onBlur={() => onBlurChangeHandle()}
                                                                        />
                                                                    </div>
                                                                    <div className="filter" style={{ flexDirection: "column" }} >
                                                                        <label>{columns.unloadedTs.label} {columns.unloadedTs.isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                                        <input
                                                                            key={'unloadedTs'}
                                                                            type='datetime-local'
                                                                            className='table-input'
                                                                            value={newRow.unloadedTs || ''}
                                                                            // min={getCurrentDateTime()}
                                                                            style={{ boxSizing: 'border-box', height: '37px' }}
                                                                            onChange={(e) => setNewRow({ ...newRow, unloadedTs: e.target.value })}

                                                                        /></div>
                                                                </div>) :
                                                                    column === 'netWeight' ? (<div style={{ display: 'flex', flexDirection: 'row', gap: '30px', padding: '6px' }}>
                                                                        <div className="filter" style={{ flexDirection: "column" }}>
                                                                            <label>Net weight (T)</label>
                                                                            <input
                                                                                key='netWeight'
                                                                                type='number'
                                                                                className='table-input'
                                                                                value={netWeight}

                                                                                readOnly
                                                                                required={true}
                                                                                onChange={(e) => { setNewRow({ ...newRow, 'netWeight': netWeight }) }}
                                                                            //onBlur={() => setNewRow({ ...newRow, 'netWeight': netWeight })}

                                                                            /></div>
                                                                            {productName!=='smartFab' &&
                                                                        <div className="filter" style={{ flexDirection: "column" }}>
                                                                            <label>
                                                                                {columns.noOfPieces.label}
                                                                                {/* {columns.noOfPieces.isRequired && <span style={{ color: 'red' }}>*</span>} */}
                                                                            </label>
                                                                            <input
                                                                                key='noOfPieces'
                                                                                type={columns.noOfPieces.type}
                                                                                className='table-input'

                                                                                value={totalPieces}
                                                                                onChange={(e) => setNewRow({ ...newRow, 'noOfPieces': e.target.value })}
                                                                            /></div>}

                                                                    </div>) :
                                                                        column === 'description' ?
                                                                            (<div className="filter">
                                                                                <label>{columns[column].label} </label>
                                                                                <textarea
                                                                                    key={column}
                                                                                    className='table-input'
                                                                                    value={newRow[column] || ''}

                                                                                    onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}

                                                                                /></div>
                                                                            ) :
                                                                            column === 'minLength' ?gradeDrop.materialType==='COIL'?'':
                                                                               ( <div className="filter">
                                                                                    <label>{columns[column].label} {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                                                    <input
                                                                                        key={column}
                                                                                        type={columns[column].type}
                                                                                        className='table-input'
                                                                                        value={newRow[column] || ''}
                                                                                        min={0}
                                                                                        max={12000}
                                                                                        onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                                                                                        required
                                                                                    /></div>)
                                                                                 :
                                                                                column === 'maxLength' ?gradeDrop.materialType==='COIL'?'':
                                                                                    (<div className="filter">
                                                                                        <label>{columns[column].label} {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                                                        <input
                                                                                            key={column}
                                                                                            type={columns[column].type}
                                                                                            className='table-input'
                                                                                            value={newRow[column] || ''}
                                                                                            min={0}
                                                                                            max={12000}
                                                                                            onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                                                                                            required
                                                                                        /></div>
                                                                                    ) :

                                                                                    column === 'grossWeightTs' ?
                                                                                        (<div className="filter" style={{ width: 'fit-content' }}>
                                                                                            <label>{columns[column].label} {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                                                            <input
                                                                                                key={column}
                                                                                                type='datetime-local'
                                                                                                className='table-input'
                                                                                                value={newRow[column] || ''}
                                                                                                required
                                                                                                // min={getCurrentDateTime()}
                                                                                                style={{ boxSizing: 'border-box', height: '37px' }}
                                                                                                onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}

                                                                                            /></div>
                                                                                        ) :
                                                                                        column === 'unloadedTs' ?
                                                                                            (<div className="filter" >
                                                                                                <label>{columns[column].label} {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                                                                <input
                                                                                                    key={column}
                                                                                                    type='datetime-local'
                                                                                                    className='table-input'
                                                                                                    value={newRow[column] || ''}
                                                                                                    // min={getCurrentDateTime()}
                                                                                                    style={{ boxSizing: 'border-box', height: '37px' }}
                                                                                                    onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}

                                                                                                /></div>
                                                                                            ) : column === 'invoiceWeight' ?
                                                                                                (<div className="filter" >
                                                                                                    <label>
                                                                                                        {columns[column].label}{column === 'invoiceWeight' ? '(T)' : ''}
                                                                                                        {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                                                                    <input
                                                                                                        key={column}
                                                                                                        type={columns[column].type}
                                                                                                        className='table-input'
                                                                                                        max={100}
                                                                                                        value={newRow[column] || ''}
                                                                                                        required={columns[column].isRequired ? true : false}
                                                                                                        onChange={(e) => { setNewRow({ ...newRow, [column]: e.target.value }); uNetWeight(e.target.value) }}
                                                                                                        onBlur={(e) => onBlurChangeHandle(null, e.target.value)}

                                                                                                    /></div>
                                                                                                ) : (<div className="filter">

                                                                                                    <label>
                                                                                                        {columns[column].label}

                                                                                                        {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                                                                    </label>
                                                                                                    <input
                                                                                                        key={column}
                                                                                                        type={columns[column].type}
                                                                                                        className='table-input'
                                                                                                        value={newRow[column] || ''}
                                                                                                        required={columns[column].isRequired ? true : false}
                                                                                                        onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}

                                                                                                    /></div>
                                                                                                )) :
                                            ""
                                    ))}

                                </div>

                                <div className="bottom-button-container">
                                    <Button type='submit' label='Save' className='blue' />
                                    <Button onClick={() => { setIsAdding(false); setNewRow({}); }} label='Cancel' className='blue' />
                                </div>
                            </div>
                        </div>
                        <div className="bg-dark"></div>
                    </div> :
                    <div>
                        <div className="AddContainer" >
                            <div className="form-container" >
                                <div className="head">Add Stock
                                    <div className="bottomMultiDel" style={{ fontSize: '15px', color: 'black', alignContent: 'flex-end' }}>
                                        <input type="checkbox" id="multiAdd"
                                            checked={multiAdd === true}
                                            onClick={(e) => { handleMultiAdd(!multiAdd) }} />
                                        <label htmlFor="multiAdd">Multiple Add</label>
                                    </div>
                                </div>

                                <div className="table-container">


                                    {columnKeys.map((column) => (
                                        column === 'invoiceType' || column === 'vehicleNo' || column === 'invoiceNumber'
                                            ?
                                            (
                                                column === 'invoiceType' ?
                                                    (<div className="filter"> <label>
                                                        {columns[column].label}
                                                        {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                    </label>
                                                        <select
                                                            id="invoiceType"
                                                            required
                                                            onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                                                        >
                                                            <option value=''>Select</option>
                                                            <option value="TSL Invoice">TSL Invoice</option>
                                                            <option value="Delivery Challan">Delivery Challan</option>
                                                            <option value="Customer Invoice">Customer Invoice</option>
                                                        </select></div>)
                                                    :
                                                    (<div className="filter">

                                                        <label>
                                                            {columns[column].label}

                                                            {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                        </label>
                                                        <input
                                                            key={column}
                                                            type={columns[column].type}
                                                            className='table-input'
                                                            value={newRow[column] || ''}
                                                            required={columns[column].isRequired ? true : false}
                                                            onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}

                                                        /></div>
                                                    )
                                            ) : ''
                                    ))}

                                </div>
                                <MultipleAddStock newRow={newRow} gradeMasterData={gradeMasterData} columnKeys={columnKeys} columns={columns} tempArray={tempArray} setTempArray={setTempArray} />
                                <div className="bottom-button-container">
                                    <Button type='submit' label='Save' className='blue' />
                                    <Button onClick={() => { setIsAdding(false); setNewRow({}); }} label='Cancel' className='blue' />
                                </div>
                            </div>
                        </div>
                        <div className="bg-dark"></div>
                    </div>
                )

            }
            </form>

            <form onSubmit={handleUpdate}>{isEditing &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container" ><div className="head">Edit Stock</div>
                            <div className="table-container">

                                {columnKeys.map((column) => (
                                    columns[column].editable == true && column !== 'grossWeightTs' && column !== 'unloadedTs' && column !== 'noOfPieces' ?
                                        (column === 'grossWeight' ?
                                            (<div style={{ display: 'flex', flexDirection: 'row', gap: '10px', padding: '6px' }}>
                                                <div className="filter" style={{ flexDirection: "column" }}>
                                                    <label>
                                                        Gross Weight (T)
                                                    </label>
                                                    <input
                                                        key='grossWeight'
                                                        type='number'

                                                        className='table-input'
                                                        value={editData['grossWeight']}
                                                        onChange={(e) => handleInputChange('grossWeight', e)}
                                                        onBlur={(e) => onBlurChangeHandle3(e.target.value)}
                                                    /></div>
                                                <div className="filter" style={{ width: 'fit-content', flexDirection: "column" }}>
                                                    <label>{columns.grossWeightTs.label} {columns.grossWeightTs.isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                    <input
                                                        key='grossWeightTs'
                                                        type='datetime-local'
                                                        className='table-input'
                                                        required
                                                        value={editData.grossWeightTs || ''}
                                                        // min={getCurrentDateTime()}
                                                        style={{ boxSizing: 'border-box', height: '37px' }}
                                                        onChange={(e) => handleInputChange('grossWeightTs', e)}

                                                    /></div>


                                            </div>) :

                                            column === 'tierWeight' ? (<div style={{ display: 'flex', flexDirection: 'row', gap: '30px', padding: '6px' }}>
                                                <div className="filter" style={{ flexDirection: "column" }}>
                                                    <label>Tare weight (T)</label>
                                                    <input
                                                        key='tareWeight'
                                                        type='number'
                                                        className='table-input'
                                                        value={editData['tierWeight']}
                                                        onChange={(e) => { setEditData({ ...editData, tierWeight: e.target.value }) }}
                                                        onBlur={(e) => onBlurChangeHandle2(e.target.value)}
                                                    /></div>
                                                <div className="filter" style={{ flexDirection: "column" }} >
                                                    <label>Tare Weight Timestamp{columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                    <input
                                                        key='unloadedTs'
                                                        type='datetime-local'
                                                        className='table-input'
                                                        value={editData.unloadedTs || ''}
                                                        min={editData.grossWeightTs}
                                                        style={{ boxSizing: 'border-box', height: '37px' }}
                                                        onChange={(e) => handleInputChange('unloadedTs', e)}

                                                    /></div>
                                            </div>) :
                                                column === 'netWeight' ? (<div style={{ display: 'flex', flexDirection: 'row', gap: '30px', padding: '6px' }}>
                                                    <div className="filter" style={{ flexDirection: "column" }}>
                                                        <label>Net weight (T)</label>
                                                        <input
                                                            key='netWeight'
                                                            type='number'
                                                            className='table-input'
                                                            readOnly
                                                            value={editData['netWeight']}
                                                            onChange={(e) => handleInputChange('netWeight', e)}
                                                        //onBlur={() => setNewRow({ ...newRow, 'netWeight': netWeight })}
                                                        /></div>
                                                        {productName!=='smartFab' && 
                                                    <div className="filter" style={{ flexDirection: "column" }}>
                                                        <label>{columns.noOfPieces.label}
                                                        </label>
                                                        <input
                                                            key='noOfPieces'
                                                            type={columns.noOfPieces.type}
                                                            className='table-input'
                                                            value={editData.noOfPieces}
                                                            readOnly
                                                            onChange={(e) => handleInputChange('noOfPieces', e)}
                                                        />
                                                    </div>}

                                                </div>)
                                                    :column === 'minLength' ?editData.materialType==='COIL'?'':
                                                    (<div className="filter">
                                                        <label>{columns[column].label} {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                        <input
                                                            key={column}
                                                            type={columns[column].type}
                                                            className='table-input'
                                                            value={editData[column]}
                                                            min={0}
                                                            max={12000}
                                                            onChange={(e) => handleInputChange(column, e)}
                                                            required
                                                        /></div>
                                                    ) :
                                                    column === 'maxLength' ?editData.materialType==='COIL'?'':
                                                        (<div className="filter">
                                                            <label>{columns[column].label} {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                            <input
                                                                key={column}
                                                                type={columns[column].type}
                                                                className='table-input'
                                                                value={editData[column]}
                                                                min={0}
                                                                max={12000}
                                                                onChange={(e) => handleInputChange(column, e)}
                                                                required
                                                            /></div>
                                                        ) :
                                                    column === 'invoiceType' ?
                                                        (<div className="filter" > <label>
                                                            {columns[column].label}
                                                            {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                        </label>
                                                            <select
                                                                id="invoiceType"
                                                                required
                                                                value={editData[column]}
                                                                onChange={(e) => handleInputChange(column, e)}
                                                            >
                                                                <option value="TSL Invoice">TSL Invoice</option>
                                                                <option value="Delivery Challan">Delivery Challan</option>
                                                                <option value="Customer Invoice">Customer Invoice</option>
                                                            </select></div>) :
                                                        column === 'noOfPieces' ?
                                                            (<div className="filter">
                                                                <label>{columns[column].label}<span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    key={column}
                                                                    type={columns[column].type}
                                                                    className='table-input'
                                                                    value={editData.noOfPieces}
                                                                    onChange={(e) => handleInputChange(column, e)}
                                                                    readOnly
                                                                /></div>
                                                            ) :
                                                            column === 'description' ?
                                                                (<div className="filter">
                                                                    <label>{columns[column].label}</label>
                                                                    <textarea
                                                                        key={column}
                                                                        className='table-input'
                                                                        value={editData[column]}

                                                                        onChange={(e) => handleInputChange(column, e)}

                                                                    /></div>
                                                                ) :
                                                                column === 'grossWeightTs' ?
                                                                    (<div className="filter">
                                                                        <label>{columns[column].label} {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                                        <input
                                                                            key={column}
                                                                            type='datetime-local'
                                                                            className='table-input'
                                                                            required
                                                                            value={editData[column] || ''}
                                                                            // min={getCurrentDateTime()}
                                                                            onChange={(e) => handleInputChange(column, e)}

                                                                        /></div>
                                                                    ) :
                                                                    column === 'unloadedTs' ?
                                                                        (<div className="filter">
                                                                            <label>{columns[column].label} {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
                                                                            <input
                                                                                key={column}
                                                                                type='datetime-local'
                                                                                className='table-input'
                                                                                value={editData[column] || ''}
                                                                                min={editData.grossWeightTs}
                                                                                onChange={(e) => handleInputChange(column, e)}

                                                                            /></div>
                                                                        ) : column === 'invoiceWeight' ?
                                                                            (<div className="filter">
                                                                                <label>
                                                                                    {columns[column].label}{column === 'invoiceWeight' ? '(T)' : ''}
                                                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                                                </label>
                                                                                <input
                                                                                    key={column}
                                                                                    type={columns[column].type}
                                                                                    className='table-input'
                                                                                    value={columns[column].type === 'datetime-local' ? formatDate(editData[column]) : editData[column]}
                                                                                    required={columns[column].isRequired ? true : false}
                                                                                    onChange={(e) => { handleInputChange(column, e); handleInvoiceWeight(e) }}

                                                                                /></div>
                                                                            ) :
                                                                            (<div className="filter">
                                                                                <label>
                                                                                    {columns[column].label}
                                                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                                                </label>
                                                                                <input
                                                                                    key={column}
                                                                                    type={columns[column].type}
                                                                                    className='table-input'
                                                                                    value={columns[column].type === 'datetime-local' ? formatDate(editData[column]) : editData[column]}
                                                                                    required={columns[column].isRequired ? true : false}
                                                                                    onChange={(e) => handleInputChange(column, e)}
                                                                                /></div>
                                                                            )
                                        ) : ""
                                ))}

                            </div>

                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => { setIsEditing(false); onSelectRow('A'); }} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>}
            </form>

            {multiAddActive && <div>
                <div className="AddContainer" >
                    <div className="form-container" style={{ width: '90vw' }}>
                        <div className="head">Add Stock

                        </div>

                        <div className="table-container">


                            {columnKeys.map((column) => (
                                column === 'gradeId' || column === 'vehicleNo' || column === 'invoiceNumber'
                                    ?
                                    (column === 'gradeName' ?
                                        (
                                            <>
                                                <div className="filter">
                                                    <label>
                                                        Stock type
                                                        {/* {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>} */}
                                                    </label>
                                                    <select
                                                        id="materialType"
                                                        // required
                                                        value={gradeDrop.materialType}
                                                        onChange={(e) => {
                                                            uGradeDrop({ ...gradeDrop, materialType: e.target.value, material: '', gradeName: '' });
                                                            uTotalPieces('');
                                                        }}
                                                    >

                                                        <option value=''>Select</option>
                                                        {[...new Set(gradeMasterData
                                                            // .filter((item) => item.gradeName === gradeDrop.gradeName && item.diameter * 1 === gradeDrop.diameter * 1)
                                                            .map(item => item.materialType))].sort((a, b) => a - b).map((value, index) => (
                                                                <option key={index} value={value}>
                                                                    {value}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                                <div className="filter">
                                                    <label>
                                                        {columns[column].label}
                                                        {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                    </label>
                                                    <select
                                                        id="gradeId"
                                                        required
                                                        value={gradeDrop.gradeName}
                                                        onChange={(e) => {
                                                            uGradeDrop({ ...gradeDrop, [column]: e.target.value, diameter: '', material: '' });
                                                            uTotalPieces('');
                                                        }}
                                                    >

                                                        <option value=''>Select</option>
                                                        {[...new Set(gradeMasterData.filter(item => item.materialType === gradeDrop.materialType).map(item => item.gradeName))].map((value, index) => (
                                                            <option key={index} value={value}>
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                            </>
                                        ) :
                                        column === 'diameter' ?
                                            (
                                                <>
                                                    <div className="filter">
                                                        <label>
                                                            {columns[column].label} (mm)
                                                            {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                        </label>
                                                        <select
                                                            id="diameter"
                                                            required
                                                            value={gradeDrop.diameter}
                                                            onChange={(e) => {
                                                                uGradeDrop({ ...gradeDrop, [column]: e.target.value, material: '' });

                                                                uTotalPieces('');
                                                            }}
                                                        >
                                                            <option value=''>Select</option>

                                                            {[...new Set(gradeMasterData.filter((item) => item.gradeName === gradeDrop.gradeName && item.materialType === gradeDrop.materialType).map(item => item.diameter))].sort((a, b) => a - b).map((value, index) => (
                                                                <option key={index} value={value}>
                                                                    {value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                </>
                                            )
                                            :
                                            (<div className="filter">

                                                <label>
                                                    {columns[column].label}

                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                </label>
                                                <input
                                                    key={column}
                                                    type={columns[column].type}
                                                    className='table-input'
                                                    value={newRow[column] || ''}
                                                    required={columns[column].isRequired ? true : false}

                                                /></div>
                                            )
                                    ) : ''
                            ))}

                        </div>
                        <div className="bottom-button-container">
                            <Button label='Add' className='blue' onClick={() => { }} />
                            <Button onClick={() => { }} label='Cancel' className='blue' />
                        </div>
                    </div>
                </div>
                <div className="bg-dark"></div>
            </div>}
        </div>
    )
}

export default A1
//End of File